import {HTTPRequest, JsonLike, Method, Url, urlFor} from "@/utilities/http";
import {searchRequestMetadata} from "@/utilities/search/helpers";
import {SearchRespMetadata} from "@/utilities/searchResponse";
import config from "@/config/config.json";
import {AxiosCalls} from "@/services/axiosCalls";
import {Language} from "@/services/language";
import {Logging} from "@/services/logging";
import {RawENVPViewList, ENVPViewList} from "@/model/envp/envpViewList";


export class DownloadENVPFileDetails{
     public fileid: string = ""
     public filename: string = ""
}

export class SearchENVPFileDetails{
     public filename_to_search: string = ""
}

export interface ENVPServices{

    searchENVP(searchString: string, mataData: searchRequestMetadata): Promise<[ENVPViewList, SearchRespMetadata]|null>
}

export class ENVPServicesImp implements ENVPServices{

    protected ax: AxiosCalls
    private language: Language
    private logger: Logging

    constructor(ax: AxiosCalls, logger: Logging, language: Language) {
        this.ax = ax
        this.logger = logger
        this.language = language
    }
    async searchENVP(searchString: string, mataData: searchRequestMetadata): Promise<[ENVPViewList, SearchRespMetadata]|null> {
        const url: Url = urlFor(config.REST_API.ROUTES.ENVP.ENVP_SEARCH)
        const data = <JsonLike>{
            "searchString": searchString,
            "searchRequestMetadata": mataData
        }
        const req: HTTPRequest = new HTTPRequest(Method.POST, url, data, [])
        try {
            const res = await this.ax.request(req)
            const rawENVPViewList: RawENVPViewList = res.data["views"]
            const searchMetadata: SearchRespMetadata = res.data["searchResponseMetadata"]
            const envpViewList = new ENVPViewList(rawENVPViewList, this.language)
            await envpViewList.deserialize()
            return [envpViewList, searchMetadata]
        }
        catch (e) {
            console.log("error while searching ENVP: \n", e)
            this.logger.addError("Suche fehlgeschlagen")
            return null
        }
    }
}