import {RawView, View} from "@/model/userInput/view/view";
import {Language} from "@/services/language";
import {isNU} from "@/utilities/isNU";


export type RawSOPView = RawView & {filename?: string}


export class SOPView extends View {

    filename: string

    constructor(raw: RawSOPView, language: Language) {
        super(raw, language);
        if(isNU(raw.filename)) throw Error(`attribute filename is ${raw.filename} but should be a string`)
        this.filename = raw.filename!
    }

    serializeChanged(): RawSOPView {
        const changed = <RawSOPView> super.serializeChanged()
        changed["filename"] = this.filename
        return changed
    }
}
