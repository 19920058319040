
























































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {
  BIconArrowDown, BIconArrowLeft, BIconArrowRight, BIconArrowsFullscreen, BIconArrowUp,
  BIconLockFill, BIconUnlockFill, BIconArrowDownShort, BIconArrowUpShort
} from "bootstrap-vue";
import SortingButton from "@/components/util/SortingButton.vue";
import {Sorting} from "@/utilities/search/sorting";
import {View} from "@/model/userInput/view/view";
import { ViewPreviewManager } from '@/utilities/search/viewColumnManager';
import {ViewColumn} from "@/model/userInput/view/viewList";
import GenericColumnField from "@/components/userInput/fields/util/GenericColumnField.vue";
import SOPIconImage from "@/components/sop/SOPIconImage.vue";
import ENVPIconImage from "@/components/envp/ENVPIconImage.vue";

type Dot = {
  labelId: string
  active: boolean
}

@Component({
    components: {
      GenericColumnField,
        BIconArrowsFullscreen,
        BIconArrowRight,
        BIconArrowLeft,
        BIconArrowUp,
        BIconArrowDown,
        BIconLockFill,
        BIconUnlockFill,
        BIconArrowDownShort,
        BIconArrowUpShort,
        SortingButton,
        SOPIconImage,
        ENVPIconImage
    }
})
export default class ViewList extends Vue {

    @Prop({required: true}) columns!: ViewColumn[]
    @Prop({required: true}) views!: View[]
    @Prop({default: true}) allowSorting!: boolean

    currentSorting: Sorting | null = null
    vpm = new ViewPreviewManager(this.columns, this.numCols)

    private static columnsTheSame(newVal: ViewColumn[], oldVal: ViewColumn[]): boolean{
        if(newVal.length !== oldVal.length) return false
        for(let i = 0; i++; i < newVal.length) if(newVal[i] !== oldVal[i]) return false
        return true
    }
    sort(sorting: Sorting) {
        this.currentSorting = sorting
        this.$emit("sortBy", sorting)
    }

    @Watch("columns")
    updateViewPreviewManager(newVal: ViewColumn[], oldVal: ViewColumn[]){
        if(!ViewList.columnsTheSame(newVal, oldVal))
            this.vpm = new ViewPreviewManager(this.columns, this.numCols)
    }

    get numCols(): number {
        return Math.max(Math.round(window.innerWidth / 450), 1)
    }

    showView(view: View): void {
        this.$emit("showView", view)
    }
    checkSopIconRequired(sopValue:string):boolean{
      if(sopValue!=null && sopValue!='') return true
      else return false
    }
    showSOP(sopFileName: string){
       this.$emit("showSOP", sopFileName)
    }
    checkENVPIconRequired(envpValue:string):boolean{
      if(envpValue!=null && envpValue!='') return true
      else return false
    }
    showENVP(envpFileName: string){
       this.$emit("showENVP", envpFileName)
    }
}
