









import {Component, Inject, Prop, Vue} from "vue-property-decorator";
import {StringField as _StringField} from "@/model/userInput/fields/common/string";
import {isNU} from "@/utilities/isNU";
import {ServiceType} from "@/services/types";
import {UserService,UserDetails} from "@/services/user";
import {marked} from "marked";


@Component
export default class MarkdownField extends Vue {

    @Inject(ServiceType.User) userS!: UserService

    @Prop({required: true}) fieldData!: _StringField
    @Prop({default: false}) inactive!: boolean
    @Prop({default: ""}) formName!: string
    get field() {
        return this.fieldData
    }

    get convertToMarkdown(){
        return marked(this.field.value!)
    }

    changed() {
        this.$emit("changed")
    }

    get placeholder(): string {
        if (!isNU(this.field.placeholder))
            return this.field.placeholder!
        else return ""
    }

    created() {
      if(this.formName!="")
        this.fieldData.value=UserDetails.prototype.userName
    }
}
