
























import {Component, Prop, Vue} from "vue-property-decorator";
import {NumberField as _NumberField} from "@/model/userInput/fields/common/number";


@Component
export default class NumberField extends Vue {

    @Prop({required: true}) fieldData!: _NumberField
    @Prop({default: false}) inactive!: boolean

    changed() {
        this.$emit("changed")
    }
}
