









import {Component, Prop, Vue} from "vue-property-decorator";
import GenericField from "@/components/userInput/fields/util/GenericField.vue";
import {ProteinCombinationNumberOfProteinsForm} from "@/model/protein/proteinCombinationNumberOfProteinsForm";

@Component({
    components: {GenericField},
})
export default class NumberOfProteinsInCombinationForm extends Vue {
    @Prop({required: true}) form!: ProteinCombinationNumberOfProteinsForm
}
