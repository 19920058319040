









import {Component, Prop, Vue} from "vue-property-decorator";
import {Form} from "@/model/userInput/form/form";
import GenericField from "@/components/userInput/fields/util/GenericField.vue";

@Component({
    components: {GenericField},
})
export default class ProteinViewColumnConfigurationForm extends Vue {
    @Prop({required: true}) form!: Form

    // This selectedProteins Only required when we need to show the Protein Names
    // On Filter Observations Modal Selection View. Otherwise, It would always be null
    @Prop({required: false}) selectedProteins!: string[]
    @Prop({required: false, default: true}) showCategoriesSelections!: boolean
}
