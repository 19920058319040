import {SearchRespMetadata} from "@/utilities/searchResponse";
import {searchRequestMetadata} from "@/utilities/search/helpers";


export type PageInfo = number | "..."


export class PaginationHelper {

    private length: number = 0
    private maxPages: number = Infinity
    private pageIdx: number = 0

    get selectedPage(): number {
        return this.pageIdx + 1
    }

    setLength(length: number){
        this.length = length
    }

    setMaxPageInfos(max: number) {
        if(max < 5) throw Error("max page numbers to show must be at least 5")
        this.maxPages = max
    }

    setCurrentIndex(currentIdx: number){
        this.pageIdx = currentIdx
    }

    get pageInfos(): PageInfo[] {

        if(this.length < this.maxPages){
            const infos = []
            let cnt = this.length
            while(cnt > 0) {
                infos.unshift(cnt)
                cnt --
            }
            return infos
        }
        else {

            const currentIdx = this.pageIdx
            const totalPages = this.length
            let cnt = this.maxPages

            const currentPage = currentIdx+1
            const infos: PageInfo[] = [currentPage]

            let left = currentIdx
            let right = currentIdx + 2
            let reachedLeftEnd = currentPage === 1
            let reachedRightEnd = currentPage === totalPages
            let toggle: boolean = false

            while(cnt > 1){
                if(toggle){
                    if (right < totalPages) {
                        infos.push(right)
                        right++
                        cnt--
                    } else if (!reachedRightEnd) {
                        infos.push(totalPages)
                        reachedRightEnd = true
                        cnt--
                    }
                }
                else {
                    if (left > 1) {
                        infos.unshift(left)
                        left--
                        cnt--
                    } else if (!reachedLeftEnd) {
                        infos.unshift(1)
                        reachedLeftEnd = true
                        cnt--
                    }
                }
                toggle = !toggle
                if(reachedLeftEnd && reachedRightEnd) return infos
            }

            if(!reachedRightEnd){
                infos[infos.length - 1] = totalPages
                infos[infos.length - 2] = "..."
            }
            if(!reachedLeftEnd){
                infos[1] = "..."
                infos[0] = 1
            }

            return infos
        }
    }
}


export abstract class SearchHandler<DataT> {

    private size: number

    private batchIndex: number = 0
    protected data: (DataT[]|null)[] = []
    private goingSomewhere: boolean = false
    
    private currentBatchData: DataT[]|null = null

    private cacheMetaData: number[] = []
    private maxElementsInCache: number
    protected emptySearchResult: boolean = false

    pagination = new PaginationHelper()

    get searchResultWasEmpty(): boolean {
        return this.emptySearchResult
    }

    get batchSize() {
        return this.size
    }

    protected constructor(size: number, paginationSize: number, maxElementsInCache: number) {
        this.size = size
        this.pagination.setMaxPageInfos(paginationSize)
        this.maxElementsInCache = maxElementsInCache
    }

    async updateSize(size: number){
        this.size = size
        await this.firstBatch()
    }

    get loading(): boolean {
        return this.goingSomewhere
    }

    get currentBatchAvailable(): boolean {
        return this.currentBatch !== null
    }

    get currentBatch(): DataT []| null {
        return this.currentBatchData
    }

    private checkDataCache(){
        const batchIndex = this.batchIndex
        this.cacheMetaData.push(batchIndex)
        while(this.cacheMetaData.length * this.size > this.maxElementsInCache){
            const batchToRemoveIdx = this.cacheMetaData.shift()
            if(batchToRemoveIdx === undefined) break
            else {
                this.data[batchToRemoveIdx] = null
            }
        }
    }

    async goto(batchIndex: number){

        if(this.goingSomewhere) return
        this.goingSomewhere = true
        this.batchIndex = batchIndex

        if(this.data[batchIndex] !== null && this.data[batchIndex] !== undefined){
            this.currentBatchData = this.data[batchIndex]
        }
        else{
            const offset = batchIndex * this.size
            const srm: searchRequestMetadata = {
                offset: offset,
                maxResults: this.size
            }
            const result = await this.loadBatch(srm)
            if(result == null) throw Error("received no data after search")
            const batchesFound = Math.ceil(result.metadata.numPotentialResults/this.size)
            if(batchesFound !== this.totalNumBatches){
                this.prepareCache(batchesFound)
            }
            this.data[batchIndex] = result.data
            this.currentBatchData = result.data
            this.checkDataCache()
        }
        this.pagination.setCurrentIndex(batchIndex)
        this.goingSomewhere = false
    }

    get totalNumBatches() {
        return this.data.length
    }

    protected abstract loadBatch(srm: searchRequestMetadata):
        Promise<{data: DataT[], metadata: SearchRespMetadata} | null>


    private prepareCache(length: number){
        this.data = []
        let cnt = length
        while(cnt>0){
            this.data.push(null)
            cnt --
        }
        this.pagination.setLength(length)
    }

    async firstBatch() {
        this.data = []
        await this.goto(0)
    }

    async nextBatch() {
        if(!this.nextBatchAvailable) return
        await this.goto(this.batchIndex + 1)
    }

    async prevBatch() {
        if(!this.prevBatchAvailable) return
        await this.goto(this.batchIndex - 1)
    }

    get nextBatchAvailable(): boolean{
        return this.batchIndex < (this.totalNumBatches - 1)
    }

    get prevBatchAvailable(): boolean{
        return this.batchIndex > 0
    }
}
