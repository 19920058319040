



















import {Component, Inject, Vue} from 'vue-property-decorator'
import {Logging as _Logging} from "@/services/logging";
import {ServiceType} from "@/services/types";
import {ErrorMessage, InfoMessage, WarningMessage} from "@/utilities/logging";
import {BIconX} from 'bootstrap-vue'

@Component({
    components: {
        BIconX
    }
})
export default class Logging extends Vue {
    messageTypes = {
        info: InfoMessage, error: ErrorMessage, warning: WarningMessage
    }
    @Inject(ServiceType.Logging) logger!: _Logging
}
