














































import {Component, Inject, Vue} from 'vue-property-decorator'
import {ServiceType} from "@/services/types";
import {FormGetter} from "@/services/formGetter";
import {Form} from "@/model/userInput/form/form";
import {UserService,UserDetails} from "@/services/user";
import GenericField from "@/components/userInput/fields/util/GenericField.vue";
import {Logging, LoggingLevel} from "@/services/logging";
import {FieldId} from "@/model/userInput/fields/util/fieldIds";

export enum NotificationType {
    INFO,
    ERROR
}

@Component({
  components: {GenericField,},
})

export default class UserSettings extends Vue {
    @Inject(ServiceType.FormGetter) protected formGetter!: FormGetter
    @Inject(ServiceType.User) userS!: UserService
    @Inject(ServiceType.Logging) logging!: Logging

    noerror : boolean=true

    form: Form|null = null
    languageFieldForm: Form| null= null

    async created(){
        this.form = await this.formGetter.getUserSettingsForm()
        this.languageFieldForm = await this.formGetter.getUserSettingsLanguageForm()
    }
    get fieldId(): typeof FieldId {
        return FieldId
    }
    ChangeLanguageValue(field: any){
      field.options.forEach((langauge: string, index: number)=>{
        const langaugeLower = langauge.toLowerCase()
        if(langaugeLower.startsWith(UserDetails.prototype.currentLanguage as string)){
          field.value = field.options[index]
        }
       });
  }

    async submit(){
        if(this.form && this.form.valid) {
            this.noerror=true
            if(UserDetails.prototype.originalPassword!=UserDetails.prototype.enteredPassword) {
              this.displayNotification(this.form,this.$t('UserSettingsPage.ErrorMessages.NewPasswordMatch').toString(),NotificationType.ERROR)
              this.noerror=false
            }
            if(UserDetails.prototype.newPassword!=UserDetails.prototype.newPasswordRetype) {
              this.displayNotification(this.form,this.$t('UserSettingsPage.ErrorMessages.NewPasswordMatch').toString(),NotificationType.ERROR)
              this.noerror=false
            }
            if(this.noerror) {
              await this.userS.userSettingsForm(this.form,
                  [this.$t('InfoTexts.UserEditedSuccessful'),this.$t('InfoTexts.UserEditedUnSuccessful')])
              this.form.makeEmpty()
              await this.$router.push("/user")
            }
        }
    }
    async languageChangeDetected(languageValueChanged: boolean){
      UserDetails.prototype.languageChanged= languageValueChanged
      if(this.languageFieldForm) {
        this.displayNotification(this.languageFieldForm,this.$t('InfoTexts.LanguageChanging').toString(),NotificationType.INFO)
        await this.userS.userSettingsLanguageForm(this.languageFieldForm,
            [this.$t('InfoTexts.LanguageChangeSuccessful'),this.$t('InfoTexts.LanguageChangeUnSuccessful')])
      }
    }
    displayNotification(form:Form, info:string, notificationType: NotificationType){
      if(notificationType === NotificationType.INFO) this.logging.addInfo(info, undefined, LoggingLevel.USER_INFO)
      else if(notificationType === NotificationType.ERROR) this.logging.addError(info, undefined, LoggingLevel.USER_INFO)
    }
}
