import {Field} from "@/model/userInput/fields/util/fieldBase";
import {Language} from "@/services/language";
import {isNU} from "@/utilities/isNU";
import {rawField} from "@/model/userInput/fields/util/raw";


type rawNonDeterminableField = rawField & {nonDeterminable?: boolean}


interface FieldImplementation<FT>{
    serialize(): FT,
    deserialize(data: FT, language: Language): Promise<void>,
    isEmpty(): boolean,
    valid(): boolean
    makeEmpty(): void
    commitChange(): void
    deserializeField(data: FT, language: Language): Promise<void>
    changed: boolean
}


export interface FlagOptionProvider {
    nonDeterminable?: boolean
    flagSet: boolean
    setNonDeterminable(value: boolean): void
    toggleNonDeterminableSelected(): void
}


export type GConstructor<T = {}> = new (...args: any[]) => T;

export type FieldConstructor = GConstructor<
    Field<rawNonDeterminableField> &
    FieldImplementation<rawNonDeterminableField>>

type FLagOptionProviderConstructor = GConstructor<FlagOptionProvider>


export type FlagOptionField = Field<any> & FlagOptionProvider


export function FLagOption<TBase extends FieldConstructor>(Base: TBase): TBase & FLagOptionProviderConstructor {
    return class FLagOption extends Base {

        get flagSet(): boolean {
            return this.nonDeterminable === true
        }

        private originalFlagVal?: boolean
        nonDeterminable?: boolean

        get changed(): boolean {
            return super.changed || this.nonDeterminable !== this.originalFlagVal
        }

        commitChange() {
            super.commitChange()
            this.originalFlagVal = this.nonDeterminable
        }

        makeEmpty(): void {
            super.makeEmpty()
            this.nonDeterminable = undefined
        }

        isEmpty(): boolean {
            return super.isEmpty() && isNU(this.nonDeterminable)
        }

        valid(): boolean {
            if(this.nonDeterminable) return true
            else return super.valid()
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        async deserializeField(data: rawNonDeterminableField, language: Language): Promise<void>{
            await super.deserializeField(data, language)
            this.originalFlagVal = data.nonDeterminable
            this.nonDeterminable = data.nonDeterminable
        }

        serialize(): rawNonDeterminableField{
            if(this.nonDeterminable) return <rawNonDeterminableField>{nonDeterminable: true}
            let serialized = super.serialize()
            serialized.nonDeterminable = false
            return serialized
        }

        setNonDeterminable(value: boolean){
            if(value){
                this.nonDeterminable = true
                super.makeEmpty()
            }
            else{
                this.nonDeterminable = false
            }
        }

        toggleNonDeterminableSelected() {
            if(this.nonDeterminable) this.setNonDeterminable(false)
            else this.setNonDeterminable(true)
        }
    }
}