













import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class ButtonWrapper extends Vue {
    @Prop({default: false}) disabled!: boolean
    clicked(){if(!this.disabled)this.$emit("click")}
}
