
















import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import RenderChart from "@/components/charts/RenderChart.vue";
import {ChartObservationsSearchHandler} from "@/utilities/chartObservationsSearch";
import {View} from "@/model/userInput/view/view";
import {ViewPreviewManager} from "@/utilities/search/viewColumnManager";
import {ViewColumn} from "@/model/userInput/view/viewList";
import {Field} from "@/model/userInput/fields/util/fieldBase";
import {FieldId} from "@/model/userInput/fields/util/fieldIds"
import {isNU} from "@/utilities/isNU";
import {NormalDistributionField} from "@/model/userInput/fields/common/normalDistribution";
import {ChartProperties} from "@/utilities/chart/chartProperties";

@Component({
  components: {
    RenderChart,
  }
})
export default class LoadObservationsData extends Vue{

    @Prop({required: true}) searchHandler!: ChartObservationsSearchHandler
    @Prop({required: true}) chartProperties!: ChartProperties
    @Prop({required: true}) selectedProteins!: string[]

    columns!: ViewColumn[]
    field!: Field<any>
    records: any = {}

    numberOfColumns: number = 0
    numberOfProteinsLength: number = 0

    created(){
      this.assignColumnLength()
      this.loadData()
    }

    assignColumnLength(){
      this.numberOfColumns = this.searchHandler.columns.length
      this.numberOfProteinsLength = this.selectedProteins.length
    }

    @Watch("selectedProteins.length")
    watchProteinValuesChange(){
      this.loadData()
    }

    @Watch("searchHandler.columns")
    loadData() {
      if(this.searchHandler) {
        this.makeDataArraysEmpty()
        const isAlreadyProteinsAreSelected = this.checkAlreadyProteinsAreSelected()
        this.columns = this.searchHandler.columns
        this.assignAllColumnNames(this.columns)
        this.searchHandler.currentBatch?.forEach((view: View)=>{
          const viewPreview = new ViewPreviewManager(this.columns, 1000)

          // if user didn't select any desired protein then show the data of all proteins available
          if(!isAlreadyProteinsAreSelected) {
            this.chartProperties.proteinNamesLabels.push(this.getActualProteinNameLabel(view))
            this.readNonEmptyFields(viewPreview, view)
          }
          // if user already selected some proteins then show the data against those proteins only
          else{
            const currentProteinName = this.getActualProteinNameLabel(view)
            if(this.selectedProteins.indexOf(currentProteinName) !== -1){
              this.chartProperties.proteinNamesLabels.push(currentProteinName)
              this.readNonEmptyFields(viewPreview, view)
            }
          }
       })
      }
   }

   makeDataArraysEmpty(){
      Object.keys(this.records).map(key => delete this.records[key])
      this.chartProperties.proteinNamesLabels = []
      this.chartProperties.selectedColumnNames = []
      this.chartProperties.observationsLabels = []
   }

   assignAllColumnNames(allColumns: ViewColumn[]){
      allColumns.forEach((column: ViewColumn) =>{
         this.chartProperties.selectedColumnNames.push(column.label.text!)
         this.chartProperties.observationsLabels.push(column.label.text!)
      })
   }

   getActualProteinNameLabel(view: View): string{
      return view.label.split("  ")[1]
   }
   checkAlreadyProteinsAreSelected(): boolean{
      if(this.selectedProteins.length > 0) return true
      else return false
   }

    readNonEmptyFields(viewPreview: ViewPreviewManager, view: View){
        viewPreview.lockedAndActiveCols.forEach((col)=>{
        if (!isNU(view.getField(col.label.id))){
          this.field = view.getField(col.label.id)
          if(this.field.fieldTypeId === this.fieldId.NormalDistOrNonDet){
            const fieldMeanValue = this.getMeanValue(this.field)
            this.assignProteinObjectAnEmptyDictionaryOfColumnNames(view)
            this.records[this.getActualProteinNameLabel(view)][col.label.text!] = fieldMeanValue
          }
        }
      })
    }

    assignProteinObjectAnEmptyDictionaryOfColumnNames(view: View){
      if(!this.records[this.getActualProteinNameLabel(view)]) {
        let emptyObject: any = {}
        this.chartProperties.selectedColumnNames.forEach((columnName: string) =>{
          emptyObject[columnName] = undefined
        })
        this.records[this.getActualProteinNameLabel(view)] = emptyObject
      }
    }

    get fieldId(): typeof FieldId {
        return FieldId
    }

    getMeanValue(field: NormalDistributionField): number{
      return Number(field.mean)
    }
}
