import {nonDeterminableOption, rawField} from "@/model/userInput/fields/util/raw";
import {FieldId} from "@/model/userInput/fields/util/fieldIds";
import {Language} from "@/services/language";
import {isNU, isNUE} from "@/utilities/isNU";
import {Deserializable} from "@/model/serializable";
import {LangInfo} from "@/model/LangInfo";
import {VLeaf} from "@/utilities/misc/nested";


export abstract class Field<T extends rawField> extends Deserializable<T> implements VLeaf{

    private language?: Language
    public readonly fieldTypeId: FieldId
    private isMutable: boolean
    public label: string = ""
    public actualLabel?: LangInfo
    public required: boolean = false
    public validationFeedback: string = ""
    public selectedProteinsList: string[] = []


    abstract get changed(): boolean

    abstract commitChange(): void

    setImmutable() {
        this.isMutable = false
    }

    constructor(data: T, language: Language) {
        super(data);
        if(isNU(data.fieldTypeId)) throw TypeError(`incoming field has fieldTypeId ${data.fieldTypeId}`)
        if(isNU(data.mutable)) throw TypeError(`incoming field has fieldTypeId ${data.mutable}`)
        if(isNU(data.label)) throw TypeError(`incoming field label ${data.label}`)
        this.fieldTypeId = data.fieldTypeId!
        this.isMutable = data.mutable!
        this.language = language
        if(!isNU(data.selectedProteinsList)) this.selectedProteinsList = data.selectedProteinsList!
    }

    async _deserialize(raw: T){
        this.actualLabel = new LangInfo(raw.label!, this.language!)
        await this.actualLabel.deserialize()
        this.label = this.actualLabel.text!
        if(!isNUE(raw.validation_feedback)) this.validationFeedback = await this.language!.parse(raw.validation_feedback!)
        await this.deserializeField(raw, this.language!)
        delete this.language
    }

    abstract makeEmpty(): void

    abstract isEmpty(): boolean

    get requiredButEmpty(): boolean {
        return this.required && this.isEmpty()
    }

    abstract valid(): boolean

    get mutable(): boolean {
        return this.isMutable
    }

    async abstract deserializeField(data: T, language: Language): Promise<void>

    abstract serialize(): T | nonDeterminableOption
}
