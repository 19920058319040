import {rawField} from "@/model/userInput/fields/util/raw";
import {Field} from "@/model/userInput/fields/util/fieldBase";
import {Language} from "@/services/language";
import {isNU, isNUE, numberInputEq} from "@/utilities/isNU";


export type rawNumberField = rawField & {
    value?: number
    min?: number
    max?: number
}

export class NumberField extends Field<rawNumberField> {

    private originalVal?: number
    value?: number
    min?: number
    max?: number

    isEmpty(): boolean {
        return isNUE(this.value)
    }

    makeEmpty() {
        this.value = undefined
    }

    get changed(): boolean {
        return !numberInputEq(this.value, this.originalVal)
    }

    valid(): boolean {
        if(typeof this.value !== "number")
            return false
        if(!isNU(this.max))
            if(this.value > this.max!) return false
        if(!isNU(this.min))
            if(this.value < this.min!) return true
        return true
    }

    commitChange() {
        this.originalVal = this.value
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async deserializeField(data: rawNumberField, language: Language): Promise<void> {
        this.originalVal = data.value
        this.max = data.max
        this.min = data.min
        this.value = data.value
    }

    serialize(): rawNumberField {
        if(!this.valid())
            throw Error("cannot serialize normal distribution")
        return <rawNumberField> {
            value: this.value,
        }
    }
}