














































































































import {Component, Inject, Vue} from 'vue-property-decorator'
import ButtonWrapper from "@/components/util/ButtonWrapper.vue";
import {BIconGear, BIconBarChart, BIconCheck2, BSpinner, BIconFilter, BIconPlusCircle} from "bootstrap-vue";
import ProteinSearchMenu from "@/components/proteins/ProteinSearchMenu.vue";
import TooltipButton from "@/components/util/TooltipButton.vue";
import {Form} from "@/model/userInput/form/form";
import {ServiceType} from "@/services/types";
import {FormGetter} from "@/services/formGetter";
import {ProteinQueries} from "@/services/proteinQueries";
import ProteinViewColumnConfigurationForm from "@/components/userInput/ProteinViewColumnConfigurationForm.vue";
import {ChartObservationsSearchHandler} from "@/utilities/chartObservationsSearch";
import {Language} from "@/services/language";
import LoadObservationsData from "@/components/charts/LoadObservationsData.vue";
import {ChartProperties} from "@/utilities/chart/chartProperties";
import AddProteinModal from "@/components/charts/AddProteinModal.vue";
import {ProteinView} from "@/model/protein/proteinView";
import {SubsetSelectField} from "@/model/userInput/fields/common/subset_select";

@Component({
  components: {
    BIconBarChart,
    BIconCheck2,
    ButtonWrapper,
    BIconGear,
    ProteinSearchMenu,
    TooltipButton,
    ProteinViewColumnConfigurationForm,
    LoadObservationsData,
    BSpinner,
    BIconFilter,
    BIconPlusCircle,
    AddProteinModal
  }
})
export default class ShowCharts extends Vue{

    chartObservationsPreviewForm: Form | null = null
    chartProteinsFilterPreviewForm: Form | null = null
    searchForm: Form | null = null
    searchHandler: ChartObservationsSearchHandler | null = null
    chartProperties: ChartProperties | null = null
    previewModalOpen: boolean = false
    previewCategoriesFilterModalOpen: boolean = false
    dataLoadedAlready: boolean = false
    addProteinModelVisible: boolean = false

    selectedChartIndex: number = 0

    @Inject(ServiceType.FormGetter) formGetter!: FormGetter
    @Inject(ServiceType.ProteinQueries) pq!: ProteinQueries
    @Inject(ServiceType.Language) language!: Language

    readonly initialBatchSize: number = 1000
    readonly paginationSize: number = 100
    readonly maxObservationsCached: number = 1000

    selectedProteins: string[] = []
    selectedProteinsListForCharts: string[] = []

    handleSearch(searchForm: Form) {
        if(this.searchHandler === null) {
          this.searchHandler = new ChartObservationsSearchHandler(
              this.initialBatchSize, this.paginationSize, this.maxObservationsCached, searchForm, this.pq, this.language)
        }
        else {
          this.searchHandler.setSearchForm(searchForm)
        }
        this.searchHandler.firstBatch()
        this.dataLoadedAlready = true
    }

    assignSelectedProtein(protein: ProteinView){
      let addedAlready = false
      this.selectedProteins.forEach((val: string) =>{
        if(val === protein.proteinName) addedAlready = true
      })
      if(!addedAlready) this.selectedProteins.push(protein.proteinName)
    }

    showPreviewObservations() {
        this.previewModalOpen = true
    }

    showCategoriesFilterObservations(){
      this.previewCategoriesFilterModalOpen = true
    }

    get loading(): boolean {
        if(this.searchHandler) return this.searchHandler.loading
        else return false
    }

    showAddProteinModal(){ this.addProteinModelVisible = true}
    hideAddProteinModal(){ this.addProteinModelVisible = false}

    chartsButtonClick(index: number){
      this.selectedChartIndex = index
      if(this.chartProperties) this.chartProperties.setSelectedChartName(index)
    }

    updatePreview(){
        this.formGetter.invalidateCachedChartObservationPreviewForm()
        if(this.searchHandler === null) return
        this.searchHandler.updateColumns()
    }

    async updateProteinsPreview(){
        this.formGetter.invalidateCachedChartProteinsPreviewForm()
        await this.getProteinFormAndSetProteinList()
    }

    async submitPreviewForm() {
        if (this.chartObservationsPreviewForm === null) return
        const form = this.chartObservationsPreviewForm
        const success = await this.pq.setProteinChartsPreview(form)
        this.previewModalOpen = false
        if(success) this.updatePreview()
    }

    async addSelectedProteinsListIntoForm(form: Form): Promise<Form>{
      let asNestedField: SubsetSelectField|null = Object.values(form.fields)[0] as SubsetSelectField
      asNestedField.selectedProteinsList = this.selectedProteins
      return form
    }

    async submitProteinsFilterPreviewForm() {
        if (this.chartProteinsFilterPreviewForm === null) return
        const form = await this.addSelectedProteinsListIntoForm(this.chartProteinsFilterPreviewForm)
        this.addProteinsNamesInList(form)
        const success = await this.pq.setChartsCategoriesFilterPreview(form)
        this.previewCategoriesFilterModalOpen = false
        if(success) await this.updateProteinsPreview()
    }

    addProteinsNamesInList(form: Form){
      this.selectedProteins = []
      let asNestedField: SubsetSelectField|null = Object.values(form.fields)[0] as SubsetSelectField
      asNestedField.selectedProteinsList.forEach((proteinName: string) =>{
        this.selectedProteins.push(proteinName)
      })
      this.selectedProteinsListForCharts = JSON.parse(JSON.stringify(this.selectedProteins))
    }

    async getProteinFormAndSetProteinList(){
       this.chartProteinsFilterPreviewForm = await this.formGetter.getChartProteinsPreviewForm()
       if(this.chartProteinsFilterPreviewForm) this.addProteinsNamesInList(this.chartProteinsFilterPreviewForm)
    }

   async created() {
     this.formGetter.invalidateCachedChartObservationPreviewForm()
     this.chartObservationsPreviewForm = await this.formGetter.getChartObservationsPreviewForm()
     await this.updateProteinsPreview()
     this.searchForm = await this.formGetter.getChartObservationsSearchForm()
     if(this.searchForm) this.handleSearch(this.searchForm)
     this.chartProperties = new ChartProperties()
     this.chartsButtonClick(this.selectedChartIndex)
   }
}
