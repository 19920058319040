



















import {Component, Prop, Vue} from 'vue-property-decorator';
import {Field} from "@/model/userInput/fields/util/fieldBase";
import {FieldId} from "@/model/userInput/fields/util/fieldIds"

import OptionsFieldPreview from "@/components/userInput/fields/previews/OptionsFieldPreview.vue";
import StringFieldPreview from "@/components/userInput/fields/previews/StringFieldPreview.vue";
import BlobOrFlagPreview from "@/components/userInput/fields/previews/BlobOrFlagPreview.vue";
import StringOrFlagPreview from "@/components/userInput/fields/previews/StringOrFlagPreview.vue";
import ButtonPreview from "@/components/userInput/fields/previews/ButtonPreview.vue";
import ButtonOrFlagPreview from "@/components/userInput/fields/previews/ButtonOrFlagPreview.vue";
import NormalDistributionOrFlagPreview from "@/components/userInput/fields/previews/NormalDistributionOrFlagPreview.vue";
import NumberFieldPreview from "@/components/userInput/fields/previews/NumberFieldPreview.vue";
import LangInfoPreview from "@/components/userInput/fields/previews/LangInfoPreview.vue";
import MarkdownFieldPreview from "@/components/userInput/fields/previews/MarkdownFieldPreview.vue";


@Component({
    components: {
      MarkdownFieldPreview,
      LangInfoPreview,
        BlobOrFlagPreview, StringFieldPreview, StringOrFlagPreview, OptionsFieldPreview, ButtonPreview,
        ButtonOrFlagPreview, NormalDistributionOrFlagPreview, NumberFieldPreview
    }
})
export default class GenericField extends Vue {

    @Prop({required: true}) field!: Field<any>

    get fieldId(): typeof FieldId {
        return FieldId
    }
}
