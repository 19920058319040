export function isNU(value: any): boolean{
    return (value === null || value === undefined)
}

export function isNUE(value: any): boolean{
    return (value === null || value === undefined || value === "")
}

export function numberInputEq(a: any, b: any): boolean {
    if(isNUE(a) && isNUE(b)) return true
    else return a === b
}
