import {rawField} from "@/model/userInput/fields/util/raw";
import {Field} from "@/model/userInput/fields/util/fieldBase";
import {Language} from "@/services/language";
import {isNU, isNUE, numberInputEq} from "@/utilities/isNU";


export type rawNormalDistField = rawField & {
    mean?: number
    stdDeviation?: number
    minMean?: number
    maxMean?: number
    minStdDeviation?: number
    maxStdDeviation?: number
    meanPlaceholder?: string
    stdDeviationPlaceholder?: string
}

export class NormalDistributionField extends Field<rawNormalDistField> {

    private originalMean?: number
    private originalStdDev?: number
    mean?: number
    stdDeviation?: number
    minMean?: number
    maxMean?: number
    minStdDeviation?: number
    maxStdDeviation?: number
    meanPlaceholder?: string
    stdDeviationPlaceholder?: string

    get changed(): boolean {
        return !numberInputEq(this.mean, this.originalMean) ||
            !numberInputEq(this.stdDeviation, this.originalStdDev)
    }

    commitChange() {
        this.originalStdDev = this.stdDeviation
        this.originalMean = this.mean
    }

    isEmpty(): boolean {
        return (isNUE(this.mean) && isNUE(this.stdDeviation))
    }

    valid(): boolean {
        if(typeof this.mean !== "number")
            return false
        if(typeof this.stdDeviation !== "number")
            return false
        if(!isNU(this.minMean))
            if(this.mean < this.minMean!) return false
        if(!isNU(this.maxMean))
            if(this.mean > this.maxMean!) return false
        if(!isNU(this.minStdDeviation))
            if(this.stdDeviation < this.minStdDeviation!) return false
        if(!isNU(this.maxStdDeviation))
            if(this.stdDeviation > this.maxStdDeviation!) return false
        return true
    }

    makeEmpty() {
        this.mean = undefined
        this.stdDeviation = undefined
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async deserializeField(data: rawNormalDistField, language: Language): Promise<void> {
        this.originalStdDev = data.stdDeviation
        this.originalMean = data.mean
        this.mean = data.mean
        this.stdDeviation = data.stdDeviation
        this.minMean = data.minMean
        this.maxMean = data.maxMean
        this.minStdDeviation = data.minStdDeviation
        this.maxStdDeviation = data.maxStdDeviation
        this.meanPlaceholder = data.meanPlaceholder
        this.stdDeviationPlaceholder = data.stdDeviationPlaceholder
    }

    serialize(): rawNormalDistField {
        if(!this.valid())
            throw Error("cannot serialize normal distribution")
        return <rawNormalDistField> {
            mean: this.mean,
            stdDeviation: this.stdDeviation,
        }
    }
}
