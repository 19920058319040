






import {Component, Prop, Vue} from 'vue-property-decorator'
import {ProteinSearchHandler} from "@/utilities/proteinSearch";


@Component({
    components: {
    }
})
export default class ShowProteinsOnModel extends Vue {

    @Prop({required: true}) searchHandler!: ProteinSearchHandler
}
