









import {Component, Inject, Vue} from 'vue-property-decorator'
import {ServiceType} from "@/services/types";
import UserRegistrationForm from "@/components/userInput/UserRegistrationForm.vue";
import {FormGetter} from "@/services/formGetter";
import {Form} from "@/model/userInput/form/form";
import {UserService} from "@/services/user";


@Component({
  components: {UserRegistrationForm}
})
export default class SignUpForm extends Vue {
    @Inject(ServiceType.FormGetter) protected formGetter!: FormGetter
    @Inject(ServiceType.User) userS!: UserService
    form: Form|null = null
    async created(){
        this.form = await this.formGetter.getUserRegistrationForm()
    }
    async submit(){
        if(this.form && this.form.valid) {
            await this.userS.signUp(this.form)
            this.form.makeEmpty()
        }

    }
}
