




























import {Component, Inject, Prop, Vue} from "vue-property-decorator";
import {BIconArrowBarDown, BIconArrowBarUp, BIconTriangleFill} from "bootstrap-vue";
import Nested from "@/components/util/Nested.vue";
import {ServiceType} from "@/services/types";
import {Logging} from "@/services/logging";
import {AxiosCalls} from "@/services/axiosCalls";
import {SingularEventBus} from "@/utilities/eventBus";
import GenericField from "@/components/userInput/fields/util/GenericField.vue";
import {NestedFieldContainer} from "@/model/userInput/fieldContainer/nestedFieldContainer";
import {FieldId} from "@/model/userInput/fields/util/fieldIds";
import {StringField as _StringField} from "@/model/userInput/fields/common/string";
import {DownloadSOPFileDetails} from "@/services/sop";
import {DownloadENVPFileDetails} from "@/services/envp";

@Component({
    name: "rec-view-and-form-vue",
    components: {GenericField, BIconTriangleFill, BIconArrowBarDown, BIconArrowBarUp, Nested}
})
export default class RecursiveRender extends Vue {

    indentationPx = 23
    @Prop() fields!: NestedFieldContainer<any>
    @Prop({default: 0}) layersToUnfold!: number
    @Prop({required: false, default: null}) closeFormEventBus!: SingularEventBus
    @Prop({default: false}) disableOpenAll!: boolean
    @Prop({default: false}) disableCloseAll!: boolean
    @Prop({default: false}) showSopContent!:boolean
    @Prop({default: false}) showENVPContent!:boolean
    @Prop({default: false}) unfoldAtStart!:boolean
    @Prop({default: 0}) layersToIgnore!: number

    @Inject(ServiceType.Logging) protected logger!: Logging
    @Inject(ServiceType.AxiosCalls) protected as!: AxiosCalls

    created(){
      if(this.fields.fields["SOP_MODEL_SOP_FILE_ID"]) {
        const fileID = this.fields.fields["SOP_MODEL_SOP_FILE_ID"] as _StringField
        const fileName = this.fields.fields["SOP_MODEL_SOP_FILE_NAME"] as _StringField
        DownloadSOPFileDetails.prototype.fileid = fileID.value as string
        DownloadSOPFileDetails.prototype.filename = fileName.value as string
      }
      if(this.fields.fields["ENVP_MODEL_ENVP_FILE_ID"]) {
        const fileID = this.fields.fields["ENVP_MODEL_ENVP_FILE_ID"] as _StringField
        const fileName = this.fields.fields["ENVP_MODEL_ENVP_FILE_NAME"] as _StringField
        DownloadENVPFileDetails.prototype.fileid = fileID.value as string
        DownloadENVPFileDetails.prototype.filename = fileName.value as string
      }
    }
    get fieldId(): typeof FieldId {
        return FieldId
    }
}
