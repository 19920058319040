import {ProteinCombinationView, RawProteinCombinationView} from "@/model/protein/proteinCombinationView";
import {JsonLike} from "@/utilities/http";
import {Deserializable} from "@/model/serializable";
import {Language} from "@/services/language";
import {isNU} from "@/utilities/isNU";

export type CombinationFindingState = JsonLike

export type RawProteinCombinationResponse = {
    combinationViews: RawProteinCombinationView[]
    combinationFinder: CombinationFindingState
    exhausted: boolean
    numTries: number
}

export class ProteinCombinationResponse extends Deserializable<RawProteinCombinationResponse> {

    views?: ProteinCombinationView[]
    combinationFinder?: CombinationFindingState
    exhausted?: boolean
    numTries?: number

    private readonly language: Language

    constructor(raw: RawProteinCombinationResponse, lang: Language){
        super(raw)
        this.language = lang
    }

    protected async _deserialize(raw: RawProteinCombinationResponse): Promise<void> {
        this.combinationFinder = raw.combinationFinder
        this.exhausted = raw.exhausted
        this.numTries = raw.numTries
        let views: ProteinCombinationView[] = []
        for (const rawView of raw.combinationViews){
            const view = new ProteinCombinationView(rawView, this.language)
            await view.deserialize()
            views.push(view)
        }
        this.views = views
    }

    isMalformed(): boolean {
        return (!this.exhausted && isNU(this.combinationFinder)) || isNU(this.exhausted) || isNU(this.numTries)
    }
}
