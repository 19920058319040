























































import {Component, Prop, Vue} from "vue-property-decorator";
import {View} from "@/model/userInput/view/view";
import {Sorting} from "@/utilities/search/sorting";
import Pagination from "@/components/util/Pagination.vue";
import ViewList from "@/components/userInput/util/ViewList.vue";
import {BIconArrowLeft, BIconArrowLeftShort, BIconArrowRight, BSpinner} from "bootstrap-vue";
import {ViewSearchHandler} from "@/utilities/search/viewSearchHandler";

@Component({
    components: {
        Pagination,
        ViewList,
        BIconArrowLeftShort,
        BIconArrowLeft,
        BIconArrowRight,
        BSpinner,
    }
})
export default class PaginatedViewList extends Vue {

    @Prop({required: true}) searchHandler!: ViewSearchHandler<any>

    get loading(): boolean {
        return this.searchHandler.loading
    }

    viewsPerPage = 10
    viewsPerPageOptions = [10, 15, 25, 50]

    updateViewsPerPage(){
        const viewsPerPage = this.viewsPerPage
        this.searchHandler.updateSize(viewsPerPage)
        this.$emit("viewsPerPage", viewsPerPage)
    }

    goto(pageNum: number){
        this.searchHandler!.goto(pageNum-1)
    }

    showView(view: View) {
          this.$emit("showView", view)
    }

    sortBy(sortKey: Sorting) {
          this.$emit("sortBy", sortKey)
    }

    showSOP(sopFileName: string) {
          this.$emit("showSOP", sopFileName)
    }

    showENVP(envpFileName: string) {
          this.$emit("showENVP", envpFileName)
    }
}
