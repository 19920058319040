
























import {Component, Inject, Vue} from "vue-property-decorator";
import {ServiceType} from "@/services/types";
import {ProteinQueries} from "@/services/proteinQueries";
import {ProteinView as _ProteinView} from "@/model/protein/proteinView";
import ProteinView from "@/components/userInput/ProteinView.vue";


@Component({
    components: {ProteinView}
})
export default class ProteinViewByUrl extends Vue {

    @Inject(ServiceType.ProteinQueries) pq!: ProteinQueries

    proteinView: _ProteinView | null = null
    loading: boolean = true

    async getProteinView(){
        const proteinId = this.$route.params["proteinName"]
        this.proteinView = await this.pq.getNotUpdatableProteinView(proteinId)
        this.loading = false
    }

    async created(){
        await this.getProteinView()
    }
}
