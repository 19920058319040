import {rawField} from "@/model/userInput/fields/util/raw";
import {Field} from "@/model/userInput/fields/util/fieldBase";
import {Language} from "@/services/language";
import {isNU} from "@/utilities/isNU";


export type rawOptionsField = rawField & {
    chooseFrom: string[]
    value?: string
    placeholder?: string
}

export class OptionsField extends Field<rawOptionsField> {

    private originalVal?: string
    options!: string[]
    value?: string
    placeholder?: string
    private reverseOptionsMap: {[key: string]: string} = {}

    makeEmpty() {
        this.value = undefined
    }

    isEmpty(): boolean {
        return !(this.value !== null && this.value !== undefined)
    }

    async deserializeField(data: rawOptionsField, language: Language): Promise<void> {
        if(!isNU(data.value)) {
            const value = await language.parse(data.value!)
            this.originalVal = value
            this.value = value
        }
        this.options = await language.parseMultiple(data.chooseFrom)
        for (let i = 0; i < this.options.length; i++)
            this.reverseOptionsMap[this.options[i]] = data.chooseFrom[i]
        if(!isNU(data.placeholder))
            this.placeholder = await language.parse(data.placeholder!)
    }

    get changed(): boolean {
        return this.value !== this.originalVal
    }

    commitChange() {
        this.originalVal = this.value
    }

    serialize(): rawOptionsField {
        if (!this.valid())
            throw Error("cannot serialize options field")
        return <rawOptionsField>{
            // @ts-ignore
            value: this.reverseOptionsMap[this.value],
        }
    }

    valid(): boolean {
        if(isNU(this.value)) return false
        return this.options.includes(this.value!)
    }
}
