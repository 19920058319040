import { render, staticRenderFns } from "./AddProteinModal.vue?vue&type=template&id=90292248&scoped=true&"
import script from "./AddProteinModal.vue?vue&type=script&lang=ts&"
export * from "./AddProteinModal.vue?vue&type=script&lang=ts&"
import style0 from "./AddProteinModal.vue?vue&type=style&index=0&id=90292248&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90292248",
  null
  
)

export default component.exports