





















import {Component, Prop, Vue} from 'vue-property-decorator'
import {ChartProperties} from "@/utilities/chart/chartProperties";
import RadarChart from "@/components/charts/charttypes/RadarChart.vue";
import BarChart from "@/components/charts/charttypes/BarChart.vue";
import LineChart from "@/components/charts/charttypes/LineChart.vue";
import {ChartProteinColors} from "@/utilities/chart/chartProteinColors";

@Component({
  components: {
    RadarChart,
    BarChart,
    LineChart
  }
})
export default class RenderChart extends Vue{

  @Prop({required: true}) chartProperties!: ChartProperties
  @Prop({required: true}) records!: {}

  readonly fontSizeOfLegendLabel: number = 14
  chartProteinColors: ChartProteinColors[] = [
      new ChartProteinColors(
      'rgba(22,60,255,0.2)',
      'rgba(85,150,248,0.5)',
      'rgba(150,168,250)',
      'rgb(124,77,251)'
      ),
      new ChartProteinColors(
      'rgba(131,250,64,0.12)',
      'rgba(91,253,46,0.5)',
      'rgba(159,239,77)',
      'rgb(112,255,70)'
      ),
      new ChartProteinColors(
      'rgba(255,0,0,0.1)',
      'rgba(253,66,51,0.5)',
      'rgba(239,77,77)',
      'rgb(251,83,69)'
      ),
      new ChartProteinColors(
      'rgba(251,123,2,0.1)',
      'rgba(250,133,49,0.5)',
      'rgba(246,149,72)',
      'rgb(248,136,56)'
      ),
       new ChartProteinColors(
      'rgba(253,187,0,0.1)',
      'rgba(255,226,43,0.5)',
      'rgba(198,152,35,0.78)',
      'rgb(251,225,56)'
      ),
       new ChartProteinColors(
      'rgba(51,129,111,0.2)',
      'rgba(50,255,161,0.5)',
      'rgba(51,177,136)',
      'rgb(80,145,115)'
      )
  ]
  whiteColor: string = '#fff'
  dataSets: any = []

  created(){
    this.makeDataSetArray()
  }

  getArrayOfDataValues(arr: any): number[]{
    let valueArray: Object = arr
    return Object.values(valueArray)
  }

  makeDataSetArray(){
    for(let i=0; i < Object.keys(this.records).length; i++){
      const currentIndexOfDataSet = i % this.chartProteinColors.length
      const singleDataSetObject = {
        label: Object.keys(this.records)[i],
        data: this.getArrayOfDataValues(Object.values(this.records)[i]),
        borderWidth: 2,
        backgroundColor: this.chartProteinColors[currentIndexOfDataSet].backgroundColor,
        borderColor: this.chartProteinColors[currentIndexOfDataSet].borderColor,
        pointBackgroundColor: this.chartProteinColors[currentIndexOfDataSet].pointBackgroundColor,
        pointBorderColor: this.whiteColor,
        pointHoverBackgroundColor: this.whiteColor,
        pointHoverBorderColor: this.chartProteinColors[currentIndexOfDataSet].pointHoverBorderColor
      }
      this.dataSets.push(singleDataSetObject)
    }
  }
}
