import { render, staticRenderFns } from "./StoreProteinSearchForm.vue?vue&type=template&id=136fcb9e&scoped=true&"
import script from "./StoreProteinSearchForm.vue?vue&type=script&lang=ts&"
export * from "./StoreProteinSearchForm.vue?vue&type=script&lang=ts&"
import style0 from "./StoreProteinSearchForm.vue?vue&type=style&index=0&id=136fcb9e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "136fcb9e",
  null
  
)

export default component.exports