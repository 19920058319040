






















import {Component, Inject, Vue} from 'vue-property-decorator'
import {UserService} from "@/services/user";
import {ServiceType} from "@/services/types";
import {BButton} from "bootstrap-vue";

@Component({
    components: {
        BButton,
    }
})
export default class Login extends Vue {
    loginData = {
        username: "",
        password: "",
    }
    @Inject(ServiceType.User) protected userService!: UserService

    login(): void {
      this.userService.login(this.loginData.username, this.loginData.password)
    }
}
