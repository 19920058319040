

export class ChartProteinColors{
    public backgroundColor: string = ""
    public borderColor: string = ""
    public pointBackgroundColor: string = ""
    public pointHoverBorderColor: string = ""

    constructor(backgroundColor: string, borderColor: string, pointBackgroundColor: string, pointHoverBorderColor: string) {
        this.backgroundColor = backgroundColor
        this.borderColor = borderColor
        this.pointBackgroundColor = pointBackgroundColor
        this.pointHoverBorderColor = pointHoverBorderColor
    }
}