import {rawField} from "@/model/userInput/fields/util/raw";
import {Field} from "@/model/userInput/fields/util/fieldBase";
import {Language} from "@/services/language";
import {isNU, isNUE} from "@/utilities/isNU";
import {LangInfo} from "@/model/LangInfo";


export type rawLangInfoField = rawField & {
    value: string
}

export class LangInfoField extends Field<rawLangInfoField> {

    value?: LangInfo

    get changed(): boolean {
        return false
    }

    commitChange() {}

    makeEmpty() { this.value = undefined }

    isEmpty(): boolean { return isNUE(this.value) }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async deserializeField(data: rawLangInfoField, language: Language): Promise<void> {
        const langInfo: LangInfo = new LangInfo(data.value!, language)
        await langInfo.deserialize()
        this.value = langInfo
    }

    serialize(): rawLangInfoField {
        if (!this.valid())
            throw Error("cannot serialize string field")
        return <rawLangInfoField>{
            value: this.value?.id,
        }
    }

    valid(): boolean {
        return !isNU(this.value)
    }
}
