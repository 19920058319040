


















import {Component, Inject, Vue} from 'vue-property-decorator'
import {BIconArrowLeftShort, BIconSearch, BIconX} from "bootstrap-vue";
import {ServiceType} from "@/services/types";
import PaginatedViewList from "@/components/userInput/util/PaginatedViewList.vue";
import UserView from "@/components/userInput/UserView.vue";
import {envpSearchHandler} from "@/utilities/envpSearch";
import {ENVPServices, SearchENVPFileDetails} from "@/services/envp";
import {ENVPView} from "@/model/envp/envpView";
import ENVPViewMarkdown from "@/components/envp/ENVPViewMarkdown.vue"
import {isNUE} from "@/utilities/isNU";
import {Language} from "@/services/language";

@Component({
    components: {UserView, PaginatedViewList, BIconSearch, BIconArrowLeftShort, BIconX, ENVPViewMarkdown}
})
export default class ENVPSearch extends Vue {

    searchString: string = ""
    envpSearchHandler: envpSearchHandler | null = null
    viewToShow: ENVPView | null = null

    showENVPContent: boolean=true

    readonly initialBatchSize: number = 10
    readonly paginationSize: number = 7
    readonly maxUserViewsCached: number = 1000

    @Inject(ServiceType.Language) language!: Language
    @Inject(ServiceType.ENVP) envpServices!: ENVPServices

    handleSearch(){
        if(this.envpSearchHandler === null) {
            this.envpSearchHandler = new envpSearchHandler(
                this.initialBatchSize,
                this.paginationSize,
                this.maxUserViewsCached,
                this.searchString,
                this.envpServices,
                this.language
            )
        }
        else {
            this.envpSearchHandler.setSearchString(this.searchString)
        }
        this.envpSearchHandler.firstBatch()
    }
    detectSearchFieldValueChange(){
      this.handleSearch()
    }
    created(){
      if(!isNUE(SearchENVPFileDetails.prototype.filename_to_search)){
        this.searchString = SearchENVPFileDetails.prototype.filename_to_search
        SearchENVPFileDetails.prototype.filename_to_search = ""
        this.handleSearch()
        this.searchString = ""
      }
      else this.handleSearch()
    }
    showView(envpView: ENVPView) {
        this.viewToShow = envpView
    }
    backToList(){
        this.viewToShow = null
    }
}
