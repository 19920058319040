import {HTTPRequest, Method, urlFor} from "@/utilities/http";
import {AxiosCalls} from "@/services/axiosCalls";
import config from "@/config/config.json"


export interface Language{
    invalidateLangMap(): void
    parse(langId: string): Promise<string>
    parseMultiple(langIds: string[]): Promise<string[]>
    getFirstHierarchy(langId: string): string
    removeFirstHierarchy(langId: string): string
    removeLastHierarchy(langId: string): string
    getHierarchies(langId: string): string[]
    turnHierarchiesToDisplayedText(hierarchies: string[]): string
    idsToHierarchicalId(hierarchies: string[]): string
}


const LANG_INFO_ADD_CHAR = "+"
const LANG_INFO_ADD_CHAR_FOR_DISPLAY_NAMES = " "
const LANG_INFO_NEW_HIERARCHY_LEVEL_CHAR = "-"
const LANG_INFO_NEW_HIERARCHY_LEVEL_CHAR_FOR_DISPLAY_NAMES = " / "
const ESCAPE_ID_PREFIX = "#esc#"


export class LanguageImpl implements Language{
    private axiosCalls: AxiosCalls
    private langMap: {[key:string]: string} = {}
    private langMapLoaded = false

    constructor(axiosCalls: AxiosCalls) {
        this.axiosCalls = axiosCalls
    }

    invalidateLangMap(){
        this.langMap = {}
        this.langMapLoaded = false
    }

    async loadLanguageMap(): Promise<{[key:string]: string}>{
        const url = urlFor(config.REST_API.ROUTES.METADATA.GET_LANG_MAP)
        const req: HTTPRequest = new HTTPRequest(Method.GET, url, {}, [])
        const res = await this.axiosCalls.request(req)
        return res.data["langMap"]
    }

    async parse(langId: string): Promise<string> {
        if (LanguageImpl.isEscapedAsText(langId)){
            return LanguageImpl.asText(langId)
        }
        let parsedName = ""
        const hierarchies = this.getHierarchies(langId)
        for (const hierarchy of hierarchies) {
            const labelIdsOfHierarchy = hierarchy.split(LANG_INFO_ADD_CHAR)
            let hierarchyDisplayName = ""
            for (const labelIdOfHierarchy of labelIdsOfHierarchy) {
                const toAdd = await this.translateId(labelIdOfHierarchy)
                if (hierarchyDisplayName.length > 0)
                    hierarchyDisplayName += LANG_INFO_ADD_CHAR_FOR_DISPLAY_NAMES + toAdd
                else
                    hierarchyDisplayName = toAdd
            }
            if (parsedName.length > 0) {
                parsedName += LANG_INFO_NEW_HIERARCHY_LEVEL_CHAR_FOR_DISPLAY_NAMES + hierarchyDisplayName
            } else {
                parsedName = hierarchyDisplayName
            }
        }
        return parsedName
    }

    private static isEscapedAsText(langUnitId: string): boolean {
        return langUnitId.startsWith(ESCAPE_ID_PREFIX)
    }

    private static asText(langUnitId: string): string {
        if (LanguageImpl.isEscapedAsText(langUnitId))
            return langUnitId.slice(ESCAPE_ID_PREFIX.length)
        else
            return langUnitId
    }

    private async translateId(langUnitId: string): Promise<string> {
        if (LanguageImpl.isEscapedAsText(langUnitId))
            return LanguageImpl.asText(langUnitId)
        else
            return await this.getFromLangMap(langUnitId)
    }

    private async getFromLangMap(langUnitId: string): Promise<string> {
        if(this.langMapLoaded){
            return this.langMap[langUnitId]
        }
        else{
            this.langMap = await this.loadLanguageMap()
            this.langMapLoaded = true
            return this.langMap[langUnitId]
        }
    }

    async parseMultiple(langUnitIds: string[]): Promise<string[]> {
        const parsedValues: string[] = []
        for (const langUnitId of langUnitIds) {
            const parsed = await this.parse(langUnitId)
            parsedValues.push(parsed)
        }
        return parsedValues
    }

    idsToHierarchicalId(hierarchies: string[]): string {
        return hierarchies.join(LANG_INFO_NEW_HIERARCHY_LEVEL_CHAR)
    }

    getHierarchies(langId: string): string[] {
        if (LanguageImpl.isEscapedAsText(langId))
            return [langId]
        return langId.split(LANG_INFO_NEW_HIERARCHY_LEVEL_CHAR)
    }

    getFirstHierarchy(langId: string): string {
        if (LanguageImpl.isEscapedAsText(langId))
            return langId
        const idx = langId.indexOf(LANG_INFO_NEW_HIERARCHY_LEVEL_CHAR)
        return langId.substr(0, idx)
    }

    removeFirstHierarchy(langId: string): string {
        const idx = langId.indexOf(LANG_INFO_NEW_HIERARCHY_LEVEL_CHAR)
        return langId.substr(idx+1)
    }

    removeLastHierarchy(langId: string): string {
        let next_idx = langId.indexOf(LANG_INFO_NEW_HIERARCHY_LEVEL_CHAR)
        let idx = 0
        while (next_idx != -1) {
            idx = next_idx
            next_idx = langId.indexOf(LANG_INFO_NEW_HIERARCHY_LEVEL_CHAR, idx + 1)
        }
        return langId.substr(0, idx)
    }

    turnHierarchiesToDisplayedText(hierarchies: string[]): string {
        return hierarchies.join(LANG_INFO_NEW_HIERARCHY_LEVEL_CHAR_FOR_DISPLAY_NAMES)
    }
}
