







import {Component, Prop, Vue} from "vue-property-decorator";
import FlagPreview from "@/components/userInput/fields/previews/FlagPreview.vue";
import BlobFieldPreview from "@/components/userInput/fields/previews/BlobFieldPreview.vue";
import {BlobOrNonDetFieldT} from "@/model/userInput/fields/common/blob_or_non_det";

@Component({
    components: {BlobFieldPreview, FlagPreview}
})
export default class BlobOrFlagPreview extends Vue {
    @Prop({required: true}) field!: BlobOrNonDetFieldT
}
