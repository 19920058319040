
























import {Component, Inject, Vue} from 'vue-property-decorator'
import {BIconCheck2,BIconX} from "bootstrap-vue";
import ProteinIdentifierInput from "@/components/proteins/ProteinIdentifierInput.vue";
import {ServiceType} from "@/services/types";
import {ProteinQueries} from "@/services/proteinQueries";
import {Logging} from "@/services/logging";
import {ProteinView} from "@/model/protein/proteinView";

@Component({
  components: {
    BIconX,
    BIconCheck2,
    ProteinIdentifierInput
  }
})

export default class AddProteinModal extends Vue {

    isValidProteinName: boolean = false
    @Inject(ServiceType.ProteinQueries) pq!: ProteinQueries
    @Inject(ServiceType.Logging) logging!: Logging

    referenceProtein: ProteinView | null = null

    hideAddProteinModal(){
      this.$emit('hideAddProteinModal')
    }

    async getAddedValidProteinName(input: string){
      if(input != null){
        const view = await this.pq.getNotUpdatableProteinView(input)
        if(view === null) {
          this.logging.addError(`Protein ${input}`+ this.$t('GeneralErrorMessages.NotLoaded'))
        }
        else {
          this.referenceProtein = view
        }
        this.isValidProteinName = true
      }
    }

    addedProteinNameIsInvalid(){
      this.isValidProteinName = false
    }
    doneAddProteinButton(){
      this.emitSelectedProtein()
      this.hideAddProteinModal()
    }

    emitSelectedProtein(){
      this.$emit("selectedProtein", this.referenceProtein)
    }
}

