






































import {Vue, Component, Inject} from "vue-property-decorator";
import Stepper from "@/components/util/Stepper.vue";
import {StepperData} from "@/utilities/misc/stepper";
import ProteinCombinationTargetForm from "@/components/userInput/ProteinCombinationTargetForm.vue";
import {ServiceType} from "@/services/types";
import {FormGetter} from "@/services/formGetter";
import {Form} from "@/model/userInput/form/form";
import {Logging} from "@/services/logging";
import ProteinIdentifierInputs from "@/components/proteins/ProteinIdentifierInputs.vue";
import {BIconArrowRightCircle} from "bootstrap-vue";
import TooltipButton from "@/components/util/TooltipButton.vue";
import LimitProteinsForm from "@/components/userInput/LimitProteinsForm.vue";
import {ProteinCombinationTargetForm as _ProteinCombinationTargetForm} from "@/model/protein/proteinCombinationTargetForm"
import NumberOfProteinsInCombinationForm from "@/components/userInput/NumberOfProteinsInCombinationForm.vue";
import {ProteinCombinationNumberOfProteinsForm} from "@/model/protein/proteinCombinationNumberOfProteinsForm";


@Component({
  components: {
    TooltipButton,
    LimitProteinsForm,
    ProteinCombinationTargetForm,
    Stepper,
    ProteinIdentifierInputs,
    BIconArrowRightCircle,
    NumberOfProteinsInCombinationForm
  }
})
export default class FindCombinationsStepper extends Vue {

    @Inject(ServiceType.FormGetter) formGetter!: FormGetter
    @Inject(ServiceType.Logging) logging!: Logging

    proteinCombinationNumberOfProteinsForm: ProteinCombinationNumberOfProteinsForm | null = null
    proteinCombinationTargetsForm: _ProteinCombinationTargetForm | null = null
    proteinSearchForm: Form | null = null
    proteinIdentifiersState: string[] | null = []

    steps = {
        SELECT_TARGETS: 0,
        PROVIDE_REFERENCES: 1,
        LIMIT_PROTEINS: 2,
    }

    stepToShow: number = this.steps.SELECT_TARGETS
    stepLabels: string[] = []
    stepIndex: number = 0
    findCombinationStepperData?: StepperData

    get nextStepIsSubmit(): boolean {
      return this.showingMostRecentStep && this.stepIndex === this.steps.LIMIT_PROTEINS
    }

    get targetStepInvalid(): boolean {
      if(this.proteinCombinationTargetsForm === null) return true
      return !this.proteinCombinationTargetsForm.valid
    }

    get limitProteinsStepInvalid(): boolean {
      return !this.proteinSearchForm?.valid || !this.proteinCombinationNumberOfProteinsForm?.valid
    }

    get provideProteinsStepInvalid(): boolean {
      return this.proteinIdentifiersState === null
    }

    get showingTargetSelectionStep() : boolean {
      return this.stepToShow === this.steps.SELECT_TARGETS
    }

    get showingLimitProteinsStep(): boolean {
      return this.stepToShow === this.steps.LIMIT_PROTEINS
    }

    get showingProvideReferencesStep(): boolean {
      return this.stepToShow === this.steps.PROVIDE_REFERENCES
    }

    get goToNextStepPossible(): boolean {
      if(this.showingTargetSelectionStep) return !this.targetStepInvalid
      if(this.showingLimitProteinsStep) return !this.limitProteinsStepInvalid
      if(this.showingProvideReferencesStep) return !this.provideProteinsStepInvalid
      return false
    }

    get feedback(): string | null  {
      if(this.showingTargetSelectionStep && this.targetStepInvalid)
        return this.$t('FindCombinationsStepper.SelectTargetsFeedback').toString()
      if(this.showingLimitProteinsStep && this.limitProteinsStepInvalid)
        return this.$t('FindCombinationsStepper.LimitProteinsFeedback').toString()
      if(this.showingProvideReferencesStep && this.provideProteinsStepInvalid)
        return this.$t('FindCombinationsStepper.ProvideReferencesFeedback').toString()
      return null
    }

    get formsLoaded(): boolean {
        return this.proteinSearchForm !== null &&
            this.proteinCombinationTargetsForm !== null &&
            this.proteinCombinationNumberOfProteinsForm !== null
    }

    handleProteinIdsInvalid(){
      this.proteinIdentifiersState = null
    }

    handleProteinIdsValid(inputs: string[]){
      this.proteinIdentifiersState = inputs
    }

    logFormLoadingError(){
      this.logging.addError("Formulare konnten nicht geladen werden")
    }

    async created(){
        this.stepLabels = [
            this.$t('FindCombinationsStepper.StepLabels.chooseTargets').toString(),
            this.$t('FindCombinationsStepper.StepLabels.setReferenceProteins').toString(),
            this.$t('FindCombinationsStepper.StepLabels.limitProteins').toString(),
        ]
        this.proteinCombinationNumberOfProteinsForm = await this.formGetter.getProteinCombinationNumberOfProteinsForm()
        this.proteinCombinationTargetsForm = await this.formGetter.getProteinCombinationTargetsForm()
        this.proteinSearchForm = await this.formGetter.getProteinSearchForm()
        if(!this.formsLoaded) this.logFormLoadingError()
    }

    showStep(index: number){
        // only show step if stepper already advanced to this step
        if(index <= this.stepIndex) this.stepToShow = index
    }

    get showingMostRecentStep(): boolean {
      return this.stepIndex === this.stepToShow
    }

    submit(payload: {
              combinationTargetsForm: _ProteinCombinationTargetForm,
              numberOfProteinsInCombinationForm: ProteinCombinationNumberOfProteinsForm,
              limitProteinsForm: Form,
              proteinIds: string[]
            }){
      this.$emit("submit", payload)
    }

    nextStep(){
        if(this.nextStepIsSubmit) {
            this.submit({
              combinationTargetsForm: this.proteinCombinationTargetsForm!,
              numberOfProteinsInCombinationForm: this.proteinCombinationNumberOfProteinsForm!,
              limitProteinsForm: this.proteinSearchForm!,
              proteinIds: this.proteinIdentifiersState!
            })
        }
        else if(this.showingMostRecentStep){
            this.stepIndex ++
            this.stepToShow ++
        }
        else {
            this.stepToShow ++
        }
    }
}
