import {AxiosCalls} from "@/services/axiosCalls";
import {Mutex} from "async-mutex"
import {HTTPErrResponse, HTTPRequest, HTTPResponse, JsonLike, Method, Url, urlFor} from "@/utilities/http";
import config from "@/config/config.json";
import {Logging, LoggingLevel} from "@/services/logging";
import {SearchRespMetadata} from "@/utilities/searchResponse";
import {searchRequestMetadata} from "@/utilities/search/helpers";
import {Language} from "@/services/language";
import {ProteinView} from "@/model/protein/proteinView";
import {SearchQueryDescriptor} from "@/utilities/search/searchQueryDescriptor";
import {Sorting} from "@/utilities/search/sorting";
import {RawViewColumns, ViewColumn} from "@/model/userInput/view/viewList";
import {ProteinViewList, RawProteinViewList} from "@/model/protein/proteinViewList";
import {Form} from "@/model/userInput/form/form";
import {CombinationFindingState, ProteinCombinationResponse} from "@/model/protein/proteinCombinationResponse";
import {ProteinCombinationTargetForm} from "@/model/protein/proteinCombinationTargetForm";
import {ProteinCombinationNumberOfProteinsForm} from "@/model/protein/proteinCombinationNumberOfProteinsForm";
import i18n from "@/i18n";


/**
 * This service is responsible for sending CRUD Protein related REST calls to the backend.
 */
export interface ProteinQueries {

    getCombination(targetsForm: ProteinCombinationTargetForm,
                   numProteinsInCombinationForm: ProteinCombinationNumberOfProteinsForm,
                   limitProteinsForm: Form,
                   referenceProteins: string[]):
        Promise<{
            combinationResp:ProteinCombinationResponse,
            viewCols: ViewColumn[],
            maxPossibleCombinations: number } | null>

    nextCombination(combinationFindingState: CombinationFindingState): Promise<ProteinCombinationResponse | null>

    getStoredSearchForms(): Promise<SearchQueryDescriptor[] | null>

    restoreSearchForm(name: string): Promise<Form|null>

    deleteStoredSearchForm(name: string): Promise<boolean>

    storeSearchForm(searchForm: Form, saveSearchForm: Form): Promise<boolean>

    getProteinView(proteinId: string): Promise<ProteinView | null>

    getUpdatableProteinView(proteinId: string): Promise<ProteinView | null>

    getNotUpdatableProteinView(proteinId: string): Promise<ProteinView | null>

    getProteinViewColumns(): Promise<ViewColumn[]|null>

    getChartObservationsViewColumns(): Promise<ViewColumn[]|null>

    setProteinPreview(form: Form): Promise<boolean>

    setProteinChartsPreview(form: Form): Promise<boolean>

    setChartsCategoriesFilterPreview(form: Form): Promise<boolean>

    search(query: Form, offset: searchRequestMetadata, sorting?: Sorting): Promise<[ProteinViewList, SearchRespMetadata] | null>

    searchChartObservations(query: Form, offset: searchRequestMetadata): Promise<[ProteinViewList, SearchRespMetadata] | null>

    getProteinNames(): Promise<string[]|null>

    deleteAllProteins(): Promise<boolean>

    getSearchSuggestions(propertyType: string): Promise<string[]>

    getSearchHelp(typeId: string): Promise<string>
}

export class ProteinQueriesImp implements ProteinQueries {

    private axiosHandler: AxiosCalls
    private logger: Logging
    private language: Language
    private allProteinNamesCache: string[]|null = null
    private suggestionsCache: {[key:string]: string[]}|null = null
    private suggestionsCacheMutex: Mutex = new Mutex()
    private searchHintsCache: {[key:string]: string}|null = null
    private searchHintsCacheMutex: Mutex = new Mutex()


    constructor(
        axiosHandler: AxiosCalls,
        logger: Logging,
        language: Language
    ) {
        this.axiosHandler = axiosHandler
        this.logger = logger
        this.language = language
    }

    async getProteinNames(): Promise<string[]|null> {
        if(this.allProteinNamesCache !== null) return this.allProteinNamesCache
        const url = urlFor(config.REST_API.ROUTES.PROTEIN_QUERIES.GET_ALL_PROTEIN_NAMES)
        const req = new HTTPRequest(Method.GET, url, {}, [])
        try {
            const res = await this.axiosHandler.request(req)
            const names = res.data["proteinNames"]
            this.allProteinNamesCache = names
            return names
        } catch (error) {
            return null
        }
    }

    async deleteAllProteins(): Promise<boolean> {
        const url = urlFor(config.REST_API.ROUTES.PROTEIN_QUERIES.DELETE_ALL_PROTEINS)
        const req = new HTTPRequest(Method.GET, url, {}, [])
        try {
            await this.axiosHandler.request(req)
            this.logger.addInfo(i18n.t("InfoTexts.ProteinsDeletedSuccessful") as string)
            return true
        } catch {
            this.logger.addError(i18n.t("InfoTexts.ProteinsDeletedUnSuccessful") as string)
            return false
        }
    }

    async getCombination(targetsForm: ProteinCombinationTargetForm,
                         numProteinsInCombinationForm: ProteinCombinationNumberOfProteinsForm,
                         limitProteinsForm: Form,
                         referenceProteins: string[]):
            Promise<{
            combinationResp:ProteinCombinationResponse,
            viewCols: ViewColumn[],
            maxPossibleCombinations: number } | null> {

        const data = <JsonLike>{
            proteinSearchForm: limitProteinsForm.serialize(),
            targetForm: targetsForm.serialize(),
            proteinsInCombinationForm: numProteinsInCombinationForm.serialize(),
            references: referenceProteins
        }
        const url = urlFor(config.REST_API.ROUTES.PROTEIN_QUERIES.GET_COMBINATIONS)
        const req = new HTTPRequest(Method.POST, url, data, [])
        try {
            const res = await this.axiosHandler.request(req)
            const rawCombinationResp = res.data["combinationResponse"]
            const rawViewColumns: RawViewColumns = res.data["combinationViewListColumns"]
            const maxPossibleCombinations: number = res.data["maxNumberOfPossibleCombinations"]
            const combinationResp = new ProteinCombinationResponse(rawCombinationResp, this.language)
            const viewColumns: ViewColumn[] = []
            for(const raw of rawViewColumns){
                const viewColumn = new ViewColumn(raw, this.language)
                await viewColumn.deserialize()
                viewColumns.push(viewColumn)
            }
            await combinationResp.deserialize()
            return {
                combinationResp: combinationResp,
                viewCols: viewColumns,
                maxPossibleCombinations: maxPossibleCombinations
            }
        } catch (error) {
            console.log(error)
            return null
        }
    }

    async nextCombination(combinationFindingState: CombinationFindingState): Promise<ProteinCombinationResponse | null> {
        const data = {combinationFinder: combinationFindingState}
        const url = urlFor(config.REST_API.ROUTES.PROTEIN_QUERIES.NEXT_COMBINATIONS)
        const req = new HTTPRequest(Method.POST, url, data, [])
        try {
            const res = await this.axiosHandler.request(req)
            const raw = res.data["combinationResponse"]
            const combinationResp = new ProteinCombinationResponse(raw, this.language)
            await combinationResp.deserialize()
            return combinationResp
        } catch (error) {
            console.log(error)
            return null
        }
    }

    async getProteinViewColumns(): Promise<ViewColumn[]|null> {
        const url = urlFor(config.REST_API.ROUTES.PROTEIN_QUERIES.GET_VIEW_COLUMNS)
        const req = new HTTPRequest(Method.GET, url, {}, [])
        try {
            const res = await this.axiosHandler.request(req)
            const rawColumns = res.data["columns"]
            const columns: ViewColumn[] = []
            for (const c of rawColumns) {
                const col = new ViewColumn(c, this.language)
                await col.deserialize()
                columns.push(col)
            }
            return columns
        } catch(error) {
            console.log(error)
            return null
        }
    }

    async getChartObservationsViewColumns(): Promise<ViewColumn[]|null> {
        const url = urlFor(config.REST_API.ROUTES.PROTEIN_QUERIES.GET_CHART_OBSERVATIONS_VIEW_COLUMNS)
        const req = new HTTPRequest(Method.GET, url, {}, [])
        try {
            const res = await this.axiosHandler.request(req)
            const rawColumns = res.data["columns"]
            const columns: ViewColumn[] = []
            for (const c of rawColumns) {
                const col = new ViewColumn(c, this.language)
                await col.deserialize()
                columns.push(col)
            }
            return columns
        } catch(error) {
            console.log(error)
            return null
        }
    }

    async setProteinPreview(form: Form): Promise<boolean> {
        const url = urlFor(config.REST_API.ROUTES.PROTEIN_QUERIES.SET_PROTEIN_PREVIEW)
        const data = <JsonLike>{"form": form.serialize(),}
        const req = new HTTPRequest(Method.POST, url, data, [])
        try {
            await this.axiosHandler.request(req)
            this.logger.addInfo("Einstellung für Proteinvorschau angepasst")
            return true
        } catch {
            this.logger.addError("Verändern der Proteinvorschau-Einstellungen fehlgeschlagen!")
            return false
        }
    }

    async setProteinChartsPreview(form: Form): Promise<boolean> {
        const url = urlFor(config.REST_API.ROUTES.PROTEIN_QUERIES.SET_PROTEIN_CHARTS_PREVIEW)
        const data = <JsonLike>{"form": form.serialize(),}
        const req = new HTTPRequest(Method.POST, url, data, [])
        try {
            await this.axiosHandler.request(req)
            this.logger.addInfo(i18n.t("InfoTexts.PreviewSuccessful") as string)
            return true
        } catch {
            this.logger.addError(i18n.t("InfoTexts.PreviewNotSuccessful") as string)
            return false
        }
    }

    async setChartsCategoriesFilterPreview(form: Form): Promise<boolean> {
        const url = urlFor(config.REST_API.ROUTES.PROTEIN_QUERIES.SET_CHARTS_CATEGORIES_FILTER_PREVIEW)
        const data = <JsonLike>{"form": form.serialize(),}
        const req = new HTTPRequest(Method.POST, url, data, [])
        try {
            await this.axiosHandler.request(req)
            this.logger.addInfo(i18n.t("InfoTexts.PreviewSuccessful") as string)
            return true
        } catch {
            this.logger.addError(i18n.t("InfoTexts.PreviewNotSuccessful") as string)
            return false
        }
    }

    private async handleProteinViewRequest(proteinId: string, url: string){
        const url_ = urlFor(url)
        const data = {"proteinName": proteinId}
        const req = new HTTPRequest(Method.POST, url_, data, [])
        try{
            const res = await this.axiosHandler.request(req)
            const rawProteinView = res.data["proteinView"]
            const view = new ProteinView(rawProteinView, this.language)
            await view.deserialize()
            return view
        }
        catch{
            this.logger.addError(
                    `Protein ${proteinId} wurde nicht gefunden`,
                    undefined,
                    LoggingLevel.USER_INFO
            )
            return null
        }
    }

    async getNotUpdatableProteinView(proteinId: string): Promise<ProteinView | null> {
        return await this.handleProteinViewRequest(proteinId, config.REST_API.ROUTES.PROTEIN_QUERIES.GET_NOT_UPDATABLE_VIEW)
    }

    async getUpdatableProteinView(proteinId: string): Promise<ProteinView | null> {
        return await this.handleProteinViewRequest(proteinId, config.REST_API.ROUTES.PROTEIN_QUERIES.GET_UPDATABLE_VIEW)
    }

    async getProteinView(proteinId: string): Promise<ProteinView | null> {
        return await this.handleProteinViewRequest(proteinId, config.REST_API.ROUTES.PROTEIN_QUERIES.GET_VIEW)
    }

    async search(searchForm: Form, srm: searchRequestMetadata, sorting?: Sorting): Promise<[ProteinViewList, SearchRespMetadata] | null> {
        let url: Url
        let data: JsonLike
        if(sorting){
            url = urlFor(config.REST_API.ROUTES.PROTEIN_QUERIES.SEARCH_SORTED)
            data = <JsonLike> {
                "form": searchForm.serialize(),
                "searchRequestMetadata": srm,
                "sorting": {
                    "ascending": sorting.ascending,
                    "sortBy": sorting.sortKey
                }
            }
        }
        else{
            url = urlFor(config.REST_API.ROUTES.PROTEIN_QUERIES.SEARCH)
            data = <JsonLike> {
                "form": searchForm.serialize(),
                "searchRequestMetadata": srm,
            }
        }
        const req = new HTTPRequest(Method.POST, url, data, [])
        const res = await this.axiosHandler.request(req)
        const proteinViewsData: RawProteinViewList = res.data["proteinViews"]
        const srm2: SearchRespMetadata = res.data["searchResponseMetadata"]
        const proteinViews: ProteinViewList = new ProteinViewList(proteinViewsData, this.language)
        await proteinViews.deserialize()
        return [proteinViews, srm2]
    }

    async searchChartObservations(searchForm: Form, srm: searchRequestMetadata): Promise<[ProteinViewList, SearchRespMetadata] | null> {
        let url: Url
        let data: JsonLike
        url = urlFor(config.REST_API.ROUTES.PROTEIN_QUERIES.SEARCH_CHART_OBSERVATIONS)
        data = <JsonLike> {
            "form": searchForm.serialize(),
            "searchRequestMetadata": srm,
        }
        const req = new HTTPRequest(Method.POST, url, data, [])
        const res = await this.axiosHandler.request(req)
        const proteinViewsData: RawProteinViewList = res.data["proteinViews"]
        const srm2: SearchRespMetadata = res.data["searchResponseMetadata"]
        const proteinViews: ProteinViewList = new ProteinViewList(proteinViewsData, this.language)
        await proteinViews.deserialize()
        return [proteinViews, srm2]
    }

    async getStoredSearchForms(): Promise<SearchQueryDescriptor[] | null> {
        const url: Url = urlFor(config.REST_API.ROUTES.PROTEIN_QUERIES.GET_STORED_SEARCH_FORMS)
        return this.axiosHandler.request(
            new HTTPRequest(Method.GET, url, {}, []))
            .then((res: HTTPResponse) => {
                try{
                    return res.data["searchDescriptors"]
                } catch {
                    this.logger.addError(
                        "abgespeicherte Suchformulare konnten nicht geladen werden",
                        undefined,
                        LoggingLevel.USER_INFO)
                    return null
                }
            })
            .catch((err: HTTPErrResponse) => {
                this.logger.addError(
                    "abgespeicherte Suchformulare konnten nicht geladen werden",
                    undefined,
                    LoggingLevel.USER_INFO)
                this.logger.addError(err.statusText, undefined, LoggingLevel.TECHNICAL)
                return null
            })
    }

    async restoreSearchForm(name: string): Promise<Form | null> {
        const url: Url = urlFor(config.REST_API.ROUTES.PROTEIN_QUERIES.RESTORE_SEARCH_FORM)
        const data: JsonLike = {"queryName": name}
        try{
            const res = await this.axiosHandler.request(new HTTPRequest(Method.GET, url, data, []))
            const formData = res.data["searchForm"]
            const form = new Form(formData, this.language)
            await form.deserialize()
            this.logger.addInfo(
                "Suchformular wurde wiederhergestellt",
                undefined,
                LoggingLevel.USER_INFO)
            return form
        } catch(error) {
            console.log(error)
            this.logger.addError(
            "Suchformular konnte nicht geladen werden",
            undefined,
            LoggingLevel.USER_INFO)
            return null
        }
    }

    async deleteStoredSearchForm(name: string): Promise<boolean> {
        const url: Url = urlFor(config.REST_API.ROUTES.PROTEIN_QUERIES.DELETED_STORED_SEARCH_FORM)
        const data: JsonLike = {"queryName": name}
        try{
            const res = await this.axiosHandler.request(new HTTPRequest(Method.POST, url, data, []))
            const success: boolean = res.data["success"]
            if(success) this.logger.addInfo(
                "Suchformular wurde gelöscht",
                undefined,
                LoggingLevel.USER_INFO)
             else this.logger.addError(
                "Suchformular konnte nicht gelöscht werden",
                undefined,
                LoggingLevel.USER_INFO)
            return success
        } catch {
             this.logger.addError(
                "Suchformular konnte nicht gelöscht werden",
                undefined,
                LoggingLevel.USER_INFO)
            return false
        }
    }

    async storeSearchForm(searchForm: Form, saveSearchForm: Form): Promise<boolean> {
        const url: Url = urlFor(config.REST_API.ROUTES.PROTEIN_QUERIES.STORE_SEARCH_FORM)
        const serializedSearchForm = await searchForm.serialize()
        const serializedSaveSearchForm = await saveSearchForm.serialize()
        const data = <JsonLike>{
            "searchForm": serializedSearchForm,
            "saveSearchForm": serializedSaveSearchForm
        }
        try{
            const req = new HTTPRequest(Method.POST, url, data, [])
            const res = await this.axiosHandler.request(req)
            if(res.data["success"]){
                this.logger.addInfo(
                    "Suchformular wurde gespeichert",
                    undefined,
                    LoggingLevel.USER_INFO)
                return true
            }
            else {
                if(res.data["alreadyExists"]){
                    this.logger.addWarning(
                        "Suche unter diesem Namen existiert schon",
                        10000,
                        LoggingLevel.USER_INFO)
                    return true
                }
                else {
                    this.logger.addError(
                        "Suche konnte nicht gespeichert werden",
                        undefined,
                        LoggingLevel.USER_INFO)
                    return false
                }
            }

        } catch {
             this.logger.addError(
                "Suche konnte nicht gespeichert werden",
                undefined,
                LoggingLevel.USER_INFO)
            return false
        }
    }

    async getSearchSuggestions(propertyType: string): Promise<string[]> {
        return await this.suggestionsCacheMutex.runExclusive(async () => {
            if (this.suggestionsCache === null) {
                this.suggestionsCache = {}
                const url = urlFor(config.REST_API.ROUTES.PROTEIN_QUERIES.GET_SEARCH_SUGGESTIONS)
                const req = new HTTPRequest(Method.POST, url, {}, [])
                try {
                    const res = await this.axiosHandler.request(req)
                    for (const element of res.data["suggestions"]) {
                        this.suggestionsCache[element["propertyType"]] = element["suggestions"]
                    }
                } catch (error) {
                    return []
                }
            }
            if (this.suggestionsCache[propertyType] === undefined) {
                return []
            } else {
                return this.suggestionsCache[propertyType]
            }
        })
    }

    async getSearchHelp(typeId: string): Promise<string> {
        return await this.searchHintsCacheMutex.runExclusive(async () => {
            if (this.searchHintsCache === null) {
                this.searchHintsCache = {}
                const url = urlFor(config.REST_API.ROUTES.PROTEIN_QUERIES.GET_SEARCH_HELP)
                const req = new HTTPRequest(Method.POST, url, {}, [])
                try {
                    const res = await this.axiosHandler.request(req)
                    for (const element of res.data["searchHelp"]) {
                        this.searchHintsCache[element["typeId"]] = element["searchHelp"]
                    }
                } catch (error) {
                    return ""
                }
            }
            if (this.searchHintsCache[typeId] === undefined) {
                return ""
            } else {
                return this.searchHintsCache[typeId]
            }
        })
    }
}
