



























































import {Component, Prop, Vue} from 'vue-property-decorator'
import {BIconArrowBarDown, BIconArrowBarUp} from "bootstrap-vue";
import {SingularEventBus, SingularEventSubscriber} from "@/utilities/eventBus";
import CollapseToggleIcon from "@/components/util/CollapseToggleIcon.vue";
import {VNode} from "@/utilities/misc/nested";

@Component({
    name: "nested",
    components: {
      BIconArrowBarUp,
      BIconArrowBarDown,
      CollapseToggleIcon
    }
})
export default class Nested extends Vue implements SingularEventSubscriber{

    @Prop({required: true}) node!: VNode
    @Prop({default: 0}) layer!: number
    @Prop({default: 30}) indentationPx!: number
    @Prop({default: 0}) layersToIgnore!: number
    @Prop({default: 0}) layersToUnfold!: number
    @Prop({default: 0}) cellPaddingPx!: number
    @Prop({required: false, default: null}) closeEventBus!: SingularEventBus
    @Prop({default: false}) disableCloseAll!: boolean
    @Prop({default: false}) disableOpenAll!: boolean
    @Prop({default: false}) unfoldAtStart!:boolean

    unfold: boolean = false
    ignore: boolean = false

    created(){
        this.unfold = this.layersToUnfold > 0
        this.ignore = this.layersToIgnore > 0
        if(this.closeEventBus) this.closeEventBus.subscribe(this)
    }

    isSubCategory(categoryNode: VNode) : boolean{
      if(categoryNode.label.includes("_")) return true
      else return false
    }

    mounted(){
      // check if unfolding at start is true then let the entire content to unfold
      // else check the condition of unfolding
        if(this.unfoldAtStart) {
          if(this.node.children.length > 0) this.cascadeOpen()
        }
    }

    handleEvent() {
        this.unfold = false
    }

    toggleLayer(){
        this.unfold = !this.unfold
    }


    idForNode(node: VNode): string {
        return `${String(node.label)}-${String(this.layer)}`
    }

    async cascadeOpen() {
        this.unfold = true
        for (const c of this.node.children) {
            if("children" in c){
                //@ts-ignore
                await this.$refs[this.idForNode(c)][0].cascadeOpen()
            }
        }
    }

    cascadeClose() {
        this.unfold = false
        for (const c of this.node.children) {
            if("children" in c){
                //@ts-ignore
                this.$refs[this.idForNode(c)][0].cascadeClose()
            }
        }
    }

    update() {
        this.$forceUpdate()
        for (const c of this.$children) {
            if(c instanceof Nested) c.update()
        }
    }

}
