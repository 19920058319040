







import {Component, Prop, Vue} from "vue-property-decorator";
import NormalDistributionPreview from "@/components/userInput/fields/previews/NormalDistributionPreview.vue"
import FlagPreview from "@/components/userInput/fields/previews/FlagPreview.vue";
import {StringOrNonDetFieldT} from "@/model/userInput/fields/common/string_or_non_det";
import StringFieldPreview from "@/components/userInput/fields/previews/StringFieldPreview.vue";

@Component({
    components: {StringFieldPreview, FlagPreview, NormalDistributionPreview}
})
export default class StringOrFlagPreview extends Vue {
    @Prop({required: true}) field!: StringOrNonDetFieldT
}
