
































import {Component, Inject, Vue} from 'vue-property-decorator'
import {AxiosCalls} from "@/services/axiosCalls";
import {ProteinQueries} from "@/services/proteinQueries";
import {BIconArrowUp, BIconCheck2, BIconGear, BIconSearch} from "bootstrap-vue";
import {ProteinSearchHandler} from "@/utilities/proteinSearch";
import {ServiceType} from "@/services/types";
import {Language} from "@/services/language";
import ProteinSearchMenu from "@/components/proteins/ProteinSearchMenu.vue";
import ProteinList from "@/components/proteins/ProteinList.vue";
import {Sorting} from "@/utilities/search/sorting";
import {Form} from "@/model/userInput/form/form";
import {FormGetter} from "@/services/formGetter";
import {sopSearchHandler} from "@/utilities/sopSearch";
import {SOPServices} from "@/services/sop";
import SOPViewMarkdown from "@/components/sop/SOPViewMarkdown.vue"
import {envpSearchHandler} from "@/utilities/envpSearch";
import {ENVPServices} from "@/services/envp";
import ENVPViewMarkdown from "@/components/envp/ENVPViewMarkdown.vue"

@Component({
    components: {
      ProteinSearchMenu,
      BIconSearch,
      BIconGear,
      BIconCheck2,
      BIconArrowUp,
      ProteinList,
      SOPViewMarkdown,
      ENVPViewMarkdown
    }
})
export default class ProteinSearch extends Vue {

    @Inject(ServiceType.AxiosCalls) axiosCalls!: AxiosCalls
    @Inject(ServiceType.ProteinQueries) pq!: ProteinQueries
    @Inject(ServiceType.Language) language!: Language
    @Inject(ServiceType.FormGetter) formGetter!: FormGetter
    @Inject(ServiceType.SOP) sopServices!: SOPServices
    @Inject(ServiceType.ENVP) envpServices!: ENVPServices

    searchHandler: ProteinSearchHandler | null = null
    sopSearchHandler: sopSearchHandler | null = null
    envpSearchHandler: envpSearchHandler | null = null

    readonly initialBatchSize: number = 10
    readonly paginationSize: number = 7
    readonly maxProteinsCached: number = 1000

    sopFileNameToSearch: string = ""
    envpFileNameToSearch: string = ""

    handleSearch(searchForm: Form) {
        if(this.searchHandler === null) {
          this.searchHandler = new ProteinSearchHandler(
              this.initialBatchSize, this.paginationSize, this.maxProteinsCached, searchForm, this.pq, this.language)
        }
        else {
          this.searchHandler.setSearchForm(searchForm)
        }
        this.searchHandler.firstBatch()
    }

    handleSearchSOP(){
      // Load all the SOP Files data in start because we have to compare the Desired sopFileName
     // with all the available files in sopSearchHandler
        if(this.sopSearchHandler === null) {
            this.sopSearchHandler = new sopSearchHandler(
                1000,
                10,
                1000,
                "",
                this.sopServices,
                this.language
            )
        }else {
            this.sopSearchHandler.setSearchString("")
        }
        this.sopSearchHandler.firstBatch()
    }

    handleSearchENVP(){
      // Load all the ENVP Files data in start because we have to compare the Desired envpFileName
     // with all the available files in envpSearchHandler
        if(this.envpSearchHandler === null) {
            this.envpSearchHandler = new envpSearchHandler(
                1000,
                10,
                1000,
                "",
                this.envpServices,
                this.language
            )
        }else {
            this.envpSearchHandler.setSearchString("")
        }
        this.envpSearchHandler.firstBatch()
    }

    async handleSort(sorting: Sorting) {
        this.searchHandler?.setSorting(sorting)
        await this.searchHandler?.firstBatch()
    }

    updatePreview(){
        this.formGetter.invalidateCachedProteinViewColumnForm()
        if(this.searchHandler === null) return
        this.searchHandler.updateColumns()
    }
    showSOP(sopFileName: string){
      this.handleSearchSOP()
      this.sopFileNameToSearch = sopFileName
    }
    showENVP(envpFileName: string){
      this.handleSearchENVP()
      this.envpFileNameToSearch = envpFileName
    }
    backToProteinsView(){
        this.sopSearchHandler = null
    }
}
