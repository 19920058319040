











import {Component, Prop, Vue} from "vue-property-decorator";
import BlobFullscreen from "@/components/userInput/util/BlobFullscreen.vue";
import {BlobField} from "@/model/userInput/fields/common/blob_or_non_det";

@Component({
    components: {
        BlobFullscreen
    }
})
export default class BlobFieldPreview extends Vue {
    shouldShowFullscreen: boolean = false
    @Prop({required: true}) field!: BlobField

    get fieldDataEncoded() {
        return "data:image/*;base64," + this.field.value
    }

    showFullscreen() {
        this.shouldShowFullscreen = true
    }
    dismissFullscreen() {
        this.shouldShowFullscreen = false
    }
}
