import {Language} from "@/services/language";
import {Deserializable} from "@/model/serializable";


export type LangId = string


export class LangInfo extends Deserializable<string>{

    readonly id: LangId
    text?: string
    private language?: Language

    constructor(raw: string, language: Language) {
        super(raw)
        this.id = raw
        this.language = language
    }

    protected async _deserialize(raw: string){
        this.text = await this.language!.parse(raw)
        delete this.language
    }

    async parse(language: Language){
        this.text = await language.parse(this.id)
    }
}