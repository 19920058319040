import {AxiosCalls} from "@/services/axiosCalls";
import {HTTPRequest, Method, Url, urlFor} from "@/utilities/http";
import config from "@/config/config.json"
import {Language} from "@/services/language";
import {Form, RawForm} from "@/model/userInput/form/form";
import {deserialize} from "@/model/serializable";
import {ProteinCombinationTargetForm} from "@/model/protein/proteinCombinationTargetForm";
import {ProteinCombinationNumberOfProteinsForm} from "@/model/protein/proteinCombinationNumberOfProteinsForm";


export interface FormGetter {
    invalidateCachedProteinViewColumnForm(): void
    invalidateCachedChartObservationPreviewForm(): void
    invalidateCachedChartProteinsPreviewForm(): void
    getSaveSearchForm(): Promise<Form|null>
    getProteinSearchForm(): Promise<Form|null>
    getChartObservationsSearchForm(): Promise<Form|null>
    getProteinPreviewForm(): Promise<Form|null>
    getChartObservationsPreviewForm(): Promise<Form|null>
    getChartProteinsPreviewForm(): Promise<Form|null>
    getUserRegistrationForm(): Promise<Form|null>
    getUserSettingsForm(): Promise<Form|null>
    getUserSettingsLanguageForm(): Promise<Form|null>
    getProteinCombinationTargetsForm(): Promise<ProteinCombinationTargetForm|null>
    getProteinCombinationNumberOfProteinsForm(): Promise<ProteinCombinationNumberOfProteinsForm|null>
}


export class FormGetterImp implements FormGetter{

    private saveSearchForm: RawForm | null = null
    private proteinSearchForm: RawForm | null = null
    private chartObservationsSearchForm: RawForm | null = null
    private proteinPreviewForm: RawForm | null = null
    private chartObservationsPreviewForm: RawForm | null = null
    private chartProteinsPreviewForm: RawForm | null = null
    private userRegistrationForm: RawForm | null = null
    private userSettingsFrom: RawForm | null = null
    private userSettingsFromLanguage: RawForm | null = null
    private proteinCombinationTargetForm: RawForm | null = null
    private proteinCombinationNumberOfProteinsForm: RawForm | null = null

    private readonly lang: Language
    private readonly as: AxiosCalls

    constructor(
        language: Language,
        axiosCalls: AxiosCalls
    ) {
        this.lang = language
        this.as = axiosCalls
    }

    invalidateCachedProteinViewColumnForm(): void {
        this.proteinPreviewForm = null
    }

    invalidateCachedChartObservationPreviewForm(): void {
        this.chartObservationsPreviewForm = null
    }

    invalidateCachedChartProteinsPreviewForm(): void {
        this.chartProteinsPreviewForm = null
    }

    protected async getForm(url: Url): Promise<RawForm|null> {
        const req = new HTTPRequest(Method.GET, url, {}, [])
        try{
            const res = await this.as.request(req)
            return res.data["form"]
        } catch {
            return null
        }
    }

    async getProteinCombinationNumberOfProteinsForm(): Promise<ProteinCombinationNumberOfProteinsForm|null> {
        if(this.proteinCombinationNumberOfProteinsForm !== null) return await deserialize(new ProteinCombinationNumberOfProteinsForm(this.proteinCombinationNumberOfProteinsForm, this.lang))
        const url: Url = urlFor(config.REST_API.ROUTES.GET_FORMS.PROTEIN_COMBINATION_NUMBER_OF_PROTEINS_FORM)
        const rawForm = await this.getForm(url)
        if(rawForm === null) return null
        this.proteinCombinationNumberOfProteinsForm = rawForm
        return await deserialize(new ProteinCombinationNumberOfProteinsForm(rawForm, this.lang))
    }

    async getProteinCombinationTargetsForm(): Promise<ProteinCombinationTargetForm|null> {
        if(this.proteinCombinationTargetForm !== null) return await deserialize(new ProteinCombinationTargetForm(this.proteinCombinationTargetForm, this.lang))
        const url: Url = urlFor(config.REST_API.ROUTES.GET_FORMS.PROTEIN_COMBINATION_TARGETS_FORM)
        const rawForm = await this.getForm(url)
        if(rawForm === null) return null
        this.proteinCombinationTargetForm = rawForm
        return await deserialize(new ProteinCombinationTargetForm(rawForm, this.lang))
    }

    async getSaveSearchForm(): Promise<Form|null> {
        if(this.saveSearchForm !== null) return await deserialize(new Form(this.saveSearchForm, this.lang))
        const url: Url = urlFor(config.REST_API.ROUTES.GET_FORMS.SAVE_PROTEIN_SEARCH)
        const rawForm = await this.getForm(url)
        if(rawForm === null) return null
        this.saveSearchForm = rawForm
        return await deserialize(new Form(rawForm, this.lang))
    }

    async getProteinSearchForm(): Promise<Form|null> {
        if(this.proteinSearchForm !== null) return await deserialize(new Form(this.proteinSearchForm, this.lang))
        const url: Url = urlFor(config.REST_API.ROUTES.GET_FORMS.PROTEIN_SEARCH)
        const rawForm = await this.getForm(url)
        if(rawForm === null) return null
        this.proteinSearchForm = rawForm
        return await deserialize(new Form(rawForm, this.lang))
    }

    async getChartObservationsSearchForm(): Promise<Form|null> {
        if(this.chartObservationsSearchForm !== null) return await deserialize(new Form(this.chartObservationsSearchForm, this.lang))
        const url: Url = urlFor(config.REST_API.ROUTES.GET_FORMS.CHART_OBSERVATIONS_SEARCH)
        const rawForm = await this.getForm(url)
        if(rawForm === null) return null
        this.chartObservationsSearchForm = rawForm
        return await deserialize(new Form(rawForm, this.lang))
    }

    async getProteinPreviewForm(): Promise<Form|null> {
        if(this.proteinPreviewForm !== null) return await deserialize(new Form(this.proteinPreviewForm, this.lang))
        const url: Url = urlFor(config.REST_API.ROUTES.GET_FORMS.PROTEIN_PREVIEW)
        const rawForm = await this.getForm(url)
        if(rawForm === null) return null
        this.proteinPreviewForm = rawForm
        return await deserialize(new Form(rawForm, this.lang))
    }

    async getChartObservationsPreviewForm(): Promise<Form|null> {
        if(this.chartObservationsPreviewForm !== null) return await deserialize(new Form(this.chartObservationsPreviewForm, this.lang))
        const url: Url = urlFor(config.REST_API.ROUTES.GET_FORMS.CHART_OBSERVATIONS_PREVIEW)
        const rawForm = await this.getForm(url)
        if(rawForm === null) return null
        this.chartObservationsPreviewForm = rawForm
        return await deserialize(new Form(rawForm, this.lang))
    }

    async getChartProteinsPreviewForm(): Promise<Form|null> {
        if(this.chartProteinsPreviewForm !== null) return await deserialize(new Form(this.chartProteinsPreviewForm, this.lang))
        const url: Url = urlFor(config.REST_API.ROUTES.GET_FORMS.CHART_PROTEINS_FILTER_PREVIEW)
        const rawForm = await this.getForm(url)
        if(rawForm === null) return null
        this.chartProteinsPreviewForm = rawForm
        return await deserialize(new Form(rawForm, this.lang))
    }

    async getUserRegistrationForm(): Promise<Form|null> {
        if(this.userRegistrationForm !== null) return await deserialize(new Form(this.userRegistrationForm, this.lang))
        const url: Url = urlFor(config.REST_API.ROUTES.GET_FORMS.USER_REGISTRATION)
        const rawForm = await this.getForm(url)
        if(rawForm === null) return null
        this.userRegistrationForm = rawForm
        return await deserialize(new Form(rawForm, this.lang))
    }
    async getUserSettingsForm(): Promise<Form|null> {
        if(this.userSettingsFrom !== null) return await deserialize(new Form(this.userSettingsFrom, this.lang))
        const url: Url = urlFor(config.REST_API.ROUTES.GET_FORMS.USER_SETTINGS)
        const rawForm = await this.getForm(url)
        if(rawForm === null) return null
        this.userSettingsFrom = rawForm
        return await deserialize(new Form(rawForm, this.lang))
    }
    async getUserSettingsLanguageForm(): Promise<Form|null> {
        if(this.userSettingsFromLanguage !== null) return await deserialize(new Form(this.userSettingsFromLanguage, this.lang))
        const url: Url = urlFor(config.REST_API.ROUTES.GET_FORMS.USER_SETTINGS_LANGUAGE)
        const rawForm = await this.getForm(url)
        if(rawForm === null) return null
        this.userSettingsFromLanguage = rawForm
        return await deserialize(new Form(rawForm, this.lang))
    }
}