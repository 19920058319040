import {rawField} from "@/model/userInput/fields/util/raw";
import {Field} from "@/model/userInput/fields/util/fieldBase";
import {Language} from "@/services/language";
import {isNU} from "@/utilities/isNU";
import {LangInfo} from "@/model/LangInfo";

export type rawButtonField = rawField & {
    value?: boolean
    onLabel?: string
    offLabel?: string
}

export class ButtonField extends Field<rawButtonField> {

    private originalVal: boolean = false
    is_on: boolean = false
    on_label?: LangInfo
    off_label?: LangInfo

    get changed(): boolean {
        return this.is_on !== this.originalVal
    }

    commitChange() {
        this.originalVal = this.is_on
    }

    get offLabelStr(): string {
        if(isNU(this.off_label)) return ""
        else return this.off_label!.text!
    }

    get onLabelStr(): string {
        if(isNU(this.on_label)) return ""
        else return this.on_label!.text!
    }

    isEmpty(): boolean {
        return isNU(this.is_on);
    }

    makeEmpty() {
        this.is_on = false
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async deserializeField(data: rawButtonField, language: Language): Promise<void> {
        const isOn = isNU(data.value)? false : data.value!
        this.originalVal = isOn
        this.is_on = isOn
        if(isNU(data.offLabel)) throw Error("offLabel in button field not present: " + data)
        if(isNU(data.onLabel)) throw Error("onLabel in button field not present: " + data)
        const offLabel = new LangInfo(data.offLabel!, language)
        await offLabel.deserialize()
        const onLabel = new LangInfo(data.onLabel!, language)
        await onLabel.deserialize()
        this.off_label = offLabel
        this.on_label = onLabel
    }

    serialize(): rawButtonField {
        if (!this.valid())
            throw Error("cannot serialize blob field")
        return <rawButtonField>{
            value: this.is_on,
        }
    }

    valid(): boolean {
        return !isNU(this.is_on)
    }

    toggleIfMutable(): void {
        if(this.mutable) this.is_on = !this.is_on
    }
}

