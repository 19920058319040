




import {Component, Prop, Vue} from "vue-property-decorator";
import {FlagOptionField} from "@/model/userInput/fields/common/flagMixin";

@Component
export default class NonDeterminablePreview extends Vue {
    @Prop({required: true}) field!: FlagOptionField
}
