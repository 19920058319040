import {NestedFieldContainer} from "@/model/userInput/fieldContainer/nestedFieldContainer";
import {RawFieldContainer} from "@/model/userInput/fieldContainer/fieldContainer";
import {Language} from "@/services/language";
import {LangInfo} from "@/model/LangInfo";
import {Field} from "@/model/userInput/fields/util/fieldBase";


export type RawView = RawFieldContainer


export class View extends NestedFieldContainer<RawView> {
    constructor(raw: RawView, language: Language) {
        super(raw, language);
    }
    columnValue(column: LangInfo): Field<any> {
        return this.getField(column.id)
    }
}
