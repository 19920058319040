

































import {Component, Inject, Prop, Vue} from "vue-property-decorator";
import {StringField as _StringField} from "@/model/userInput/fields/common/string";
import {isNU} from "@/utilities/isNU";
import {ServiceType} from "@/services/types";
import {UserService,UserDetails} from "@/services/user";
import {Language} from "@/services/language";
import {ProteinQueries} from "@/services/proteinQueries";
import {VueAutosuggest} from "vue-autosuggest";


@Component({
    components: {VueAutosuggest}
})
export default class StringField extends Vue {

    @Inject(ServiceType.User) userS!: UserService
    @Inject(ServiceType.ProteinQueries) pq!: ProteinQueries
    @Inject(ServiceType.Language) lang!: Language

    @Prop({required: true}) fieldData!: _StringField
    @Prop({default: false}) inactive!: boolean
    @Prop({default: ""}) formName!: string
    suggestions: string[] = []

    get field() {
        return this.fieldData
    }

    changed() {
        this.$emit("changed")
    }

    shownSuggestions(){
        return [{data: this.suggestions.filter(sugg => {
                if (this.field.value)
                    return sugg.toLowerCase().indexOf(this.field.value?.toLowerCase()) > -1
                else
                    return true
            })}]
    }

    get placeholder(): string {
        if (!isNU(this.field.placeholder))
            return this.field.placeholder!
        else return ""
    }

    onAutosuggestInputChange(value: string) {
        this.field.value = value
    }

    onAutosuggestSelected(obj: any) {
        if (obj)
            this.field.value = obj.item
    }

    created() {
        if(this.formName!="")
            this.fieldData.value=UserDetails.prototype.userName

        this.pq.getSearchSuggestions(this.lang.removeLastHierarchy(this.fieldData.actualLabel!.id)).then((data => {
            this.suggestions = data
        }));
    }
}
