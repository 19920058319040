import {ViewColumn} from "@/model/userInput/view/viewList"

export class ViewPreviewManager {

    labels: ViewColumn[]
    private locked: ViewColumn[]

    maxActiveColumnsCount: number

    private left: number
    private right: number

    constructor(labels: ViewColumn[], maxActiveColumnsCount: number) {
        this.labels = labels
        this.maxActiveColumnsCount = maxActiveColumnsCount
        this.left = 0
        this.right = maxActiveColumnsCount - 1
        this.locked = []
    }

    get lockedAndActiveCols(): ViewColumn[] {
        return this.lockedCols.concat(this.activeColumns)
    }

    get lockingPossible(): boolean {
        return this.moreLabelsThanSpaces && (this.maxActiveColumnsCount > 1)
    }

    get moreLabelsThanSpaces(): boolean {
        return this.labels.length > this.maxActiveColumnsCount
    }

    get fullyLocked(): boolean {
        return this.right < this.left
    }

    lock(col: ViewColumn){
        if(!this.lockingPossible) return
        this.locked.push(col)
        this.right --
    }

    unlock(col: ViewColumn){
        this.locked = this.locked.filter(item => {
            return item !== col
        })
        this.right ++
    }

    get activeColumns(): ViewColumn[] {
        return this.unlockedCols.slice(this.left, this.right + 1)
    }

    colIsActive(col: ViewColumn): boolean {
        return this.activeColumns.includes(col)
    }

    get lockedCols(): ViewColumn[] {
        return this.locked
    }

    get unlockedCols(): ViewColumn[] {
        return this.labels.filter(label => !this.lockedCols.includes(label));
    }

    get activeColCount(): number {
        return this.right - this.left
    }

    goRightInColumns(): void {
        if (this.fullyLocked) return
        if (this.right < this.unlockedCols.length - 1) {
            this.left++
            this.right++
        }
    }

    goLeftInColumns(): void {
        if (this.fullyLocked) return
        if (this.left > 0) {
            this.left--
            this.right--
        }
    }

    jumpToUnlockedCol(col: ViewColumn) {
        if (this.fullyLocked) return
        const idx = this.unlockedCols.indexOf(col)
        if (idx > this.right) {
            this.left += idx - this.right
            this.right = idx
        } else if (idx < this.left) {
            this.right -= this.left - idx
            this.left = idx
        }
    }
}