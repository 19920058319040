import Vue from 'vue'
import App from './App.vue'
import {BootstrapVue} from "bootstrap-vue"
import VueRouter from 'vue-router'
import {router} from './router'
import i18n from './i18n'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/globals.css'
import {UserDetails} from "@/services/user";

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(BootstrapVue)

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {

  // use the language from the routing param or default language
  let language = UserDetails.prototype.currentLanguage?.toLowerCase()
  if (!language) {
    language = 'de'
  }
  // set the current language for i18n.
  i18n.locale = language
  next()
})


new Vue({
    render: h => h(App),
    router,
    i18n
}).$mount('#app')


