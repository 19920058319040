












import {Component, Prop, Vue} from "vue-property-decorator";
import RecursiveRender from "@/components/userInput/util/RecursiveRender.vue";
import {View} from "@/model/userInput/view/view";


@Component({
    components: {RecursiveRender}
})
export default class ProteinView extends Vue {

    @Prop({required: true}) view!: View
    @Prop({default: false}) showSopContent!:boolean
    @Prop({default: false}) showENVPContent!:boolean
 }
