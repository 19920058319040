import {RawView, View} from "@/model/userInput/view/view";
import {Language} from "@/services/language";
import {isNU} from "@/utilities/isNU";


export type ProteinRatio = {
    proteinName: string,
    ratio: number
}


export type RawProteinCombinationView = RawView & {proteinRatios: ProteinRatio[]}

export class ProteinCombinationView extends View {

    proteinRatios: ProteinRatio[] = []

    constructor(raw: RawProteinCombinationView, language: Language) {
        super(raw, language);
        if(isNU(raw.proteinRatios))
            throw Error(`attribute proteinRatios is ${raw.proteinRatios} but should be an object`)
        this.proteinRatios = raw.proteinRatios
    }
}
