













import {Component, Prop, Vue} from "vue-property-decorator";
import {ButtonOrNonDetFieldT} from "@/model/userInput/fields/common/button_or_non_det";
import ButtonField from "@/components/userInput/fields/ButtonField.vue";
import NonDeterminableButton from "@/components/userInput/fields/NonDeterminableButton.vue";


@Component({
  components: {NonDeterminableButton, ButtonField}
})
export default class ButtonOrFlagField extends Vue {

    @Prop({required: true}) fieldData!: ButtonOrNonDetFieldT
    @Prop({default: false}) inactive!: boolean

    changed() {
        this.$emit("changed")
    }
}
