








































import {Component, Inject, Vue} from 'vue-property-decorator'
import {BIconJustify} from 'bootstrap-vue'
import {UserDetails, UserService} from "@/services/user"
import {ServiceType} from "@/services/types"
import {isDevMode} from "@/env"


type navElement = { route: string | null, text: string, permission: number | null , languageTag:string}


@Component({
    components: {
        BIconJustify,
    },
})
export default class Navigation extends Vue {

    showLinks: boolean = false
    @Inject(ServiceType.User) protected userService!: UserService

    async logout() {
        await this.userService.logout()
    }

    navigationElements: Array<navElement> = [
        {text:  "", route: null, permission: null, languageTag:'NavigationBarLabels.Users'},
        {route: "/user", text: "", permission: 0, languageTag:'NavigationBarLabels.UsersRoutes.Show'},
        {route: "/user-settings", text: "", permission: 0, languageTag:'NavigationBarLabels.UsersRoutes.Settings'},
        {route: "/sign-up", text: "", permission: 3, languageTag:'NavigationBarLabels.UsersRoutes.Registration'},
        {route: "/search-user", text: "", permission: 3, languageTag:'NavigationBarLabels.UsersRoutes.Search'},

        {text: "", route: null, permission: null, languageTag:'NavigationBarLabels.Protein'},
        {route: "/search-proteins", text: "", permission: 1, languageTag:'NavigationBarLabels.UsersRoutes.Search'},
        //{route: "/new-protein", text: "", permission: 2, languageTag:'NavigationBarLabels.UsersRoutes.Registration'},
        {route: "/combinations", text: "", permission: 1, languageTag:'NavigationBarLabels.ProteinRoutes.Combinations'},
        {route: "/search-sop", text: "", permission: 1, languageTag:'NavigationBarLabels.ProteinRoutes.SOP'},
        {route: "/search-envp", text: "", permission: 1, languageTag:'NavigationBarLabels.ProteinRoutes.ENVP'},
        {route: "/show-charts", text: "", permission: 1, languageTag:'NavigationBarLabels.ProteinRoutes.ShowCharts'},

        {text: "", route: null, permission: 2, languageTag:'NavigationBarLabels.Others'},
        {route: "/excel-import", text: "", permission: 2, languageTag:'NavigationBarLabels.OthersRoutes.ExcelImport'},
        {route: "/media-import", text: "", permission: 2, languageTag:'NavigationBarLabels.OthersRoutes.MediaImport'},
        //{route: "bookmarks", text: "Lesezeichen", permission: 1, languageTag:'NavigationBarLabels.Users'},
        //{route: "/own-changes", text: "", permission: 2, languageTag:'NavigationBarLabels.OthersRoutes.OwnChanges'},
        {route: "/delete-proteins", text: "", permission: 2, languageTag:'NavigationBarLabels.OthersRoutes.DeleteProteins'}
    ]

    created(){
      // Set the Current Language after Signin
      this.$i18n.locale=UserDetails.prototype.currentLanguage!
      if(isDevMode()){
        this.navigationElements.push({
          route: "/test-page",
          text: "Testpage",
          permission: 0,
          languageTag:''
        })
      }
    }

    get sidebarContent(): Array<navElement> {
        return this.navigationElements.filter(e => {
            e.text=this.$t(e.languageTag).toString()
            if (e.permission != null)
                return e.permission <= this.userPermissions
            else
                return true
        })
    }

    get userPermissions(): number {
        const role = this.userService.userRole
        if (role !== null) return role.valueOf()
        else throw Error("user not logged in!")
    }

    toggleNavLinks(): void {
        this.showLinks = !this.showLinks
    }

    smallScreen(): boolean {
        return !!window.matchMedia("(max-width: 700px)")
    }

    goTo(routeName: string): void {
        if (this.$route.name === routeName)
            this.showLinks = false
        else
            this.$router.push(routeName)
    }

    generateKey(elem: navElement) {
        return String(elem.route) + String(elem.permission) + String(elem.text)
    }
}
