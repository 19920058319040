














import {Component, Prop, Vue} from "vue-property-decorator";
import RecursiveRender from "@/components/userInput/util/RecursiveRender.vue";
import {UserView as UserViewT} from "@/model/users/userView";
import GenericField from "@/components/userInput/fields/util/GenericField.vue";
@Component({
    components: {RecursiveRender, GenericField}
})
export default class UserView extends Vue {
    @Prop({required: true}) view!: UserViewT
}
