













import {Component, Inject, Prop, Vue} from 'vue-property-decorator'
import {ServiceType} from "@/services/types";
import {ProteinQueries} from "@/services/proteinQueries";
import {AxiosCalls} from "@/services/axiosCalls";
import TooltipButton from "@/components/util/TooltipButton.vue";
import {Language} from "@/services/language";
import {FormGetter} from "@/services/formGetter";
import {Form} from "@/model/userInput/form/form";
import StoreProteinSearchForm from "@/components/userInput/StoreProteinSearchForm.vue";

@Component({
  components: {StoreProteinSearchForm, TooltipButton}
})
export default class StoreSearchQuery extends Vue {

    @Prop({required: true, default: null}) searchForm!: Form | null
    saveSearchForm: Form | null = null
    @Inject(ServiceType.ProteinQueries) pq!: ProteinQueries
    @Inject(ServiceType.AxiosCalls) as!: AxiosCalls
    @Inject(ServiceType.Language) lang!: Language
    @Inject(ServiceType.FormGetter) formGetter!: FormGetter

    async created(){
        this.saveSearchForm = await this.formGetter.getSaveSearchForm()
    }

    async saveSearch(){
        if(this.saveSearchForm === null || this.searchForm === null) return
        if(!this.searchForm.valid || !this.saveSearchForm.valid) return
        const res = await this.pq.storeSearchForm(this.searchForm, this.saveSearchForm)
        if (res) this.$emit("searchSaved")
    }

}
