import {rawField} from "@/model/userInput/fields/util/raw";
import {Field} from "@/model/userInput/fields/util/fieldBase";
import {Language} from "@/services/language";
import {isNU, isNUE} from "@/utilities/isNU";
import {UserDetails} from "@/services/user";


export type rawStringField = rawField & {
    value?: string
    pattern?: string
    placeholder?: string,
    // This is Only to show database translated data. So no need to Consider it as input change at somewhere
    value_en?: string
}

export class StringField extends Field<rawStringField> {

    private originalVal?: string
    value?: string
    pattern?: RegExp
    placeholder?: string

    get changed(): boolean {
        if(isNUE(this.originalVal)) return !isNUE(this.value)
        else return this.value !== this.originalVal
    }

    commitChange() {
        this.originalVal = this.value
    }

    makeEmpty() {
        this.value = undefined
    }

    isEmpty(): boolean {
        return isNUE(this.value)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async deserializeField(data: rawStringField, language: Language): Promise<void> {
        this.originalVal = data.value
        this.value = data.value
        // if the language preference is english then overwrite the "value" with "value_en" but if we
        // have any other language option in future then add another elseif condition with that language value
        if(data.value_en && !isNUE(data.value_en) && UserDetails.prototype.currentLanguage == "en"){
            this.value = data.value_en
        }
        if(data.pattern) this.pattern = new RegExp(data.pattern)
        this.placeholder = data.placeholder
    }

    serialize(): rawStringField {
        if (!this.valid())
            throw Error("cannot serialize string field")
        return <rawStringField>{
            value: this.value,
        }
    }

    valid(): boolean {
        if(isNU(this.value)) return false
        if(isNU(this.pattern)) return true
        return this.pattern!.test(this.value!)
    }
}
