






import {Component, Prop, Vue} from "vue-property-decorator";
import {StringField} from "@/model/userInput/fields/common/string";

@Component
export default class StringFieldPreview extends Vue {
    @Prop({required: true}) field!: StringField
}
