
























import {Component, Prop, Vue} from "vue-property-decorator";
import RecursiveRender from "@/components/userInput/util/RecursiveRender.vue";
import {ProteinCombinationTargetForm as _ProteinCombinationTargetForm} from "@/model/protein/proteinCombinationTargetForm";
import CollapseToggleIcon from "@/components/util/CollapseToggleIcon.vue"
import ProteinTargetFormFieldSummary from "@/components/userInput/util/ProteinTargetFormFieldSummary.vue";


@Component({
    components: {ProteinTargetFormFieldSummary, RecursiveRender, CollapseToggleIcon}
})
export default class ProteinCombinationTargetForm extends Vue {
  @Prop({required: true}) form!: _ProteinCombinationTargetForm
}
