import {RawViewList, ViewList} from "@/model/userInput/view/viewList";
import {ProteinView, RawProteinView} from "@/model/protein/proteinView";
import {Language} from "@/services/language";


export type RawProteinViewList = RawViewList<RawProteinView>


export class ProteinViewList extends ViewList<RawProteinView, ProteinView> {

    protected async deserializeView(raw: RawProteinView, language: Language): Promise<ProteinView> {
        const view = new ProteinView(raw, language)
        await view.deserialize()
        return view
    }
}