







































































import {Component, Inject, Vue} from 'vue-property-decorator'
import {AxiosCalls} from "@/services/axiosCalls";
import {ProteinQueries} from "@/services/proteinQueries";
import StoredSearchQueriesLoader from "@/components/proteins/StoredSearchQueriesLoader.vue"
import {BIconArrowDown, BIconArrowUp, BIconCheck2, BIconGear, BIconSearch, BIconFolder2, BIconCloudUpload} from "bootstrap-vue";
import {ServiceType} from "@/services/types";
import {Language} from "@/services/language";
import {SingularEventBus} from "@/utilities/eventBus";
import StoreSearchQuery from "@/components/proteins/StoreSearchQuery.vue";
import TooltipButton from "@/components/util/TooltipButton.vue";
import {FormGetter} from "@/services/formGetter";
import ButtonWrapper from "@/components/util/ButtonWrapper.vue";
import {Form} from "@/model/userInput/form/form";
import ProteinViewColumnConfigurationForm from "@/components/userInput/ProteinViewColumnConfigurationForm.vue";
import MainProteinSearchForm from "@/components/userInput/MainProteinSearchForm.vue";
import {isNU} from "@/utilities/isNU";
import {BIconGraphDown} from "bootstrap-vue/src/icons/icons";
import {SubsetSelectField, SubsetSelectNode} from "@/model/userInput/fields/common/subset_select";
import {ProteinSearchHandler} from "@/utilities/proteinSearch";
import {ProteinView} from "@/model/protein/proteinView";
import {View} from "@/model/userInput/view/view";

@Component({
    components: {
      ProteinSearchForm: MainProteinSearchForm,
        BIconSearch, BIconGear, BIconCheck2, ProteinViewColumnConfigurationForm,
        BIconArrowDown, BIconArrowUp, BIconFolder2, BIconCloudUpload,
        StoredSearchQueriesLoader, StoreSearchQuery, TooltipButton, ButtonWrapper,BIconGraphDown
    }
})
export default class ProteinSearchMenu extends Vue {

    @Inject(ServiceType.AxiosCalls) axiosCalls!: AxiosCalls
    @Inject(ServiceType.ProteinQueries) pq!: ProteinQueries
    @Inject(ServiceType.Language) language!: Language
    @Inject(ServiceType.FormGetter) formGetter!: FormGetter

    searchForm: Form | null = null
    proteinPreviewForm: Form | null = null
    chartObservationsPreviewForm: Form | null = null
    chartProteinsFilterPreviewForm: Form | null = null
    proteinSearchHandler: ProteinSearchHandler | null = null

    previewModalOpen: boolean = false
    storedSearchQueriesModalOpen: boolean = false
    storeSearchQueryModalOpen: boolean = false
    loadingDataStarted: boolean = false

    closeFormEventBus: SingularEventBus = new SingularEventBus()

    replaceForm(form: Form){
        this.searchForm = form
        this.closeStoredSearchQueriesModal()
    }

    get formValid(): boolean {
        if (isNU(this.searchForm)) return false
        return this.searchForm!.valid
    }

    proteinPreviewChanged(){
        this.$emit("proteinViewColumnsChanged")
    }

    submitSearchForm() {
        if (!this.searchForm) return
        if (!this.formValid) return
        this.collapseSearchForm()
        this.$emit("submitSearchForm", this.searchForm)
    }

    collapseSearchForm(){
        this.closeFormEventBus.publish()
    }

    showPreviewWindow() {
        this.previewModalOpen = true
    }

    showStoredSearchQueriesModal(){
        this.storedSearchQueriesModalOpen = true
    }

    closeStoredSearchQueriesModal(){
        this.storedSearchQueriesModalOpen = false
    }

    showStoreSearchQueryModal(){
        this.storeSearchQueryModalOpen = true
    }

    async showCharts(){
      this.loadingDataStarted = true
      await this.copyCurrentColumnsToChartPreview()
      this.proteinSearchHandler = null
      await this.handleProteinSearch()
      await this.getProteinFormAndSetProteinList()
      this.$router.push("/show-charts")
    }

    async getProteinFormAndSetProteinList(){
       this.chartProteinsFilterPreviewForm = await this.formGetter.getChartProteinsPreviewForm()
       if(this.chartProteinsFilterPreviewForm) {
         const proteinNamesList = this.addProteinsNamesInList(this.proteinSearchHandler!)
         this.chartProteinsFilterPreviewForm = await this.addSelectedProteinsListIntoForm(this.chartProteinsFilterPreviewForm, proteinNamesList)
         await this.pq.setChartsCategoriesFilterPreview(this.chartProteinsFilterPreviewForm)
       }
    }

    async addSelectedProteinsListIntoForm(form: Form, proteinNamesList: string[]): Promise<Form>{
      let asNestedField: SubsetSelectField|null = Object.values(form.fields)[0] as SubsetSelectField
      asNestedField.selectedProteinsList = proteinNamesList
      return form
    }

    addProteinsNamesInList(handler: ProteinSearchHandler): string[]{
      let selectedProteins: string[] = []
      handler.currentBatch?.forEach((view: ProteinView) =>{
        selectedProteins.push(this.getActualProteinNameLabel(view))
      })
      return selectedProteins
    }

    async handleProteinSearch(){
      if(this.searchForm){
        if(this.proteinSearchHandler === null) {
          this.proteinSearchHandler = new ProteinSearchHandler(
              1000, 10, 1000, this.searchForm, this.pq, this.language)
        }
        else {
          this.proteinSearchHandler.setSearchForm(this.searchForm)
        }
        await this.proteinSearchHandler.firstBatch()
      }
    }

    getActualProteinNameLabel(view: View): string{
      return view.label.split("  ")[1]
   }

    async copyCurrentColumnsToChartPreview(){
      this.chartObservationsPreviewForm = await this.formGetter.getChartObservationsPreviewForm()
      const currentColumnsListOfProteinForm = this.getCurrentColumnsListOfForm(this.proteinPreviewForm!)
       // get only those column category types which are allowed in chart Observation preview form
      const numericColumns = this.getOnlyNumericDataColumns(currentColumnsListOfProteinForm)
      this.assignColumnsToChartObservationPreviewForm(numericColumns)
      await this.pq.setProteinChartsPreview(this.chartObservationsPreviewForm!)
    }

    assignColumnsToChartObservationPreviewForm(columnsList: string[]){
      if(this.chartObservationsPreviewForm){
        let field: SubsetSelectField = Object.values(this.chartObservationsPreviewForm.fields)[0] as SubsetSelectField
        field.value = columnsList
      }
    }

    getCurrentColumnsListOfForm(form: Form): string[]{
      const field: SubsetSelectField = Object.values(form?.fields)[0] as SubsetSelectField
      return field.value
    }

    getOnlyNumericDataColumns(columnsList: string[]): string[]{
      let filteredNumericColumnsList: string[] = []
      let allowedColumnCategoryTypes: string[] = []
      if(this.chartObservationsPreviewForm){
        const field: SubsetSelectField = Object.values(this.chartObservationsPreviewForm.fields)[0] as SubsetSelectField
        const asNestedStructure: SubsetSelectNode = field.asNestedStructure as SubsetSelectNode
        asNestedStructure.children.forEach((value) =>{
          const node: SubsetSelectNode = value as SubsetSelectNode
          allowedColumnCategoryTypes.push(node.label)
        })
        columnsList.forEach((value) =>{
          const columnCategory: string = this.getTheFirstLabelOfColumn(value)
          if(allowedColumnCategoryTypes.includes(columnCategory)){
            filteredNumericColumnsList.push(value)
          }
        })
      }
      return filteredNumericColumnsList
    }

    getTheFirstLabelOfColumn(value: string){
      return this.removeSpaceFromEndOfName(value.split("/")[0])
    }

    removeSpaceFromEndOfName(value: string): string{
      return value.trimEnd()
    }

    closeStoreSearchQueryModal(){
        this.storeSearchQueryModalOpen = false
    }

    async submitPreviewForm() {
        if (this.proteinPreviewForm === null) return
        const form = this.proteinPreviewForm
        const success = await this.pq.setProteinPreview(form)
        this.previewModalOpen = false
        if(success) this.proteinPreviewChanged()
    }

    async created() {
        this.searchForm = await this.formGetter.getProteinSearchForm()
        this.proteinPreviewForm = await this.formGetter.getProteinPreviewForm()
    }

    scrollTop(): void {
        window.scrollTo(0, 0);
    }
}
