



























import {Component, Inject, Prop, Vue} from 'vue-property-decorator'
import ViewList from "@/components/userInput/util/ViewList.vue";
import {ProteinSearchHandler} from "@/utilities/proteinSearch";
import {ProteinView} from "@/model/protein/proteinView";
import ProteinEdit from "@/components/proteins/ProteinEdit.vue";
import {BIconArrowLeft, BIconArrowLeftShort, BIconArrowRight} from "bootstrap-vue";
import Pagination from "@/components/util/Pagination.vue";
import {UserService} from "@/services/user";
import {ServiceType} from "@/services/types";
import {Role} from "@/utilities/roles";
import {Sorting} from "@/utilities/search/sorting";
import ProteinViewVue from "@/components/userInput/ProteinView.vue"
import PaginatedViewList from "@/components/userInput/util/PaginatedViewList.vue";


@Component({
    components: {
      PaginatedViewList,
        Pagination,
        ViewList,
        ProteinEdit,
        BIconArrowLeftShort,
        BIconArrowLeft,
        BIconArrowRight,
        ProteinViewVue
    }
})
export default class ProteinList extends Vue {

    @Prop({required: true}) searchHandler!: ProteinSearchHandler
    @Inject(ServiceType.User) userS!: UserService

    viewToShow: ProteinView | null = null
    viewToEdit: string | null = null  // only the identifier

    proteinsPerPage: number|null = null
    proteinsPerPageOptions = [10, 15, 25, 50]

    created(){
        const size = this.searchHandler.batchSize
        this.proteinsPerPage = size
        if(this.proteinsPerPageOptions.includes(size)) return
        this.proteinsPerPageOptions.push(size)
        this.proteinsPerPageOptions.sort()
    }

    sortBy(sorting: Sorting){
        this.$emit("sortBy", sorting)
    }

    get sufficientPermissions(){
        return this.userS.satisfiesRole(Role.FullAccess)
    }

    updateProteinsPerPage(){
        if(this.proteinsPerPage !== null){
            const proteinsPerPage = this.proteinsPerPage
            this.searchHandler!.updateSize(proteinsPerPage)
            this.$emit("proteinsPerPage", proteinsPerPage)
        }
    }

    showView(proteinView: ProteinView) {
        this.viewToShow = proteinView
    }

    backToList(){
        this.viewToShow = null
        this.viewToEdit = null
    }

    editProtein(){
        if(this.viewToShow === null) throw Error("cant edit view, since no view is selected")
        this.viewToEdit = this.viewToShow.proteinName
    }

    cancelEdit(){
        this.viewToEdit = null
    }
    goto(pageNum: number){
        this.searchHandler!.goto(pageNum-1)
    }
    showSOP(sopFileName: string){
      this.$emit("showSOP", sopFileName)
    }
}
