import {UserView} from "@/model/users/userView";
import {searchRequestMetadata} from "@/utilities/search/helpers";
import {SearchRespMetadata} from "@/utilities/searchResponse";
import {UserService} from "@/services/user";
import {Language} from "@/services/language";
import {ViewSearchHandler} from "@/utilities/search/viewSearchHandler";


export class UserSearchHandler extends ViewSearchHandler<UserView> {

    private userS: UserService
    private searchString: string
    private language: Language

    constructor(
        size: number,
        paginationSize: number,
        maxElementsInCache: number,
        searchString: string,
        userS: UserService,
        language: Language,
    ) {
        super(size, paginationSize, maxElementsInCache)
        this.userS = userS
        this.searchString = searchString
        this.language = language
    }

    setSearchString(searchString: string){
        this.searchString = searchString
    }

    protected async loadBatch(srm: searchRequestMetadata):
        Promise<{ data: UserView[]; metadata: SearchRespMetadata } | null> {

        const searchResult = await this.userS.searchUsers(this.searchString, srm)

        if(searchResult === null) {
            this.emptySearchResult = true
            return null
        }

        const viewList = searchResult[0]
        const metadata = searchResult[1]

        this.columns = viewList.columns
        this.emptySearchResult = metadata.numPotentialResults === 0
        return {data: viewList.views, metadata: metadata}
    }
}