















import {Component, Vue} from "vue-property-decorator";
import FindCombinationsStepper from "@/components/proteins/FindCombinationsStepper.vue";
import {BIconArrowsFullscreen} from "bootstrap-vue";
import ProteinCombinationsSearch from "@/components/proteins/ProteinCombinationsSearch.vue";
import {
  ProteinCombinationTargetForm as _ProteinCombinationTargetForm
} from "@/model/protein/proteinCombinationTargetForm";
import {ProteinCombinationNumberOfProteinsForm} from "@/model/protein/proteinCombinationNumberOfProteinsForm";
import {Form} from "@/model/userInput/form/form";


@Component({
  components: {ProteinCombinationsSearch, FindCombinationsStepper, BIconArrowsFullscreen}
})
export default class FindCombinationsPage extends Vue{

  searching: boolean = false

  combinationTargetsForm?: _ProteinCombinationTargetForm
  numberOfProteinsInCombinationForm?: ProteinCombinationNumberOfProteinsForm
  limitProteinsForm?: Form
  proteinIds?: string[]

  handleSubmit(payload: {
    combinationTargetsForm: _ProteinCombinationTargetForm,
    numberOfProteinsInCombinationForm: ProteinCombinationNumberOfProteinsForm,
    limitProteinsForm: Form,
    proteinIds: string[]}){

    this.combinationTargetsForm = payload.combinationTargetsForm
    this.numberOfProteinsInCombinationForm = payload.numberOfProteinsInCombinationForm
    this.limitProteinsForm = payload.limitProteinsForm
    this.proteinIds = payload.proteinIds

    this.searching = true
  }

  reset(){
    this.searching = false
    this.combinationTargetsForm = undefined
    this.numberOfProteinsInCombinationForm = undefined
    this.limitProteinsForm = undefined
    this.proteinIds = undefined
  }
}
