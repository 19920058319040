
export enum Role{
    NoAccess,
    ReadOnly,
    FullAccess,
    Admin,
}

export const roles: Role[] = Object.values(Role).filter(p => typeof p == "number") as Role[]

const roleToDesc: Record<Role, string> = {
    [Role.NoAccess] : "Kein Zugriff",
    [Role.ReadOnly] : "Proteine suchen",
    [Role.FullAccess] : "Proteine suchen und verändern",
    [Role.Admin] : "Administration"
}

export function roleDescription(role: Role){
    return roleToDesc[role]
}
