












import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class ProteinCombinationProgress extends Vue{
  @Prop({required: true}) triedCombinations!: number
  @Prop({required: true}) totalCombinations!: number

  get ratio(): number {
    return (this.triedCombinations / this.totalCombinations) * 100
  }
}
