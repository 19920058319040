var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({transform: ("translate(" + _vm.indent + "px)")})},[(_vm.field && _vm.field.deserialized)?_c('div',[_c('div',{staticClass:"flex-h"},[(_vm.showLabel && !_vm.showSopContent)?_c('div',{staticClass:"generic-field-label",domProps:{"innerHTML":_vm._s(_vm.field.label)}}):_vm._e(),_c('div',[_c('div',{class:{'red-outline': _vm.redOutline, 'transparent': _vm.transparent}},[(_vm.field.fieldTypeId === _vm.fieldId.SubsetSelect)?_c('SubsetSelectField',{attrs:{"field-data":_vm.field,"selected-proteins":_vm.selectedProteins,"show-categories-selections":_vm.showCategoriesSelections}}):(_vm.field.fieldTypeId === _vm.fieldId.BlobOrNonDet)?_c('BlobOrFlagField',{attrs:{"field-data":_vm.field}}):(_vm.field.fieldTypeId === _vm.fieldId.String)?_c('StringField',{attrs:{"field-data":_vm.field,"form-name":_vm.formName}}):(_vm.field.fieldTypeId === _vm.fieldId.StringOrNonDet)?_c('StringOrFlagField',{attrs:{"field-data":_vm.field}}):(_vm.field.fieldTypeId === _vm.fieldId.Options)?_c('OptionsField',{attrs:{"field-data":_vm.field},on:{"changed":function (){
	var ref;

	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];
	return (ref = this$1).changeInOption.apply(ref, args);
}}}):(_vm.field.fieldTypeId === _vm.fieldId.Button)?_c('ButtonField',{attrs:{"field-data":_vm.field}}):(_vm.field.fieldTypeId === _vm.fieldId.ButtonOrNonDet)?_c('ButtonOrFlagField',{attrs:{"field-data":_vm.field}}):(_vm.field.fieldTypeId === _vm.fieldId.NormalDistOrNonDet)?_c('NormalDistributionOrFlagField',{attrs:{"field-data":_vm.field}}):(_vm.field.fieldTypeId === _vm.fieldId.Number)?_c('NumberField',{attrs:{"field-data":_vm.field}}):(_vm.field.fieldTypeId === _vm.fieldId.Password)?_c('PasswordField',{attrs:{"field-data":_vm.field},on:{"changed":function (){
	var ref;

	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];
	return (ref = this$1).changeInPasswordField.apply(ref, args);
}}}):(_vm.field.fieldTypeId === _vm.fieldId.NumberRange)?_c('NumberRangeFeld',{attrs:{"field-data":_vm.field}}):(_vm.field.fieldTypeId === _vm.fieldId.LangInfo)?_c('LangInfoField',{attrs:{"field-data":_vm.field}}):(_vm.field.fieldTypeId === _vm.fieldId.MarkdownContent)?_c('MarkdownField',{attrs:{"field-data":_vm.field}}):_c('div',[_vm._v(" "+_vm._s(("cannot render column of type " + (_vm.field.fieldTypeId)))+" ")])],1),(_vm.field.mutable && _vm.getSearchHelp())?_c('div',{staticClass:"search-help"},[_vm._v(_vm._s(_vm.getSearchHelp()))]):_vm._e(),(_vm.mustBeFilledOut)?_c('div',{staticClass:"validation-feedback"},[_vm._v(" "+_vm._s(_vm.$t("fields.fieldRequired"))+" ")]):(_vm.showFeedback)?_c('div',{staticClass:"validation-feedback"},[_vm._v(" "+_vm._s(_vm.field.validationFeedback)+" ")]):_vm._e()])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }