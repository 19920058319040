import {ViewSearchHandler} from "@/utilities/search/viewSearchHandler";
import {ENVPView} from "@/model/envp/envpView";
import {Language} from "@/services/language";
import {searchRequestMetadata} from "@/utilities/search/helpers";
import {SearchRespMetadata} from "@/utilities/searchResponse";
import {ENVPServices} from "@/services/envp";


export class envpSearchHandler extends ViewSearchHandler<ENVPView>{

    private envp: ENVPServices
    private language: Language
    private searchString: string

    constructor(
        size: number,
        paginationSize: number,
        maxElementsInCache: number,
        searchString: string,
        envp:ENVPServices,
        language: Language,
        ) {

        super(size, paginationSize, maxElementsInCache)
        this.envp=envp
        this.language = language
        this.searchString = searchString
    }

    setSearchString(searchString: string){
        this.searchString = searchString
    }

     protected async loadBatch(srm: searchRequestMetadata):
        Promise<{ data: ENVPView[]; metadata: SearchRespMetadata } | null> {
        const searchResult = await this.envp.searchENVP(this.searchString, srm)

         if(searchResult === null) {
            this.emptySearchResult = true
            return null
        }
        const viewList = searchResult[0]
        const metadata = searchResult[1]

        this.columns = viewList.columns
        this.emptySearchResult = metadata.numPotentialResults === 0
        return {data: viewList.views, metadata: metadata}
    }
}