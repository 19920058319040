





import {Component, Prop, Vue} from 'vue-property-decorator'
import {ChartProperties} from "@/utilities/chart/chartProperties";

import {
  Chart, ArcElement, LineElement, BarElement, PointElement, BarController, BubbleController,
  DoughnutController, LineController, PieController, PolarAreaController, RadarController,
  ScatterController, CategoryScale, LinearScale, LogarithmicScale, RadialLinearScale,
  TimeScale, TimeSeriesScale, Decimation, Filler, Legend, Title, Tooltip, SubTitle} from 'chart.js';
Chart.register(
  ArcElement, LineElement, BarElement, PointElement, BarController, BubbleController,
  DoughnutController, LineController, PieController, PolarAreaController, RadarController,
  ScatterController, CategoryScale, LinearScale, LogarithmicScale, RadialLinearScale,
  TimeScale, TimeSeriesScale, Decimation, Filler, Legend, Title, Tooltip, SubTitle);

@Component({
  components: {
  }
})
export default class BarChart extends Vue{

  @Prop({required: true}) chartProperties!: ChartProperties
  @Prop({required: true}) dataSets!: any
  @Prop({required: true}) fontSizeOfLegendLabel!: number

  mounted(){
    this.renderChart()
  }

  renderChart(){
    const chartID: any = document.getElementById('barChart')
    chartID.innerHTML = this.chartProperties.observationsLabels
    const myChart = new Chart(chartID, {
    type: this.chartProperties.selectedChartName,
    data: {
        labels: this.chartProperties.convertHTMLLabelsToPlainText(this.chartProperties.observationsLabels),
        datasets: this.dataSets
    },
    options: {
      plugins:{
        legend:{
          labels: {
            color: 'white',
            font:{
              size: this.fontSizeOfLegendLabel
            }
          }
        }
      },
      scales: {
        y: {
          ticks: { color: 'white', beginAtZero: true }
        },
        x: {
          ticks: { color: 'white', beginAtZero: true }
        }
      },
      responsive: true,
      maintainAspectRatio: false
    }
});

    myChart;
  }
}
