import {Field} from "@/model/userInput/fields/util/fieldBase";
import {StringField} from "@/model/userInput/fields/common/string";
import {OptionsField} from "@/model/userInput/fields/common/options";
import {Language} from "@/services/language";
import {SubsetSelectField} from "@/model/userInput/fields/common/subset_select";
import {rawField} from "@/model/userInput/fields/util/raw";
import {FieldConstructor} from "@/model/userInput/fields/common/flagMixin";
import {NormalDistributionOrNonDet} from "@/model/userInput/fields/common/normal_dist_or_non_det";
import {BlobOrNonDetField} from "@/model/userInput/fields/common/blob_or_non_det";
import {NumberField} from "@/model/userInput/fields/common/number";
import {ButtonField} from "@/model/userInput/fields/common/button";
import {ButtonOrNonDetField} from "@/model/userInput/fields/common/button_or_non_det";
import {isNU} from "@/utilities/isNU";
import {PasswordField} from "@/model/userInput/fields/common/password";
import {NumberRangeField} from "@/model/userInput/fields/common/numberRange";
import {LangInfoField} from "@/model/userInput/fields/common/lang_info";


export enum FieldId {
    BlobOrNonDet = "BLOB_OR_NON_DETERMINABLE",
    Button = "BUTTON",
    ButtonOrNonDet = "BUTTON_OR_NON_DET",
    NormalDistOrNonDet = "NORMAL_DIST_OR_NON_DETERMINABLE",
    Number = "NUMBER",
    Options = "OPTIONS",
    String = "STRING",
    SubsetSelect = "SUBSET_SELECT",
    StringOrNonDet = "STRING_OR_NON_DETERMINABLE",
    Password = "PASSWORD",
    NumberRange = "NUMBER_RANGE",
    LangInfo = "LANG_INFO",
    MarkdownContent="MARKDOWN_CONTENT"
}

export const identifierToFormField:
    Record<FieldId, FieldConstructor> = {
    STRING: StringField,
    OPTIONS: OptionsField,
    NORMAL_DIST_OR_NON_DETERMINABLE: NormalDistributionOrNonDet,
    SUBSET_SELECT: SubsetSelectField,
    BLOB_OR_NON_DETERMINABLE: BlobOrNonDetField,
    NUMBER: NumberField,
    BUTTON: ButtonField,
    BUTTON_OR_NON_DET: ButtonOrNonDetField,
    STRING_OR_NON_DETERMINABLE: StringField,
    PASSWORD: PasswordField,
    NUMBER_RANGE: NumberRangeField,
    LANG_INFO: LangInfoField,
    MARKDOWN_CONTENT:StringField
}


export async function deserializeField(value: rawField, language: Language): Promise<Field<any>> {
    const fieldId = value.fieldTypeId
    if(isNU(fieldId)) throw TypeError(`expected fieldId for field ${value} but got null or undefined`)
    const FieldCls = identifierToFormField[fieldId!]
    if(isNU(FieldCls)) throw Error(`Unknown field type with id ${fieldId}.`)
    const field = new FieldCls(value, language)
    await field.deserialize()
    return field
}
