import {NestedFieldContainer} from "@/model/userInput/fieldContainer/nestedFieldContainer";
import {RawFieldContainer} from "@/model/userInput/fieldContainer/fieldContainer";
import {isNU} from "@/utilities/isNU";


export type RawForm = RawFieldContainer & {
    optional?: string[]
}


export class Form extends NestedFieldContainer<RawForm> {

    required: string[] = []

    protected async _deserialize(raw: RawForm) {
        await super._deserialize(raw)
        if(isNU(raw.optional)) raw.optional = []
        this.required = []
        for(const key of Object.keys(this.fields)){
            if(!raw.optional!.includes(key)) {
                this.required.push(key)
                this.fields[key].required = true
            }
        }
    }

    makeEmpty() {
        for(const field of Object.values(this.fields)) field.makeEmpty()
    }

    get valid(): boolean {
        if(!this.nonEmptyFieldsValid()) return false
        const nonEmpty = this.keysOfNonEmptyFields
        for(const key of this.required) if(!nonEmpty.includes(key)) return false
        return true
    }

    serialize(): RawForm {
        return this.serializeNonEmpty()
    }
}
