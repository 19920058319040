







import {Component, Prop, Vue} from "vue-property-decorator";
import NormalDistributionPreview from "@/components/userInput/fields/previews/NormalDistributionPreview.vue"
import FlagPreview from "@/components/userInput/fields/previews/FlagPreview.vue";
import {NormalDistributionOrNonDetFieldT} from "@/model/userInput/fields/common/normal_dist_or_non_det";

@Component({
    components: {FlagPreview, NormalDistributionPreview}
})
export default class NormalDistributionOrFlagPreview extends Vue {
    @Prop({required: true}) field!: NormalDistributionOrNonDetFieldT
}
