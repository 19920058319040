





import {Vue, Component} from "vue-property-decorator";
import FindCombinationsPage from "@/components/proteins/FindCombinationsPage.vue";

@Component({
  components: {FindCombinationsPage}
})
export default class Testpage extends Vue {
}
