




import {Component, Inject, Prop, Vue} from "vue-property-decorator";
import {ServiceType} from "@/services/types";
import {Language} from "@/services/language";
import {Field} from "@/model/userInput/fields/util/fieldBase";
import FieldSummary from "@/components/userInput/util/FieldSummary.vue";


@Component({
    components: {FieldSummary}
})
export default class ProteinSearchFormFieldSummary extends Vue {

    @Prop({required: true}) field!: Field<any>
    @Inject(ServiceType.Language) language!: Language

    labelText: string = ""

    async created(){
        const hierarchies = this.language.getHierarchies(this.field.actualLabel!.id)
        hierarchies.shift()
        let suffix: string = hierarchies.pop()!
        let prefix = this.language.idsToHierarchicalId(hierarchies)
        prefix = await this.language.parse(prefix)
        suffix = await this.language.parse(suffix)
        this.labelText = `${prefix} ${suffix}`
    }
}
