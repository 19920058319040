import VueRouter from "vue-router";
import About from "@/components/About";
import ExcelImportForm from "@/components/ExcelImportForm.vue"
import MediaImportForm from "@/components/MediaImportForm.vue"
import NewProtein from "@/components/NewProtein";
import SignUpForm from "@/components/SignUpForm";
import UserPage from "@/components/user/UserPage";
import UserSearch from "@/components/user/UserSearch";
import UserSettings from "@/components/UserSettings";
import ProteinSearch from '@/components/proteins/ProteinSearch'
import ProteinViewByUrl from "@/components/proteins/ProteinByUrl";
import ProteinEdit from "@/components/proteins/ProteinEdit";
import SOPSearch from "@/components/sop/SOPSearch";
import ENVPSearch from "@/components/envp/ENVPSearch";
import ShowCharts from "@/components/charts/ShowCharts.vue"
import Testpage from "@/components/util/Testpage";
import FindCombinationsPage from "@/components/proteins/FindCombinationsPage"
import DeleteAllProteins from "@/components/proteins/DeleteAllProteins";

const routes = [
    {path: '/about', component: About, name: 'about'},
    {path: '/excel-import', component: ExcelImportForm, name: 'excel_import'},
    {path: '/media-import', component: MediaImportForm, name: 'media_import'},
    {path: '/new-protein', component: NewProtein, name: 'new_protein'},
    {path: '/user', component: UserPage, name: 'user'},
    {path: '/user-settings', component: UserSettings, name: 'user-settings'},
    {path: '/sign-up', component: SignUpForm, name: 'sign_up'},
    {path: '/search-user', component: UserSearch, name: 'search_user'},
    {path: '/search-proteins', component: ProteinSearch, name: 'search-proteins', meta: {"keepAlive": true}},
    {path: '/search-sop', component: SOPSearch, name: 'search-sop'},
    {path: '/search-envp', component: ENVPSearch, name: 'search-envp'},
    {path: '/show-charts', component: ShowCharts, name: 'show-charts'},
    {path: '/protein/view/:proteinName', component: ProteinViewByUrl, name: 'protein-view-by-url'},
    {path: '/protein/edit/:proteinName', component: ProteinEdit, name: 'protein-edit'},
    {path: '/test-page', component: Testpage, name: 'test-page'},
    {path: '/combinations', component: FindCombinationsPage, name: 'find-combinations'},
    {path: '/delete-proteins', component: DeleteAllProteins, name: 'delete-proteins'}
]

export const router = new VueRouter({
    mode: "history",
    routes: routes
})
