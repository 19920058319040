import {FieldContainer, Fields, RawFieldContainer} from "@/model/userInput/fieldContainer/fieldContainer";
import {Language} from "@/services/language";
import {Field} from "@/model/userInput/fields/util/fieldBase";
import {isNU} from "@/utilities/isNU";
import {VNode} from "@/utilities/misc/nested";


export class NestedFieldContainerLayer implements VNode {

    label: string = ""
    nestedFields: {[key: string]: (NestedFieldContainerLayer|Field<any>)} = {}
    isSetup = false

    get children(): (NestedFieldContainerLayer|Field<any>)[] {
        return Object.values(this.nestedFields)
    }

    constructor(label: string, fields: Fields, language: Language) {
        this.initialize(label, fields, language).then()
    }

    async initialize(label: string, fields: Fields, language: Language){
        this.label = await language.parse(label)
        for (const field of Object.values(fields)) {
            let currentNestedForm: NestedFieldContainerLayer = this
            const hierarchies = language.getHierarchies(field.actualLabel!.id)
            for (let index = 0; index < hierarchies.length; ++index) {
                const labelId = hierarchies[index]
                if (index === hierarchies.length - 1) {
                    field.label = await language.parse(labelId)
                    currentNestedForm.nestedFields[labelId] = field
                } else {
                    if (currentNestedForm.nestedFields[labelId] === undefined) {
                        const name = hierarchies[index]
                        currentNestedForm.nestedFields[labelId] = new NestedFieldContainerLayer(name, {}, language)
                    }
                    currentNestedForm = currentNestedForm.nestedFields[labelId] as NestedFieldContainerLayer
                }
            }
        }
        this.isSetup = true
    }
}


export class NestedFieldContainer<T extends RawFieldContainer> extends FieldContainer<T> {

    asNested: NestedFieldContainerLayer|null = null

    protected async _deserialize(raw: RawFieldContainer): Promise<void> {
        await super._deserialize(raw)
        if(isNU(raw.label)) throw Error("nested field container must have a label")
        const fields: Fields = {}
        for (const field of Object.values(this.fields)) fields[field.actualLabel!.id] = field
        this.asNested = new NestedFieldContainerLayer(raw.label!, this.fields, this.language)
    }
}
