















import {Prop, Vue, Component} from "vue-property-decorator";
import {PaginationHelper} from "@/utilities/search/searchHandler";
import {BIconArrowRight, BIconArrowLeft} from "bootstrap-vue";

@Component({
    components: {
        BIconArrowRight, BIconArrowLeft
    }
})
export default class Pagination extends Vue {

    @Prop({required: true}) pagination!: PaginationHelper

    gotoPage(page: number){
        this.$emit("goto", page)
    }
    nextPage(){
        this.$emit("nextPage")
    }
    prevPage(){
        this.$emit("prevPage")
    }
}
