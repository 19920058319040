
















import {Component, Inject, Prop, Vue} from "vue-property-decorator";
import {ServiceType} from "@/services/types";
import {Language} from "@/services/language";
import {Field} from "@/model/userInput/fields/util/fieldBase";
import GenericField from "@/components/userInput/fields/util/GenericField.vue";
import {BIconXSquare} from "bootstrap-vue";
import {isNU} from "@/utilities/isNU";


@Component({
    components: {GenericField, BIconXSquare}
})
export default class FieldSummary extends Vue {

    @Prop({required: true}) field!: Field<any>
    @Prop({required: false}) labelOverride?: string
    @Inject(ServiceType.Language) language!: Language

    get labelText(): string {
        if(!isNU(this.labelOverride)) return this.labelOverride!
        return this.field.label
    }

    emptyField(){
        this.field.makeEmpty()
    }
}
