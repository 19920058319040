






import {Component, Prop, Vue} from "vue-property-decorator";
import {LangInfoField} from "@/model/userInput/fields/common/lang_info";

@Component
export default class LangInfoPreview extends Vue {
    @Prop({required: true}) field!: LangInfoField
}
