import {ResponseType} from "axios";
import config from "@/config/config.json"
import {isDevMode} from '@/env';
import {RawForm} from "@/model/userInput/form/form";

export enum Method{
    GET,
    POST,
    PUT,
    DELETE
}

export type Url = {
    protocol:string, host: string, port: string, route: string
}

export function urlFor(route: string): Url{
    if(isDevMode()){
        return {
            host: config.REST_API.DEV_ADDRESS.HOST,
            port: config.REST_API.DEV_ADDRESS.PORT,
            protocol: config.REST_API.DEV_ADDRESS.PROTOCOL,
            route: route,
        }
    }
    else {
        let protocol = location.protocol
        if (protocol.slice(-1) === ":")
            protocol = protocol.slice(0, -1)
        return {
            host: window.location.hostname,
            port: window.location.port,
            protocol: protocol,
            route: route,
        }
    }
}

export type Header = {
    headerName: string, headerVal: string
}

export type Auth = {
    username: string, password: string
}

export type basicType = number | string | null | boolean

export type JsonLike = {
    [key:string] : JsonLike | basicType | Array<JsonLike | basicType> | RawForm
}

export class HTTPRequest{
    constructor(public method:Method, public url:Url, public data: JsonLike|FormData, public headers: any, public responseType: ResponseType | undefined = undefined, public auth?: Auth){}
}

export class HTTPResponse{
    constructor(public status: number, public statusText: string, public data: any){}
}

export class HTTPErrResponse extends HTTPResponse{
}

export class HTTPNoResponse extends HTTPErrResponse{
    constructor() { super(0, "no response", {})}
}
