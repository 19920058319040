/**
 * represents a message, mainly for the logging service.
 */
export interface Message{
    readonly message: string
    readonly id: number
}

export class WarningMessage implements Message{
    constructor(readonly message: string, readonly id: number) {}
}

export class InfoMessage implements Message{
    constructor(readonly message: string, readonly id: number) {}
}

export class ErrorMessage implements Message{
    constructor(readonly message: string, readonly id: number) {}
}
