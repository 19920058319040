import {Field} from "@/model/userInput/fields/util/fieldBase";
import {Language} from "@/services/language";
import {isNU, isNUE, numberInputEq} from "@/utilities/isNU";
import {rawField} from "@/model/userInput/fields/util/raw";


export type rawFloatRangeField = rawField & {
    decimals?: number
    lower?: number
    upper?: number
    min?: number
    max?: number
}

export class NumberRangeField extends Field<rawFloatRangeField> {

    private originalUpper?: number
    private originalLower?: number
    decimals?: number
    upper?: number
    lower?: number
    min?: number
    max?: number

    get step(): number {
        if(isNU(this.decimals)) return 0.01
        if(this.decimals! === 0) return 1
        return Math.pow(0.1, this.decimals!)
    }

    isEmpty(): boolean {
        return isNUE(this.upper) && isNUE(this.lower)
    }

    makeEmpty() {
        this.lower = undefined
        this.upper = undefined
    }

    get changed(): boolean {
        return !numberInputEq(this.lower, this.originalLower) || !numberInputEq(this.upper, this.originalUpper)
    }

    valid(): boolean {
        if(typeof this.upper !== "number")
            return false
        if(typeof this.lower !== "number")
            return false

        if(!isNU(this.max))
            if(this.upper > this.max!) return false
        if(!isNU(this.min))
            if(this.lower < this.min!) return false

        return this.upper >= this.lower;
    }

    commitChange() {
        this.originalUpper = this.upper
        this.originalLower = this.lower
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async deserializeField(data: rawFloatRangeField, language: Language): Promise<void> {
        this.originalLower = data.lower
        this.originalUpper = data.upper
        this.decimals = data.decimals
        this.lower = data.lower
        this.upper = data.upper
        this.max = data.max
        this.min = data.min
    }

    serialize(): rawFloatRangeField {
        if(!this.valid())
            throw Error("cannot serialize normal distribution")
        return <rawFloatRangeField> {
            upper: this.upper,
            lower: this.lower
        }
    }
}