import {RawViewList, ViewList} from "@/model/userInput/view/viewList";
import {Language} from "@/services/language";
import {RawUserView, UserView} from "@/model/users/userView";


export type RawUserViewList = RawViewList<RawUserView>


export class UserViewList extends ViewList<RawUserView, UserView> {

    protected async deserializeView(raw: RawUserView, language: Language): Promise<UserView> {
        const view = new UserView(raw, language)
        await view.deserialize()
        return view
    }
}