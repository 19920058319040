





















import {Component, Prop, Vue} from "vue-property-decorator";
import {PasswordField as _PasswordField} from "@/model/userInput/fields/common/password";
import {isNU} from "@/utilities/isNU";



@Component
export default class PasswordField extends Vue {

    @Prop({required: true}) fieldData!: _PasswordField
    @Prop({default: false}) inactive!: boolean

     fieldEmpty: boolean=false
     changed(): void{
       this.$emit("changed",{label:this.fieldData.label,value:this.fieldData.value})
     }

    get placeholder(): string {
        if (!isNU(this.fieldData.placeholder)) {
          return this.fieldData.placeholder!
        }
        else return ""
    }
    // Making the Password Field Empty Everytime at the beginning. User needs to enter the password again
    updated() {
      if(!this.fieldData.isEmpty() && !this.fieldEmpty) {
        this.fieldData.makeEmpty()
        this.fieldEmpty=true
      }
    }
    // When User Start writing Password in field then declare it empty
    declareItEmpty(){
      this.fieldEmpty=true
    }

}
