























import {Component, Prop, Vue} from "vue-property-decorator";
import RecursiveRender from "@/components/userInput/util/RecursiveRender.vue";
import {Form} from "@/model/userInput/form/form";
import ProteinSearchFormFieldSummary from "@/components/userInput/util/ProteinSearchFormFieldSummary.vue";
import CollapseToggleIcon from "@/components/util/CollapseToggleIcon.vue"
import {SingularEventBus} from "@/utilities/eventBus";


@Component({
    components: {RecursiveRender, ProteinSearchFormFieldSummary, CollapseToggleIcon}
})
export default class MainProteinSearchForm extends Vue{

    @Prop({required: true}) form!: Form
    @Prop({default: null}) closeFormEventBus?: SingularEventBus
    @Prop({default: 0}) layersToUnfold!: number
    @Prop({default: 0}) layersToIgnore!: number

}
