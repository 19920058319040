import {rawField} from "@/model/userInput/fields/util/raw";
import {Field} from "@/model/userInput/fields/util/fieldBase";
import {Language} from "@/services/language";
import {isNU} from "@/utilities/isNU";
import {VLeaf, VNode} from "@/utilities/misc/nested";
import i18n from "@/i18n";


type rawSubsetSelectField = rawField & {
    value?: string[]
    chooseFrom?: string[]
}


export class SubsetSelectLeaf implements VLeaf{

    constructor(public label: string,
                public labelId: string,
                public selected: boolean,
                public parent: SubsetSelectNode|null = null){}

    fullLabel(language: Language): string {
        const labels = [this.label]
        let parent: SubsetSelectNode|null = this.parent
        while (parent !== null) {
            labels.unshift(parent.label)
            parent = parent.parent
        }
        labels.shift()
        return language.turnHierarchiesToDisplayedText(labels)
    }
}

export class SubsetSelectNode implements VNode {

    parent: SubsetSelectNode|null
    label: string
    labelId: string
    children: (SubsetSelectNode|SubsetSelectLeaf)[] = []

    constructor(label: string,
                labelId: string,
                children: {selected: boolean, labels: string[], labelIds: string[]}[],
                parent: SubsetSelectNode|null = null){

        this.label = label
        this.labelId = labelId
        this.parent = parent

        const temp: {[key: string]: {selected: boolean, labels: string[], labelIds: string[]}[]} = {}
        const tempLangMap: {[key: string]: string} = {}
        for (const c of children){
            const firstId = c.labelIds[0]
            if (!temp[firstId]) {
                temp[firstId] = [c]
                tempLangMap[firstId] = c.labels[0]
            } else {
                temp[firstId].push(c)
            }
        }

        for(const [key, values] of Object.entries(temp)){
            const childrenOfChildren: {selected: boolean, labels: string[], labelIds: string[]}[] = []
            for(const val of values){
                if(val.labelIds.length === 1){
                    const field = new SubsetSelectLeaf(val.labels[0], key, val.selected, this)
                    this.children.push(field)
                }
                else if (val.labelIds.length > 1){
                    val.labelIds.shift()
                    val.labels.shift()
                    childrenOfChildren.push(val)
                }
            }
            if(childrenOfChildren.length > 0) this.children.push(new SubsetSelectNode(tempLangMap[key], key, childrenOfChildren, this))
        }
    }

    traverse(labelIds: string[]): null|SubsetSelectLeaf|SubsetSelectNode {
        let current: SubsetSelectNode | SubsetSelectLeaf = this
        for (const l of labelIds) {
            if (current instanceof SubsetSelectLeaf) return null
            // @ts-ignore
            for (const c of current.children) {
                if (c.labelId === l) {
                    current = c
                    break
                }
            }
        }
        return current
    }
}


export class SubsetSelectField extends Field<rawSubsetSelectField> {

    private originalVal?: string[]
    value: string[] = []  // contains display names
    chooseFrom: string[] = []  // contains label ids
    constraintDisplayNames: {chooseFrom: string[]} = {chooseFrom: []}  // contains display names
    asNestedStructure: SubsetSelectNode|SubsetSelectLeaf|null = null
    reverseOptionsMap: {[key: string]: string} = {}
    orderKeys: {[key: string]: number} = {}  // contains display names to order them correctly

    isEmpty(): boolean {
        return !(this.value !== null && this.value !== undefined)
    }

    isProteinListEmpty(): boolean {
        return !(this.selectedProteinsList !== null && this.selectedProteinsList !== undefined)
    }

    makeEmpty() {
        this.value = []
    }

    makeEmptyProteinsList(){
        this.selectedProteinsList = []
    }

    get changed(): boolean {
        if(isNU(this.originalVal)) return !isNU(this.value)
        for(const elem of this.originalVal!) if(!this.value.includes(elem)) return false
        for(const elem of this.value) if(!this.originalVal!.includes(elem)) return false
        return true
    }

    commitChange() {
        this.originalVal = this.value
    }

    private sortValues(){
        this.value = this.value.sort((a, b) => {
            if(this.orderKeys[a] === undefined || this.orderKeys[a] === null) return 1
            if(this.orderKeys[b] === undefined || this.orderKeys[b] === null) return -1
            return this.orderKeys[a] - this.orderKeys[b]
        })
    }

    select(val: string){
        this.value.push(val)
        this.sortValues()
    }

    unselect(val: string){
        this.value = this.value.filter(item => {
            return item !== val
        })
    }

    private static async toNestedStructure(value: string[], chooseFrom: string[], chooseFromDisplayNames: string[], language: Language):
        Promise<SubsetSelectNode|SubsetSelectLeaf> {

        if (chooseFrom.length == 1) {
            const labelId = chooseFrom[0]
            const label = chooseFromDisplayNames[0]
            const selected = value.includes(chooseFromDisplayNames[0])
            return new SubsetSelectLeaf(label, labelId, selected)
        } else {
            const children: {selected: boolean, labels: string[], labelIds: string[]}[] = []
            for (const c of chooseFrom) {
                let selected = false
                if (value.includes(await language.parse(c))) selected = true
                children.push({
                    selected: selected,
                    labels: await language.parseMultiple(language.getHierarchies(c)),
                    labelIds: language.getHierarchies(c)
                })
            }
            return  new SubsetSelectNode(i18n.t("proteinView.allProperties") as string, "", children)
        }
    }

    async deserializeField(data: rawSubsetSelectField, language: Language) {
        if(data.value) {
            const value = await language.parseMultiple(data.value)
            this.value = value
            this.originalVal = value
        } else {
            this.value = []
            this.originalVal = []
        }
        if(isNU(data.chooseFrom)) this.chooseFrom = []
        else this.chooseFrom = data.chooseFrom!
        const values = await language.parseMultiple(this.chooseFrom)
        this.constraintDisplayNames = {chooseFrom: values}
        this.reverseOptionsMap = {}
        for (let iVal = 0; iVal < values.length; ++iVal) {
            this.reverseOptionsMap[values[iVal]] = this.chooseFrom[iVal]
        }
        for (let i = 0; i < values.length; i++){
            this.orderKeys[values[i]] = i
        }
        this.asNestedStructure = await SubsetSelectField.toNestedStructure(this.value, this.chooseFrom, this.constraintDisplayNames.chooseFrom, language)
        this.sortValues()
    }

    selectedIds(): string[] {
        const values: string[] = []
        this.value.forEach(val => values.push(this.reverseOptionsMap[val]))
        return values
    }

    selectedProtein(): string[] {
        const proteins: string[] = []
        this.selectedProteinsList.forEach(val => proteins.push(val))
        return proteins
    }

    serialize(): rawSubsetSelectField {
        if (!this.valid())
            throw Error("cannot serialize subset-select field")
        if(isNU(this.value)) return <rawSubsetSelectField>{value: []}
        if(isNU(this.selectedProteinsList)) return <rawSubsetSelectField>{selectedProteinsList: []}
        const values: string[] = this.selectedIds()
        const proteins: string[] = this.selectedProtein()
        return <rawSubsetSelectField>{
            value: values,
            selectedProteinsList: proteins
        }
    }

    valid(): boolean {
        if(isNU(this.value)) return false
        if(isNU(this.selectedProteinsList)) return false
        if(isNU(this.chooseFrom)) return false
        for(const v of this.value){
            if(!this.constraintDisplayNames.chooseFrom.includes(v)) return false
        }
        return true
    }
}
