






import {Component, Prop, Vue} from "vue-property-decorator";
import {OptionsField} from "@/model/userInput/fields/common/options";

@Component
export default class OptionsFieldPreview extends Vue {
    @Prop({required: true}) field!: OptionsField
}
