import {SearchRespMetadata} from "@/utilities/searchResponse";
import {ProteinQueries} from "@/services/proteinQueries";
import {searchRequestMetadata} from "@/utilities/search/helpers";
import {ProteinView} from "@/model/protein/proteinView";
import {Sorting} from "@/utilities/search/sorting";
import {Form} from "@/model/userInput/form/form";
import {ViewColumn} from "@/model/userInput/view/viewList";
import {Language} from "@/services/language";
import {removeFirstHierarchyAndParse} from "@/utilities/protein/shortenLabel";
import {ViewSearchHandler} from "@/utilities/search/viewSearchHandler";


export class ChartObservationsSearchHandler extends ViewSearchHandler<ProteinView> {

    private pq: ProteinQueries
    private readonly language: Language
    private form: Form
    private searchSorting?: Sorting

    constructor(
        size: number,
        paginationSize: number,
        maxElementsInCache: number,
        searchForm: Form,
        pq: ProteinQueries,
        language: Language,
        searchSorting?: Sorting,
        ) {

        super(size, paginationSize, maxElementsInCache)
        this.pq = pq
        this.form = searchForm
        this.searchSorting = searchSorting
        this.language = language
    }

    setSorting(sorting: Sorting){
        this.searchSorting = sorting
    }

    setSearchForm(searchForm: Form){
        this.form = searchForm
    }

    async shortenAndSetColumns(cols: ViewColumn[], language: Language) {
        const shortened = []
        for(const col of cols){
            col.label.text = await removeFirstHierarchyAndParse(col.label.id, language)
            shortened.push(col)
        }
        this.columns = shortened
    }

    async updateColumns(): Promise<void> {
        const columns: ViewColumn[] | null = await this.pq.getChartObservationsViewColumns()
        if(columns !== null) await this.shortenAndSetColumns(columns, this.language)
    }

    protected async loadBatch(srm: searchRequestMetadata):
        Promise<{ data: ProteinView[]; metadata: SearchRespMetadata } | null> {
        const searchResult = await this.pq.searchChartObservations(this.form, srm)
        if(searchResult === null) {
            this.emptySearchResult = true
            return null
        }
        const viewList = searchResult[0]
        const metadata = searchResult[1]
        await this.shortenAndSetColumns(viewList.columns, this.language)
        this.emptySearchResult = metadata.numPotentialResults === 0
        return {data: viewList.views, metadata: metadata}
    }
}
