import {Form} from "@/model/userInput/form/form";
import {NumberRangeField} from "@/model/userInput/fields/common/numberRange";

export class ProteinCombinationNumberOfProteinsForm extends Form{
    get valid(): boolean {
        return (!this.numberOfProteinsRangeField.isEmpty()) && super.valid
    }
    get numberOfProteinsRangeField(): NumberRangeField {
        return <NumberRangeField>Object.values(this.fields)[0]
    }
}
