







import {Component, Prop, Vue} from "vue-property-decorator";
import FlagPreview from "@/components/userInput/fields/previews/FlagPreview.vue";
import {ButtonOrNonDetFieldT} from "@/model/userInput/fields/common/button_or_non_det";
import ButtonPreview from "@/components/userInput/fields/previews/ButtonPreview.vue";

@Component({
    components: {ButtonPreview, FlagPreview}
})
export default class ButtonOrFlagPreview extends Vue {
    @Prop({required: true}) field!: ButtonOrNonDetFieldT
}
