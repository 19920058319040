











import {Component, Prop, Vue} from "vue-property-decorator";
import {ButtonField as _ButtonField} from "@/model/userInput/fields/common/button";


@Component
export default class ButtonField extends Vue {

    @Prop({required: true}) fieldData!: _ButtonField
    @Prop({default: false}) inactive!: boolean

    get field() {
        return this.fieldData
    }

    toggleIfMutable() {
        this.fieldData.toggleIfMutable()
    }
}
