import {Message, InfoMessage, WarningMessage, ErrorMessage} from "@/utilities/logging";

export enum LoggingLevel {
    USER_INFO,
    TECHNICAL
}

/**
 * This service is responsible for creating, holding and deleting logging messages.
 */
export interface Logging{

    messages: Array<Message>
    setLoggingLevel(level: LoggingLevel): void
    addInfo(message: string, timeout?: number, loggingLevel?: LoggingLevel): void
    addWarning(message: string, timeout?: number, loggingLevel?: LoggingLevel): void
    addError(message: string, timeout?: number, loggingLevel?: LoggingLevel): void
    removeMessage(m: Message): void
}

export class LoggingImp implements Logging {

    private DEFAULT_TIMEOUT: number = 3500 // ms
    private level: LoggingLevel = LoggingLevel.USER_INFO
    private id: number = 0
    messages: Array<Message> = []

    getMessageId(): number {
        this.id ++
        return this.id
    }

    setLoggingLevel(level: LoggingLevel) {
        this.level = level
    }

    removeMessage(m: Message) {
        const id = m.id
        const toDel: Message | undefined = this.messages.find(message => message.id === id)
        if(!toDel) throw new Error(`Could not remove message with id ${id}. Message was not found in logger.`)
        const index: number = this.messages.indexOf(toDel)
        this.messages.splice(index, 1)
    }

    addInfo(message: string, timeout?: number, loggingLevel?: LoggingLevel) {

        if(loggingLevel && loggingLevel > this.level) return;
        const m: Message = new InfoMessage(message, this.getMessageId())
        this.messages.push(m)
        if(!timeout) timeout = this.DEFAULT_TIMEOUT

        setTimeout(()=>{
            this.removeMessage(m)
        }, timeout)
    }

    addWarning(message: string, timeout?: number, loggingLevel?: LoggingLevel) {

        if(loggingLevel && loggingLevel > this.level) return;
        const m: Message = new WarningMessage(message, this.getMessageId())
        this.messages.push(m)
        if(!timeout) timeout = this.DEFAULT_TIMEOUT

        setTimeout(()=>{
            this.removeMessage(m)
        }, timeout)
    }

    addError(message: string, timeout?: number, loggingLevel?: LoggingLevel) {

        if(loggingLevel && loggingLevel > this.level) return;
        const m: Message = new ErrorMessage(message, this.getMessageId())
        this.messages.push(m)
        if(!timeout) timeout = this.DEFAULT_TIMEOUT

        setTimeout(()=>{
            this.removeMessage(m)
        }, timeout)
    }
}
