






import {Component, Prop, Vue} from "vue-property-decorator";
import {NumberField} from "@/model/userInput/fields/common/number";

@Component
export default class NumberFieldPreview extends Vue {
    @Prop({required: true}) field!: NumberField
}
