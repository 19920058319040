import { render, staticRenderFns } from "./Testpage.vue?vue&type=template&id=40d7e4d1&scoped=true&"
import script from "./Testpage.vue?vue&type=script&lang=ts&"
export * from "./Testpage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d7e4d1",
  null
  
)

export default component.exports