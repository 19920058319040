import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locals/translations/en.json'
import de from './locals/translations/de.json'

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages: {
    en,
    de
  }
})