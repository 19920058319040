

































import {Component, Inject, Prop, Vue} from "vue-property-decorator";
import TooltipButton from "@/components/util/TooltipButton.vue";
import ProteinCombinationProgress from "@/components/proteins/ProteinCombinationProgress.vue";
import {
  ProteinCombinationTargetForm as _ProteinCombinationTargetForm
} from "@/model/protein/proteinCombinationTargetForm";
import {ProteinCombinationNumberOfProteinsForm} from "@/model/protein/proteinCombinationNumberOfProteinsForm";
import {Form} from "@/model/userInput/form/form";
import {ServiceType} from "@/services/types";
import {ProteinQueries} from "@/services/proteinQueries";
import {Logging} from "@/services/logging";
import {CombinationFindingState, ProteinCombinationResponse} from "@/model/protein/proteinCombinationResponse";
import {ProteinCombinationView} from "@/model/protein/proteinCombinationView";
import {ViewColumn} from "@/model/userInput/view/viewList";
import ProteinCombinationViewVue from "@/components/userInput/ProteinCombinationView.vue";
import ViewList from "@/components/userInput/util/ViewList.vue";
import ButtonWrapper from "@/components/util/ButtonWrapper.vue";
import {BIconArrowLeft} from "bootstrap-vue";

@Component({
  components: {ButtonWrapper, ViewList, BIconArrowLeft, ProteinCombinationViewVue, TooltipButton, ProteinCombinationProgress}
})
export default class ProteinCombinationsSearch extends Vue{

  @Prop({required: true}) combinationTargetsForm!: _ProteinCombinationTargetForm
  @Prop({required: true}) numberOfProteinsInCombinationForm!: ProteinCombinationNumberOfProteinsForm
  @Prop({required: true}) limitProteinsForm!: Form
  @Prop({required: true}) referenceProteinIds!: string[]

  @Inject(ServiceType.ProteinQueries) pq!: ProteinQueries
  @Inject(ServiceType.Logging) logging!: Logging

  private currentlySearching: boolean = false
  private combinationFindingState: CombinationFindingState | null = null
  private combinationViews: ProteinCombinationView[] = []
  private combinationViewCols: ViewColumn[] = []
  private maxProteinSubsetsCnt: number = 0
  private checkedProteinsSubsetsCnt: number = 0
  private stopSearchingForCombinations: boolean = false
  private combinationView: ProteinCombinationView | null = null
  private exhausted: boolean = false

  logError(){this.logging.addError("Fehler beim Finden von Kombinationen")}
  logNoCombinationsFound(){this.logging.addWarning("Keine Kombinationen für die Anforderungen gefunden")}
  logExhausted(){this.logging.addInfo("Keine weiteren Kombinationen für die Anforderungen gefunden")}

  $destroy() {
    this.stopSearchingForCombinations = true
  }

  async created() {
    await this.getFirstCombination(this.combinationTargetsForm, this.numberOfProteinsInCombinationForm, this.limitProteinsForm, this.referenceProteinIds)
  }

  showCombinationView(view: ProteinCombinationView){ this.combinationView = view }
  hideCombinationView(){ this.combinationView = null }

  get feedback(): string {
    if(this.exhausted) return this.$t("FindCombinationsPage.feedbackExhausted").toString()
    if(this.currentlySearching) return this.$t("FindCombinationsPage.feedbackCurrentlySearching").toString()
    return ""
  }

  handleResponse(resp: ProteinCombinationResponse) {
      if(resp.isMalformed()) {
        this.logError()
        return
      }
      this.checkedProteinsSubsetsCnt += resp.numTries!
      if(resp.exhausted || this.checkedProteinsSubsetsCnt === this.maxProteinSubsetsCnt) {
        this.exhausted = true
        this.currentlySearching = false
        return
      }
      this.combinationFindingState = resp.combinationFinder!
      for (const view of resp.views!) {
        this.combinationViews.push(view)
      }
      this.currentlySearching = false
  }

  async getNextCombination() {
    this.currentlySearching = true
    while(this.currentlySearching && !this.stopSearchingForCombinations){
      const resp = await this.pq.nextCombination(this.combinationFindingState!)
      if(resp === null) {
        this.logError()
        this.currentlySearching = false
      }
      else this.handleResponse(resp)
    }
  }

  async getFirstCombination(combinationTargetsForm: _ProteinCombinationTargetForm,
                            numberOfProteinsInCombinationForm: ProteinCombinationNumberOfProteinsForm,
                            limitProteinsForm: Form,
                            proteinIds: string[]){

    this.currentlySearching = true

    const resp = await this.pq.getCombination(combinationTargetsForm, numberOfProteinsInCombinationForm, limitProteinsForm, proteinIds)

    if(resp === null) this.logError()
    else {
      this.combinationViewCols = resp.viewCols
      this.maxProteinSubsetsCnt = resp.maxPossibleCombinations
      const combinationResp = resp.combinationResp
      this.handleResponse(combinationResp)
      if(this.currentlySearching) await this.getNextCombination()
    }
  }

  reset(){this.$emit("reset")}
}
