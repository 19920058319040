import {AxiosCalls} from "@/services/axiosCalls";
import {HTTPErrResponse, HTTPRequest, HTTPResponse, Method, Url, urlFor} from "@/utilities/http";
import config from "@/config/config.json"
import {Logging, LoggingLevel} from "@/services/logging";
import {UserService} from "@/services/user";


export type Group = {groupName: string}

export interface GroupsService {
    availableGroups: Array<Group>
    updateGroups(): void
    isGroup(groupName: any): boolean
}

export class GroupsServiceImp implements GroupsService {

    private ax: AxiosCalls
    private groups: Array<Group> = []
    private logger: Logging
    private userS: UserService

    constructor(ax: AxiosCalls, logger: Logging, userService: UserService) {
        this.ax = ax
        this.logger = logger
        this.userS = userService
    }

    updateGroups(): void {
        const url: Url = urlFor(config.REST_API.ROUTES.USER.GET_GROUPS)
        const req: HTTPRequest = new HTTPRequest(Method.GET, url, {}, [])
        this.ax.request(req).then((res: HTTPResponse) => {
            try {
                this.groups = res.data["groups"]
            }
            catch (e){
                console.log(e)
                this.logger.addError(
                    "Nutzergruppen konnten nicht aktualisiert werden", undefined, LoggingLevel.USER_INFO)
            }
        })
        .catch((err: HTTPErrResponse)=>{
            this.logger.addError(
                "Nutzergruppen konnten nicht aktualisiert werden", undefined, LoggingLevel.USER_INFO)
            this.logger.addError(err.statusText, undefined, LoggingLevel.TECHNICAL)
            return false
        })
    }

    get availableGroups(): Array<Group> {
        if(this.groups.length === 0)
            this.updateGroups()
        return this.groups
    }

    isGroup(group: Group): boolean {
        return this.groups.includes(group)
    }
}
