













import {Component, Prop, Vue} from "vue-property-decorator";
import StringField from "@/components/userInput/fields/StringField.vue";
import NonDeterminableButton from "@/components/userInput/fields/NonDeterminableButton.vue";
import {StringOrNonDetFieldT} from "@/model/userInput/fields/common/string_or_non_det";


@Component({
  components: {NonDeterminableButton, StringField}
})
export default class StringOrFlagField extends Vue {

    @Prop({required: true}) fieldData!: StringOrNonDetFieldT
    @Prop({default: false}) inactive!: boolean

    changed() {
        this.$emit("changed")
    }
}
