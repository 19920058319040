













import {Component, Prop, Vue} from "vue-property-decorator";
import {ButtonOrNonDetFieldT} from "@/model/userInput/fields/common/button_or_non_det";
import BlobField from "@/components/userInput/fields/BlobField.vue";
import NonDeterminableButton from "@/components/userInput/fields/NonDeterminableButton.vue";


@Component({
  components: {NonDeterminableButton, BlobField}
})
export default class BlobOrFlagField extends Vue {

    @Prop({required: true}) fieldData!: ButtonOrNonDetFieldT
    @Prop({default: false}) inactive!: boolean

    changed() {
        this.$emit("changed")
    }
}
