













import {Component, Prop, Vue} from "vue-property-decorator";
import NonDeterminableButton from "@/components/userInput/fields/NonDeterminableButton.vue";
import {NormalDistributionOrNonDetFieldT} from "@/model/userInput/fields/common/normal_dist_or_non_det";
import NormalDistributionVue from "@/components/userInput/fields/NormalDistribution.vue"


@Component({
  components: {NonDeterminableButton, NormalDistributionVue}
})
export default class NormalDistributionOrFlagField extends Vue {

    @Prop({required: true}) fieldData!: NormalDistributionOrNonDetFieldT
    @Prop({default: false}) inactive!: boolean

    changed() {
        this.$emit("changed")
    }
}
