



































import {Inject, Component, Prop, Vue} from 'vue-property-decorator';
import {Field} from "@/model/userInput/fields/util/fieldBase";
import {FieldId} from "@/model/userInput/fields/util/fieldIds"
import {ServiceType} from "@/services/types";
import {ProteinQueries} from "@/services/proteinQueries";
import SubsetSelectField from "@/components/userInput/fields/SubsetSelectField.vue";
import BlobOrFlagField from "@/components/userInput/fields/BlobOrFlagField.vue";
import StringField from "@/components/userInput/fields/StringField.vue";
import StringOrFlagField from "@/components/userInput/fields/StringOrFlagField.vue";
import OptionsField from "@/components/userInput/fields/OptionsField.vue";
import ButtonField from "@/components/userInput/fields/ButtonField.vue";
import ButtonOrFlagField from "@/components/userInput/fields/ButtonOrFlagField.vue";
import NormalDistributionOrFlagField from "@/components/userInput/fields/NormalDistributionOrFlagField.vue";
import NumberField from "@/components/userInput/fields/NumberField.vue";
import PasswordField from "@/components/userInput/fields/PasswordField.vue";
import NumberRangeFeld from "@/components/userInput/fields/NumberRangeFeld.vue";
import LangInfoField from "@/components/userInput/fields/LangInfoField.vue";
import {UserDetails} from "@/services/user";
import MarkdownField from "@/components/userInput/fields/MarkdownField.vue";


@Component({
    components: {
      MarkdownField,
      LangInfoField,
      NumberRangeFeld,
      PasswordField,
      NumberField,
      NormalDistributionOrFlagField,
      ButtonOrFlagField,
      ButtonField,
      OptionsField,
      StringOrFlagField,
      StringField,
      BlobOrFlagField,
      SubsetSelectField
    }
})
export default class GenericField extends Vue {
    @Inject(ServiceType.ProteinQueries) pq!: ProteinQueries

    @Prop({required: true}) field!: Field<any>
    @Prop({default: false}) isPreview!: boolean
    @Prop({default: false}) disableValidationFeedback!: boolean
    @Prop({default: true}) showLabel!: boolean
    @Prop({default: false}) showSopContent!:boolean
    @Prop({default: ""}) formName!: string
    @Prop({default: 0}) indent!: number
    @Prop({required: false, default: true}) showCategoriesSelections!: boolean

    // This selectedProteins Only required when we need to show the Protein Names
    // On Filter Observations Modal Selection View. Otherwise, It would always be null
    @Prop({required: false}) selectedProteins!: string[]
    searchHelp: string = ""

    get fieldId(): typeof FieldId {
        return FieldId
    }

    get transparent() {
        return this.field.isEmpty() || !this.field.mutable
    }

    get showFeedback() {
        return !this.field.isEmpty() && !this.field.valid() && !this.disableValidationFeedback
    }

    get mustBeFilledOut() {
        return this.field.requiredButEmpty
    }

    get redOutline(): boolean {
        if(!this.field.mutable) return false
        return (!this.field.isEmpty() && !this.field.valid()) || this.field.requiredButEmpty
    }

    created() {
        this.pq.getSearchHelp(this.field.actualLabel!.id).then((data => {
            this.searchHelp = data
        }))
    }

    getSearchHelp(): string {
        return this.searchHelp
    }

    // Detect in which password field the input is given
    changeInPasswordField(data:any){
        if(data['label'] === this.$t('UserSettingsPage.LabelTexts.OldPassword').toString())
            UserDetails.prototype.enteredPassword=data['value']
        else if(data['label'] === this.$t('UserSettingsPage.LabelTexts.NewPassword').toString())
            UserDetails.prototype.newPassword=data['value']
        else if(data['label'] === this.$t('UserSettingsPage.LabelTexts.ReTypePassword').toString())
            UserDetails.prototype.newPasswordRetype=data['value']
    }
    // Detect Language Option Changed in Language Dropdown
    changeInOption(data:any){
        if(data['label'] === this.$t('UserSettingsPage.LabelTexts.Language').toString()) {
            if(data['value'] === "English" || data['value'] === "Englisch") localStorage.setItem('lang',"en")
            else localStorage.setItem('lang',"de")
            if(UserDetails.prototype.currentLanguage!=localStorage.getItem("lang")) this.$emit("languageChanged", true)
            else this.$emit("languageChanged", false)
        }
    }
}
