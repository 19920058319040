/**
 * Service identifier declarations.
 */


const ServiceType = {
    AxiosCalls: 'AXIOS_CALLS',
    Groups: 'GROUPS',
    Language: 'LANGUAGE',
    Logging: 'LOGGING',
    ProteinQueries: 'PROTEIN_QUERIES',
    User: 'USER_SERVICE',
    SOP:"SOP_SEARCH",
    ENVP:"ENVP_SEARCH",
    FormGetter: 'FORM_GETTER'
}

export {ServiceType}
