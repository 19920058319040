
















































import {Component, Prop, Vue} from "vue-property-decorator";
import {NormalDistributionField as _NormalDistributionField} from "@/model/userInput/fields/common/normalDistribution";


@Component
export default class NormalDistributionField extends Vue {

    @Prop({required: true}) fieldData!: _NormalDistributionField
    @Prop({default: false}) inactive!: boolean

    get field() {
        return this.fieldData
    }

    get valid() {
        return this.field.valid()
    }

    changed() {
        this.$emit("changed")
    }

    get placeholderMean(): string {
        if (this.field.meanPlaceholder)
            return this.field.meanPlaceholder
        else return ""
    }

    get placeholderStd(): string {
        if (this.field.stdDeviationPlaceholder)
            return this.field.stdDeviationPlaceholder
        else return ""
    }
}
