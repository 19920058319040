
export class ChartProperties{
    public selectedChartName: any = ""
    public observationsLabels: string[] = []
    public proteinNamesLabels: string[] = []
    public selectedColumnNames: string[] = []
    public chartTypes: any = {
	Radar: "radar",
	Bar: "bar",
	Line: "line"
    }

    constructor() {
    }

    setSelectedChartName(selectedIndex: number){
        this.selectedChartName = Object.values(this.chartTypes)[selectedIndex]
    }

    convertHTMLLabelsToPlainText(content: string[]): string[]{
        let allLabels: string[] = []
        content.forEach((label) =>{
            for (const to_delete of ["<sub>", "</sub>", "&shy;"]) {
                let new_label = label.replace(to_delete, "")
                while (new_label !== label) {
                    label = new_label
                    new_label = label.replace(to_delete, "")
                }
                label = new_label
            }
            allLabels.push(label)
        })
        return allLabels
    }
}
