



































import {Component, Inject, Vue} from 'vue-property-decorator'
import {BIconArrowLeftShort, BIconSearch, BIconX} from "bootstrap-vue";
import {UserService} from "@/services/user";
import {ServiceType} from "@/services/types";
import PaginatedViewList from "@/components/userInput/util/PaginatedViewList.vue";
import {UserSearchHandler} from "@/utilities/users/userSearchHandler";
import {Language} from "@/services/language";
import {UserView as UserViewT} from "@/model/users/userView";
import UserView from "@/components/userInput/UserView.vue";

@Component({
    components: {UserView, PaginatedViewList, BIconSearch, BIconArrowLeftShort, BIconX}
})
export default class UserSearch extends Vue {

    searchString: string = ""
    userSearchHandler: UserSearchHandler | null = null
    viewToShow: UserViewT | null = null
    updateViewSpinnerState = false

    readonly initialBatchSize: number = 10
    readonly paginationSize: number = 7
    readonly maxUserViewsCached: number = 1000

    @Inject(ServiceType.Language) language!: Language
    @Inject(ServiceType.User) userService!: UserService

    handleSearch(){
        if(this.userSearchHandler === null) {
            this.userSearchHandler = new UserSearchHandler(
                this.initialBatchSize,
                this.paginationSize,
                this.maxUserViewsCached,
                this.searchString,
                this.userService,
                this.language
            )
        }
        else {
            this.userSearchHandler.setSearchString(this.searchString)
        }
        this.userSearchHandler.firstBatch()
    }

    showView(view: UserViewT) {
        this.viewToShow = view
    }

    get viewChanged(): boolean {
        if(this.viewToShow === null) return false
        return this.viewToShow.changedFields.length > 0
    }

    async updateUserView() {
        if(this.viewToShow === null) return
        this.updateViewSpinnerState = true
        await this.userService.updateUserViewAsAdmin(this.viewToShow)
        this.updateViewSpinnerState = false
    }

    cancelView() {
        this.viewToShow = null
    }
}
