import {RawViewList, ViewList} from "@/model/userInput/view/viewList";
import {Language} from "@/services/language";
import {RawENVPView, ENVPView} from "@/model/envp/envpView";


export type RawENVPViewList = RawViewList<RawENVPView>


export class ENVPViewList extends ViewList<RawENVPView, ENVPView> {

    protected async deserializeView(raw: RawENVPView, language: Language): Promise<ENVPView> {
        const view = new ENVPView(raw, language)
        await view.deserialize()
        return view
    }
}