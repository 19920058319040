export interface Subscriber<T>{
    handleEvent(payload: T): void
}

export class EventBus<T>{

    subscribers: Subscriber<T>[] = []

    subscribe(s: Subscriber<T>){
        this.subscribers.push(s)
    }

    publish(payload: T){
        this.subscribers.forEach(s => s.handleEvent(payload))
    }
}

export class SingularEventBus extends EventBus<void> {}
export interface SingularEventSubscriber extends Subscriber<void> {}