import {HTTPRequest, JsonLike, Method, Url, urlFor} from "@/utilities/http";
import {searchRequestMetadata} from "@/utilities/search/helpers";
import {SearchRespMetadata} from "@/utilities/searchResponse";
import config from "@/config/config.json";
import {AxiosCalls} from "@/services/axiosCalls";
import {Language} from "@/services/language";
import {Logging} from "@/services/logging";
import {RawSOPViewList, SOPViewList} from "@/model/sop/sopViewList";


export class DownloadSOPFileDetails{
     public fileid: string = ""
     public filename: string = ""
}

export class SearchSOPFileDetails{
     public filename_to_search: string = ""
}

export interface SOPServices{

    searchSOP(searchString: string, mataData: searchRequestMetadata): Promise<[SOPViewList, SearchRespMetadata]|null>
}

export class SOPServicesImp implements SOPServices{

    protected ax: AxiosCalls
    private language: Language
    private logger: Logging

    constructor(ax: AxiosCalls, logger: Logging, language: Language) {
        this.ax = ax
        this.logger = logger
        this.language = language
    }
    async searchSOP(searchString: string, mataData: searchRequestMetadata): Promise<[SOPViewList, SearchRespMetadata]|null> {
        const url: Url = urlFor(config.REST_API.ROUTES.SOP.SOP_SEARCH)
        const data = <JsonLike>{
            "searchString": searchString,
            "searchRequestMetadata": mataData
        }
        const req: HTTPRequest = new HTTPRequest(Method.POST, url, data, [])
        try {
            const res = await this.ax.request(req)
            const rawSopViewList: RawSOPViewList = res.data["views"]
            const searchMetadata: SearchRespMetadata = res.data["searchResponseMetadata"]
            const sopViewList = new SOPViewList(rawSopViewList, this.language)
            await sopViewList.deserialize()
            return [sopViewList, searchMetadata]
        }
        catch (e) {
            console.log("error while searching sop: \n", e)
            this.logger.addError("Suche fehlgeschlagen")
            return null
        }
    }
}