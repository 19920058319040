























import {Component, Inject, Prop, Vue} from "vue-property-decorator";
import {ServiceType} from "@/services/types";
import {ProteinQueries} from "@/services/proteinQueries";
import {ProteinView as _ProteinView} from "@/model/protein/proteinView";
import ProteinView from "@/components/userInput/ProteinView.vue";

@Component({
    components: {
      ProteinView
    }
})
/**
 * this component requests the server to load the protein with the given proteinName,
 * the proteinName can be provided via url (see router.js) or via the prop 'proteinName'
 */
export default class ProteinEdit extends Vue {

    @Inject(ServiceType.ProteinQueries) pq!: ProteinQueries

    @Prop({default: null, required: false}) proteinName!: string | null

    proteinView: _ProteinView | null = null
    loading: boolean = false

    async getProteinView(){
        this.loading = true
        let proteinId = ""
        if(this.$route.params["proteinName"]) proteinId = this.$route.params["proteinName"]
        else if(this.proteinName) proteinId = this.proteinName
        else throw Error("cannot load protein since no protein identifier was given")
        this.proteinView = await this.pq.getUpdatableProteinView(proteinId)
        this.loading = false
    }

    async created(){
        await this.getProteinView()
    }
}
