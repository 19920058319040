















import {Component, Prop, Vue} from 'vue-property-decorator'
import {Field} from "@/model/userInput/fields/util/fieldBase";
import {FlagOptionProvider} from "@/model/userInput/fields/common/flagMixin";

@Component
export default class NonDeterminableButton extends Vue {
    @Prop({required: true}) inactive!: boolean
    @Prop({required: true}) fieldData!: Field<any> & FlagOptionProvider
    emitToggleNonDeterminableIfMutable() {
        if(this.fieldData.mutable && !this.inactive) this.fieldData.toggleNonDeterminableSelected()
    }
}
