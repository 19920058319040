







import {Component, Inject, Vue} from 'vue-property-decorator'
import {ServiceType} from "@/services/types";
import {UserService} from "@/services/user";
import UserVue from "@/components/userInput/UserView.vue";
import {UserView} from "@/model/users/userView";

@Component({
    components: {UserVue}
})
export default class UserPage extends Vue {

    @Inject(ServiceType.User) protected userService!: UserService
    userView: UserView | null = null

    async created() {
        this.userView = await this.userService.getUserView()
    }
}
