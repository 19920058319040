






import {Component, Prop, Vue} from "vue-property-decorator";
import BaseProteinSearchForm from "@/components/userInput/BaseProteinSearchForm.vue";
import {Form} from "@/model/userInput/form/form";

@Component({
  components: {BaseProteinSearchForm}
})
export default class LimitProteinsForm extends Vue{
  @Prop({required: true}) form!: Form
}
