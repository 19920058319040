














































import {Component, Prop, Vue} from "vue-property-decorator";
import {NumberRangeField as _NumberRangeField} from "@/model/userInput/fields/common/numberRange";


@Component
export default class NumberRangeFeld extends Vue {

    @Prop({required: true}) fieldData!: _NumberRangeField
    @Prop({default: false}) inactive!: boolean

    changed() {
        this.$emit("changed")
    }
}
