import {RawViewList, ViewList} from "@/model/userInput/view/viewList";
import {Language} from "@/services/language";
import {RawSOPView, SOPView} from "@/model/sop/sopView";


export type RawSOPViewList = RawViewList<RawSOPView>


export class SOPViewList extends ViewList<RawSOPView, SOPView> {

    protected async deserializeView(raw: RawSOPView, language: Language): Promise<SOPView> {
        const view = new SOPView(raw, language)
        await view.deserialize()
        return view
    }
}