import {AxiosCalls} from "@/services/axiosCalls";
import {HTTPErrResponse, HTTPRequest, HTTPResponse, JsonLike, Method, Url, urlFor} from "@/utilities/http";
import config from "@/config/config.json"
import {Logging, LoggingLevel} from "@/services/logging";
import {Role} from "@/utilities/roles";
import {Form} from "@/model/userInput/form/form";
import {Language} from "@/services/language";
import {RawUserViewList, UserViewList} from "@/model/users/userViewList";
import {SearchRespMetadata} from "@/utilities/searchResponse";
import {searchRequestMetadata} from "@/utilities/search/helpers";
import {UserView} from "@/model/users/userView";


export class UserDetails{
     public userName: undefined | string = ""
     public originalPassword: undefined | string = ""
     enteredPassword: undefined | string = ""
     newPassword: undefined | string = ""
     newPasswordRetype: undefined | string = ""
     languageChanged:boolean=false
     currentLanguage:undefined|string=""
}
export interface UserService{

    userRole: Role | null
    loggedIn: boolean

    satisfiesRole(role: Role): boolean

    login(username: string, password: string): Promise<boolean>

    signUp(form: Form): Promise<boolean>

    userSettingsForm(form: Form, loggerMessages: any): Promise<boolean>

    userSettingsLanguageForm(form: Form, loggerMessages: any): Promise<boolean>

    logout(): Promise<boolean>

    getUserView(): Promise<UserView|null>

    updateUserViewAsAdmin(userView: UserView): Promise<boolean>

    searchUsers(searchString: string, srm: searchRequestMetadata): Promise<[UserViewList, SearchRespMetadata]|null>
}

export class UserServiceImp implements UserService{

    protected ax: AxiosCalls
    private logger: Logging
    private language: Language

    userRole: Role|null = null
    loggedIn: boolean = false

    constructor(ax: AxiosCalls, logger: Logging, language: Language) {
        this.ax = ax
        this.logger = logger
        this.language = language
    }

    async logout(): Promise<boolean>{
        const url: Url = urlFor(config.REST_API.ROUTES.USER.LOGOUT)
        const req: HTTPRequest = new HTTPRequest(Method.POST, url, {}, [])
        try{
            await this.ax.request(req)
            this.logger.addInfo("Ausloggen erfolgreich!", undefined, LoggingLevel.USER_INFO)
            this.loggedIn = false
            this.language.invalidateLangMap()
            return true
        }
        catch (e) {
            console.log(e)
            this.logger.addError("Ausloggen fehlgeschlagen!", undefined, LoggingLevel.USER_INFO)
            return false
        }
    }

    login(username: string, password: string): Promise<boolean> {
        const url: Url = urlFor(config.REST_API.ROUTES.USER.LOGIN)
        const data: JsonLike = {
            "username": username,
            "password": password
        }
        UserDetails.prototype.userName=username
        UserDetails.prototype.originalPassword=password

        const req: HTTPRequest = new HTTPRequest(Method.POST, url, data, [])
        return this.ax.request(req)
            .then((res:HTTPResponse)=>{
                try {
                    this.userRole = res.data["role"]["permissionLevel"]
                    UserDetails.prototype.currentLanguage=res.data["lang"].toString().toLowerCase()
                    localStorage.setItem('lang',res.data["lang"].toString().toLowerCase())
                    this.loggedIn = true
                }
                catch {
                    this.logger.addWarning("die Antwort vom Server hat ein ungültiges Format", undefined, LoggingLevel.USER_INFO)
                    return false
                }
                return true
            })
            .catch((err: HTTPErrResponse)=>{
                this.logger.addError("Einloggen fehlgeschlagen!", undefined, LoggingLevel.USER_INFO)
                this.logger.addError(err.statusText, undefined, LoggingLevel.TECHNICAL)
                return false
            })
    }

    signUp(form: Form): Promise<boolean> {
        const url: Url = urlFor(config.REST_API.ROUTES.USER.SIGN_UP)
        const data = <JsonLike>{"form": form.serialize()}
        const req: HTTPRequest = new HTTPRequest(Method.POST, url, data, [])
        return this.ax.request(req)
            .then(()=>{
                this.logger.addInfo("Registrierung erfolgreich!", undefined, LoggingLevel.USER_INFO)
                return true
            })
            .catch((err: HTTPErrResponse)=>{
                this.logger.addError("Registrierung fehlgeschlagen!", undefined, LoggingLevel.USER_INFO)
                this.logger.addError(err.statusText, undefined, LoggingLevel.TECHNICAL)
                return false
            })
    }

     userSettingsForm(form: Form, loggerMessages: any): Promise<boolean> {
        const url: Url = urlFor(config.REST_API.ROUTES.USER.USER_SETTINGS_UPDATE)
        const data = <JsonLike>{"form": form.serialize()}
        const req: HTTPRequest = new HTTPRequest(Method.POST, url, data, [])

        return this.ax.request(req)
            .then(()=>{
                this.logger.addInfo(loggerMessages[0], undefined, LoggingLevel.USER_INFO)
                return true
            })
            .catch((err: HTTPErrResponse)=>{
                this.logger.addError(loggerMessages[1], undefined, LoggingLevel.USER_INFO)
                this.logger.addError(err.statusText, undefined, LoggingLevel.TECHNICAL)
                return false
            })
    }

    userSettingsLanguageForm(form: Form, loggerMessages: any): Promise<boolean> {
        const url: Url = urlFor(config.REST_API.ROUTES.USER.USER_SETTINGS_LANGUAGE_UPDATE)
        const data = <JsonLike>{"form": form.serialize()}
        const req: HTTPRequest = new HTTPRequest(Method.POST, url, data, [])
        return this.ax.request(req)
            .then(()=>{
                this.logger.addInfo(loggerMessages[0], undefined, LoggingLevel.USER_INFO)
                return true
            })
            .catch((err: HTTPErrResponse)=>{
                this.logger.addError(loggerMessages[1], undefined, LoggingLevel.USER_INFO)
                this.logger.addError(err.statusText, undefined, LoggingLevel.TECHNICAL)
                return false
            })
    }

    async searchUsers(searchString: string, srm: searchRequestMetadata): Promise<[UserViewList, SearchRespMetadata]|null> {
        const url: Url = urlFor(config.REST_API.ROUTES.USER.SEARCH_USERS)
        const data = <JsonLike>{
            "searchString": searchString,
            "searchRequestMetadata": srm
        }
        const req: HTTPRequest = new HTTPRequest(Method.POST, url, data, [])
        try {
            const res = await this.ax.request(req)
            const rawUserViewList: RawUserViewList = res.data["views"]
            const searchMetadata: SearchRespMetadata = res.data["searchResponseMetadata"]
            const userViewList = new UserViewList(rawUserViewList, this.language)
            await userViewList.deserialize()
            return [userViewList, searchMetadata]
        }
        catch (e) {
            console.log("error while searching users: \n", e)
            this.logger.addError("Suche fehlgeschlagen")
            return null
        }
    }

    async getUserView(): Promise<UserView | null> {
        const url: Url = urlFor(config.REST_API.ROUTES.USER.GET_VIEW)
        const data = <JsonLike> {}
        const req: HTTPRequest = new HTTPRequest(Method.GET, url, data, [])
        try {
            const res = await this.ax.request(req)
            const rawUserView = res.data["view"]
            const userView = new UserView(rawUserView, this.language)
            await userView.deserialize()
            return userView
        }
        catch (e) {
            console.log("error while getting user view: \n", e)
            this.logger.addError("Nutzerdaten nicht abrufbar")
            return null
        }
    }

    async updateUserViewAsAdmin(userView: UserView): Promise<boolean> {
        const url: Url = urlFor(config.REST_API.ROUTES.USER.UPDATE_AS_ADMIN)
        const changedView = userView.serializeChanged()
        const data = <JsonLike>{"updatedView": changedView}
        const req: HTTPRequest = new HTTPRequest(Method.POST, url, data, [])
        try {
            await this.ax.request(req)
            this.logger.addInfo("Änderungen wurden übernommen")
            userView.commitChanges()
            return true
        }
        catch (e) {
            console.log("error while trying to update user: \n", e)
            this.logger.addError("Fehler: Änderungen wurden möglicherweise nicht übernommen")
            return false
        }
    }

    satisfiesRole(role: Role): boolean {
        const userRole = this.userRole
        if (userRole === null) {
            console.log("not logged in")
            return false
        }
        const expected = role.valueOf()
        const actual = userRole.valueOf()
        return actual >= expected
    }
}

export class UserServiceMock implements UserService {

    loggedIn = true
    userRole = Role.Admin
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    login(username: string, password: string): Promise<boolean> {
        return Promise.resolve(true);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    signUp(form: Form): Promise<boolean> {
        return Promise.resolve(true);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    userSettingsForm(form: Form): Promise<boolean> {
        return Promise.resolve(true);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    userSettingsLanguageForm(form: Form): Promise<boolean> {
        return Promise.resolve(true);
    }

    async logout(): Promise<boolean> {return true}

    searchUsers(): Promise<[UserViewList, SearchRespMetadata]|null>{
        return Promise.resolve(null)
    }

    getUserView(): Promise<UserView | null> {
        return Promise.resolve(null)
    }

    satisfiesRole(role: Role): boolean {
        const userRole = this.userRole
        if (userRole === null) return false
        const expected = role.valueOf()
        const actual = userRole.valueOf()
        return actual >= expected
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateUserViewAsAdmin(userView: UserView): Promise<boolean> {
        return Promise.resolve(false);
    }
}