





















import {Component, Prop, Vue} from "vue-property-decorator";
import RecursiveRender from "@/components/userInput/util/RecursiveRender.vue";
import {ProteinCombinationView} from "@/model/protein/proteinCombinationView";
import GenericField from "@/components/userInput/fields/util/GenericField.vue";


@Component({
    components: {RecursiveRender, GenericField}
})
export default class ProteinCombinationViewVue extends Vue {
    @Prop({required: true}) view!: ProteinCombinationView
}
