








































import {Component, Inject, Prop, Vue} from "vue-property-decorator";
import {
  SubsetSelectField as _SubsetSelectField,
  SubsetSelectLeaf,
  SubsetSelectNode
} from "@/model/userInput/fields/common/subset_select";
import Nested from "@/components/util/Nested.vue";
import {ServiceType} from "@/services/types";
import {Language} from "@/services/language";
import ShowProteinsOnModel from "@/components/util/ShowProteinsOnModel.vue"

@Component({
  components: {Nested, ShowProteinsOnModel}
})
export default class SubsetSelectField extends Vue {

    @Prop({required: true}) fieldData!: _SubsetSelectField
    @Inject(ServiceType.Language) protected language!: Language
    @Prop({required: false, default: true}) showCategoriesSelections!: boolean

    // This selectedProteins Only required when we need to show the Protein Names
    // On Filter Observations Modal Selection View. Otherwise, It would always be null
    @Prop({required: false}) selectedProteins!: string[]

    get nested(){
      return this.fieldData.asNestedStructure
    }

    getProteinName(protein: string): string{
      return (":" + protein)
    }

    changed() {
        this.$emit("changed")
    }

    isSelected(label: string): boolean {
        return this.fieldData.value.includes(label)
    }

    toggleSelect(leaf: SubsetSelectLeaf) {
        if(leaf.selected) this.unselect(leaf)
        else this.select(leaf)
    }

    select(leaf: SubsetSelectLeaf) {
        const label = leaf.fullLabel(this.language)
        if(this.isSelected(label)) return

        leaf.selected = true
        this.fieldData.select(label)
        this.changed()
    }

    findLeaf(label: string): SubsetSelectLeaf {
        const hierarchies = this.language.getHierarchies(this.fieldData.reverseOptionsMap[label])
        let node: SubsetSelectNode|SubsetSelectLeaf|null = this.fieldData.asNestedStructure
        if(node instanceof SubsetSelectNode) node = node.traverse(hierarchies)
        if(node === null) throw TypeError("subset select field: nested data structure not setup")
        if(node instanceof SubsetSelectNode) throw TypeError("subset select field: could not reach leaf")
        return node
    }

    unselect(leaf: SubsetSelectLeaf) {
        const label = leaf.fullLabel(this.language)
        this.fieldData.unselect(label)
        leaf.selected = false
        this.changed()
    }

    unselectProtein(protein: string){
      const index = this.selectedProteins.indexOf(protein);
      this.selectedProteins.splice(index, 1)
    }

}
