




















import {Component, Inject, Vue} from 'vue-property-decorator'
import {AxiosCalls} from "@/services/axiosCalls";
import {HTTPErrResponse, HTTPRequest, HTTPResponse, Method, urlFor} from "@/utilities/http";
import {Logging, LoggingLevel} from "@/services/logging";
import config from "@/config/config.json"
import {BIconCloudDownload, BIconCloudUpload} from "bootstrap-vue";
import {ServiceType} from "@/services/types";


@Component({
    components: {
        BIconCloudUpload,
        BIconCloudDownload
    }
})
export default class MediaImportForm extends Vue {

    private file: File | null = null
    @Inject(ServiceType.AxiosCalls) axios!: AxiosCalls
    @Inject(ServiceType.Logging) logging!: Logging
    private uploading: boolean = false

    upload(): void {
        if (this.file === null) {
            this.logging.addWarning("Keine Datei ausgewählt.", 4000, LoggingLevel.USER_INFO)
            return
        }
        let formData = new FormData()
        formData.append("file", this.file)
        this.uploading = true
        const req: HTTPRequest = new HTTPRequest(
            Method.POST,
            urlFor(config.REST_API.ROUTES.MEDIA_IMPORT.UPLOAD),
            formData,
            {'Content-Type': 'multipart/form-data'}
        )
        this.axios.request(req).then((res: HTTPResponse) => {
            let success: boolean
            try {
                success = Boolean(res.data["success"])
            } catch {
                this.logging.addWarning(
                    "die Antwort vom Server hat ein ungültiges Format",
                    undefined,
                    LoggingLevel.USER_INFO)
                return
            } finally {
                this.uploading = false
            }
            if (success) this.logging.addInfo(
                "Media Archiv wurde verarbeitet",
                undefined,
                LoggingLevel.USER_INFO)
            else this.logging.addError(
                "Media Archiv konnte nicht verarbeitet werden.",
                undefined,
                LoggingLevel.USER_INFO)
        })
            .catch((err: HTTPErrResponse) => {
                this.logging.addError(
                    "Media Archiv konnte nicht verarbeitet werden.",
                    undefined,
                    LoggingLevel.USER_INFO)
                this.logging.addError(
                    err.statusText,
                    undefined,
                    LoggingLevel.TECHNICAL)
            })
    }
}
