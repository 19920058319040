











import {Component, Provide, Vue} from 'vue-property-decorator';
import Navigation from "@/components/Navigation.vue";
import Logging from "@/components/Logging.vue";
import Login from "@/components/Login.vue";
import {UserServiceImp} from "@/services/user";
import {ServiceType} from "@/services/types";
import {AxiosCallsImp} from "@/services/axiosCalls";
import {ProteinQueriesImp} from "@/services/proteinQueries";
import {LoggingImp} from "@/services/logging";
import {GroupsServiceImp} from "@/services/groups";
import {LanguageImpl} from "@/services/language";
import {FormGetterImp} from "@/services/formGetter";
import {SOPServicesImp} from "@/services/sop";
import {ENVPServicesImp} from "@/services/envp";

@Component({
    components: {
        Navigation,
        Logging,
        Login
    },
})
export default class App extends Vue {
    @Provide(ServiceType.Logging) private logging = new LoggingImp()
    @Provide(ServiceType.AxiosCalls) private axiosCalls = new AxiosCallsImp(this.logging)
    @Provide(ServiceType.Language) private language = new LanguageImpl(this.axiosCalls)
    @Provide(ServiceType.User) private users = new UserServiceImp(this.axiosCalls, this.logging, this.language)
    @Provide(ServiceType.Groups) private groups = new GroupsServiceImp(this.axiosCalls, this.logging, this.users)
    @Provide(ServiceType.ProteinQueries) private proteinQueries = new ProteinQueriesImp(this.axiosCalls, this.logging, this.language)
    @Provide(ServiceType.SOP) private sopServices = new SOPServicesImp(this.axiosCalls, this.logging, this.language)
    @Provide(ServiceType.ENVP) private envpServices = new ENVPServicesImp(this.axiosCalls, this.logging, this.language)
    @Provide(ServiceType.FormGetter) private formGetter = new FormGetterImp(this.language, this.axiosCalls)

    mounted() {
        document.title = "NFS - Proteindatenbank";
    }
}
