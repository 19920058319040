import { render, staticRenderFns } from "./ProteinByUrl.vue?vue&type=template&id=140c183d&scoped=true&"
import script from "./ProteinByUrl.vue?vue&type=script&lang=ts&"
export * from "./ProteinByUrl.vue?vue&type=script&lang=ts&"
import style0 from "./ProteinByUrl.vue?vue&type=style&index=0&id=140c183d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "140c183d",
  null
  
)

export default component.exports