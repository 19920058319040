import {HTTPErrResponse, HTTPNoResponse, HTTPRequest, HTTPResponse, Method} from "../utilities/http"
import {AxiosRequestConfig} from "axios";
import {Logging} from "@/services/logging";


/**
 * this service uses axios to perform REST calls
 * @protected axios: axios function
 */
export interface AxiosCalls {

    /**
     * performs the request specified in request parameter
     * @param request: request configuration
     * @return promises a HTTPResponse object
     */
    request(request: HTTPRequest): Promise<HTTPResponse | HTTPNoResponse>
}

export class AxiosCallsImp implements AxiosCalls {

    // should be protected so it is possible to mock it (useful for tests)
    protected axios = require('axios')
    protected logger: Logging

    constructor(logger: Logging) {
        this.axios.defaults.withCredentials = true
        this.logger = logger
    }

    private methodToStr(method: Method): "GET" | "POST" | "PUT" | "DELETE" {
        if (method === Method.GET) return "GET"
        else if (method === Method.POST) return "POST"
        else if (method === Method.PUT) return "PUT"
        else if (method === Method.DELETE) return "DELETE"
        throw TypeError("Method could not be parsed.")
    }

    private sendReqObj(reqObj: AxiosRequestConfig) {
        let response
        return new Promise<HTTPResponse>((resolve, reject) => {
            this.axios(reqObj).then((res: any) => {
                response = new HTTPResponse(res.status, res.statusText, res.data)
                resolve(response)

            }).catch((error: any) => {
                if (error.response) {
                    response = new HTTPErrResponse(error.response.status, error.response.statusText, error.response.data)
                } else {
                    response = new HTTPNoResponse()
                }
                reject(response)
            })
        })
    }

    public request(request: HTTPRequest): Promise<HTTPResponse> {
        let reqObj: AxiosRequestConfig = {
            method: this.methodToStr(request.method),
            url: `${request.url.protocol}://${request.url.host}:${request.url.port}${request.url.route}`,
            data: {},
            params: {},
            headers: request.headers,
            responseType: request.responseType,
        };
        if (request.method === Method.GET)
            reqObj.params = request.data;
        if (request.auth)
            reqObj.auth = request.auth
        else
            reqObj.data = request.data;

        return this.sendReqObj(reqObj)
    }
}

export class AxiosCallsDummy implements AxiosCalls {

    private response: HTTPResponse = new HTTPNoResponse()

    public respondWith(response: HTTPResponse) {
        this.response = response
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public request(request: HTTPRequest): Promise<HTTPResponse> {
        return new Promise<HTTPResponse>((resolve, reject) => {
            if (this.response instanceof HTTPNoResponse) reject(this.response)
            else if (this.response instanceof HTTPErrResponse) reject(this.response)
            else resolve(this.response)
        })
    }
}
