
















import {Component, Prop, Vue} from "vue-property-decorator";
import {Form} from "@/model/userInput/form/form";
import GenericField from "@/components/userInput/fields/util/GenericField.vue";

@Component({
    components: {GenericField},
})
export default class UserRegistrationForm extends Vue {
    @Prop({required: true}) form!: Form
}
