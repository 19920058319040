













import {Component, Prop, Vue} from "vue-property-decorator";
import {BlobField as _BlobField} from "@/model/userInput/fields/common/blob_or_non_det";
import {isNU} from "@/utilities/isNU";

@Component
export default class BlobField extends Vue {

    @Prop({required: true}) fieldData!: _BlobField
    @Prop({default: false}) inactive!: boolean
    imageInput!: string

    get field() {
        return this.fieldData
    }

    get fieldHasValue() {
        return !isNU(this.fieldData.value)
    }

    get fieldDataEncoded() {
        return "data:image/*;base64," + this.fieldData.value
    }

    setImage() {
        /*#TODO: continue here
        f.stream()

        const reader = new FileReader()
        reader.readAsDataURL(f.stream()[0])
        this.fieldData.value = reader.result as string*/
        this.changed()
    }

    changed() {
        this.$emit("changed")
    }
}
