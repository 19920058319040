import {rawField} from "@/model/userInput/fields/util/raw";
import {Field} from "@/model/userInput/fields/util/fieldBase";
import {Language} from "@/services/language";
import {isNU} from "@/utilities/isNU";
import {FLagOption, FlagOptionProvider} from "@/model/userInput/fields/common/flagMixin";


type rawBlobField = rawField & {
    value?: string
    maxSize?: number
}

export class BlobField extends Field<rawBlobField> {

    private originalVal?: string
    value?: string
    max_size?: number

    get changed(): boolean {
        return this.value === this.originalVal
    }

    commitChange() {
        this.originalVal = this.value
    }

    makeEmpty() {
        this.value = undefined
    }

    isEmpty(): boolean {
        return isNU(this.value) && this.value !== ""
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async deserializeField(data: rawBlobField, language: Language): Promise<void> {
        this.value = data.value
        this.originalVal = data.value
        this.max_size = data.maxSize
    }

    serialize(): rawBlobField {
        if (!this.valid())
            throw Error("cannot serialize blob field")
        return <rawBlobField>{
            value: this.value,
        }
    }

    valid(): boolean {
        if(isNU(this.value)) return false
        if(isNU(this.max_size)) return false
        // size of base64 string in bytes
        const len = this.value!.length
        const maxLen = 3 * (this.max_size! / 4)
        return len <= maxLen
    }
}


export const BlobOrNonDetField = FLagOption(BlobField)

export type BlobOrNonDetFieldT = BlobField & FlagOptionProvider

