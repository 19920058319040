











import {Component, Inject, Vue} from "vue-property-decorator";
import {BIconPlusCircle} from "bootstrap-vue";
import {ServiceType} from "@/services/types";
import {ProteinQueries} from "@/services/proteinQueries";

@Component({
  components: {BIconPlusCircle}
})
export default class ProteinIdentifierInput extends Vue{

  @Inject(ServiceType.ProteinQueries) pq!: ProteinQueries
  inputValid: boolean = false
  identifierInput: string = ""
  private proteinNames: string[]|null = null

  async created(){
    this.proteinNames = await this.pq.getProteinNames()
  }

  validateInput() {
    if(this.proteinNames === null) this.inputValid = false
    else this.inputValid = this.proteinNames.includes(this.identifierInput)
  }

  changed(){
    this.validateInput()
    if(this.inputValid) this.$emit("correctId", this.identifierInput)
    else this.$emit('invalid')
  }
}
