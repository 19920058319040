import {RawView, View} from "@/model/userInput/view/view";
import {Language} from "@/services/language";
import {LangId, LangInfo} from "@/model/LangInfo";
import {Deserializable} from "@/model/serializable";


export type RawViewColumn = {label: LangId, sortKey: string, sop_file: string, envp_file: string}
export type RawViewColumns = RawViewColumn[]


export type RawViewList<T extends RawView> = {
    columns: RawViewColumns
    views: T[]
}


export class ViewColumn extends Deserializable<RawViewColumn>{

    label!: LangInfo
    sort_key!: string
    language: Language
    sop_file!: string
    envp_file!: string

    constructor(raw: RawViewColumn, language: Language) {
        super(raw);
        this.language = language
    }

    protected async _deserialize(raw: RawViewColumn): Promise<void> {
        const lang = new LangInfo(raw.label, this.language)
        await lang.deserialize()
        this.label = lang
        this.sort_key = raw.sortKey
        this.sop_file = raw.sop_file
        this.envp_file = raw.envp_file
    }
}


export abstract class ViewList<R extends RawView, T extends View> extends Deserializable<RawViewList<R>>{

    views: T[] = []
    columns: ViewColumn[] = []
    private language?: Language

    constructor(raw: RawViewList<R>, language: Language) {
        super(raw)
        this.language = language
    }

    protected async _deserialize(raw: RawViewList<R>): Promise<void> {
        for(const view of raw.views){
            const v: T = await this.deserializeView(<R>view, this.language!)
            this.views.push(v)
        }
        for(const col of raw.columns){
            const colDeserialized = new ViewColumn(col, this.language!)
            await colDeserialized.deserialize()
            this.columns.push(colDeserialized)
        }
        delete this.language
    }

    protected abstract async deserializeView(raw: R, language: Language): Promise<T>
}