import {RawView, View} from "@/model/userInput/view/view";
import {Language} from "@/services/language";
import {isNU} from "@/utilities/isNU";


export type RawUserView = RawView & {username?: string}


export class UserView extends View {

    username: string

    constructor(raw: RawUserView, language: Language) {
        super(raw, language);
        if(isNU(raw.username)) throw Error(`attribute username is ${raw.username} but should be a string`)
        this.username = raw.username!
    }

    serializeChanged(): RawUserView {
        const changed = <RawUserView> super.serializeChanged()
        changed["username"] = this.username
        return changed
    }
}
