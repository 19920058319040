


















import {Prop, Vue, Component} from "vue-property-decorator";

@Component({})
export default class Stepper extends Vue {

    @Prop({required: true}) steps!: string[]
    @Prop({required: true}) progress!: number
    @Prop({required: true}) currentStep!: number
    @Prop({required: false, default: false}) disabledOtherSteps!: boolean
    @Prop({default: null}) tooltipMessage: string|null = null

    emitStepIfEnabled(step_idx: number){
        if((this.progress < step_idx) || (this.disabledOtherSteps && (this.currentStep !== step_idx))) return
        this.$emit('clickedStep', step_idx)
    }
}
