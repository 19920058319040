






import {Component, Prop, Vue} from "vue-property-decorator";
import {LangInfoField as _LangInfoField} from "@/model/userInput/fields/common/lang_info";


@Component
export default class LangInfoField extends Vue {

    @Prop({required: true}) fieldData!: _LangInfoField
    @Prop({default: false}) inactive!: boolean

    get field() { return this.fieldData }
    get placeholder(): string { return "" }
    changed() { this.$emit("changed") }
}
