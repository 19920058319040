











import {Component, Prop, Vue} from 'vue-property-decorator'
import {BIconArrowDown, BIconArrowUp} from "bootstrap-vue"
import {Sorting} from "@/utilities/search/sorting";

@Component({
    components: {BIconArrowDown, BIconArrowUp}
})
export default class SortingButton extends Vue {

    @Prop({required: true}) forSortKey!: string
    @Prop({required: true}) currentSorting!: null | Sorting

    sort(ascending: boolean) {
        const sorting: Sorting = {
            ascending: ascending,
            sortKey: this.forSortKey
        }
        this.currentSorting = sorting
        this.$emit("sort", sorting)
    }

    sortDesc(){
        this.sort(false)
    }

    sortAsc(){
        this.sort(true)
    }
}
