








import {Component, Prop, Vue} from "vue-property-decorator";
import {BlobField} from "@/model/userInput/fields/common/blob_or_non_det";

@Component
export default class BlobFullscreen extends Vue {
    @Prop({required: true}) field!: BlobField

    get fieldDataEncoded() {
        return "data:image/*;base64," + this.field.value
    }

    dismiss() {
        this.$emit("dismiss")
    }
}
