
























import {Component, Prop, Vue} from "vue-property-decorator";
import {OptionsField as _OptionsField} from "@/model/userInput/fields/common/options";


@Component
export default class OptionsField extends Vue {

    @Prop({default: false}) inactive!: boolean
    @Prop({required: true}) fieldData!: _OptionsField

    changed() {
        this.$emit("changed",{label:this.fieldData.label,value:this.fieldData.value})
    }

    get placeholder(): string {
        if (this.fieldData.placeholder)
            return this.fieldData.placeholder
        else return ""
    }
}
