
























import {Component, Inject, Vue} from 'vue-property-decorator'
import {ServiceType} from "@/services/types";
import {ProteinQueries} from "@/services/proteinQueries";
import {SearchQueryDescriptor} from "@/utilities/search/searchQueryDescriptor";
import TooltipButton from "@/components/util/TooltipButton.vue";
import {BIconTrash} from "bootstrap-vue";


@Component({
    components: {TooltipButton, BIconTrash}
})
export default class StoredSearchQueriesLoader extends Vue {

    storedSearchQueryDescriptors: SearchQueryDescriptor[] = []

    @Inject(ServiceType.ProteinQueries) pq!: ProteinQueries

    created(){
        this.loadStoredSearchQueryDescriptors().then(res => {
            if(res !== null) this.storedSearchQueryDescriptors = res
        })
    }

    async loadStoredSearchQueryDescriptors(): Promise<SearchQueryDescriptor[]|null> {
        return await this.pq.getStoredSearchForms()
    }

    async loadForm(searchDescriptor: SearchQueryDescriptor){
        const searchForm = await this.pq.restoreSearchForm(searchDescriptor.name)
        this.$emit("restoredSearchForm", searchForm)
    }

    async deleteSearchForm(searchDescriptor: SearchQueryDescriptor){
        const success = await this.pq.deleteStoredSearchForm(searchDescriptor.name)
        if(success) {
            this.storedSearchQueryDescriptors = this.storedSearchQueryDescriptors.filter(
                descriptor => descriptor !== searchDescriptor)
        }
    }
}
