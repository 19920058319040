export abstract class Deserializable<RawT> {

    private raw?: RawT
    private isDeserialized: boolean = false

    get deserialized(): boolean {
        return this.isDeserialized
    }

    protected constructor(raw: RawT) {
        this.raw = raw
    }

    async deserialize(): Promise<void> {
        if(this.isDeserialized) return
        await this._deserialize(this.raw!)
        this.isDeserialized = true
        delete this.raw
    }

    protected abstract async _deserialize(raw: RawT): Promise<void>

    private sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async waitUntilDeserialized(): Promise<void> {
        while (!this.isDeserialized) await this.sleep(100)
    }
}


export async function deserialize<T extends Deserializable<any>>(d: T): Promise<T>{
    await d.deserialize()
    return d
}