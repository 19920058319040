


















import {Component, Inject, Vue} from 'vue-property-decorator'
import {BIconArrowLeftShort, BIconSearch, BIconX} from "bootstrap-vue";
import {ServiceType} from "@/services/types";
import PaginatedViewList from "@/components/userInput/util/PaginatedViewList.vue";
import UserView from "@/components/userInput/UserView.vue";
import {sopSearchHandler} from "@/utilities/sopSearch";
import {SOPServices, SearchSOPFileDetails} from "@/services/sop";
import {SOPView} from "@/model/sop/sopView";
import SOPViewMarkdown from "@/components/sop/SOPViewMarkdown.vue"
import {isNUE} from "@/utilities/isNU";
import {Language} from "@/services/language";

@Component({
    components: {UserView, PaginatedViewList, BIconSearch, BIconArrowLeftShort, BIconX, SOPViewMarkdown}
})
export default class SOPSearch extends Vue {

    searchString: string = ""
    sopSearchHandler: sopSearchHandler | null = null
    viewToShow: SOPView | null = null

    showSopContent: boolean=true

    readonly initialBatchSize: number = 10
    readonly paginationSize: number = 7
    readonly maxUserViewsCached: number = 1000

    @Inject(ServiceType.Language) language!: Language
    @Inject(ServiceType.SOP) sopServices!: SOPServices

    handleSearch(){
        if(this.sopSearchHandler === null) {
            this.sopSearchHandler = new sopSearchHandler(
                this.initialBatchSize,
                this.paginationSize,
                this.maxUserViewsCached,
                this.searchString,
                this.sopServices,
                this.language
            )
        }
        else {
            this.sopSearchHandler.setSearchString(this.searchString)
        }
        this.sopSearchHandler.firstBatch()
    }
    detectSearchFieldValueChange(){
      this.handleSearch()
    }
    created(){
      if(!isNUE(SearchSOPFileDetails.prototype.filename_to_search)){
        this.searchString = SearchSOPFileDetails.prototype.filename_to_search
        SearchSOPFileDetails.prototype.filename_to_search = ""
        this.handleSearch()
        this.searchString = ""
      }
      else this.handleSearch()
    }
    showView(sopView: SOPView) {
        this.viewToShow = sopView
    }
    backToList(){
        this.viewToShow = null
    }
}
