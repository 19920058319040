











import {Component, Prop, Vue} from 'vue-property-decorator'
import ButtonWrapper from "@/components/util/ButtonWrapper.vue";

@Component({
    components: {ButtonWrapper}
})
export default class TooltipButton extends Vue {
    @Prop({required: true}) id!: string
    @Prop({required: true}) disabled!: boolean
    @Prop({default: null, required: false}) tooltipMessage!: string
    clicked(){if(!this.disabled)this.$emit("click")}
}
