












import {Component, Prop, Vue} from "vue-property-decorator";
import RecursiveRender from "@/components/userInput/util/RecursiveRender.vue";
import {Form} from "@/model/userInput/form/form";
import ProteinSearchFormFieldSummary from "@/components/userInput/util/ProteinSearchFormFieldSummary.vue";
import CollapseToggleIcon from "@/components/util/CollapseToggleIcon.vue"
import {SingularEventBus, Subscriber} from "@/utilities/eventBus";
import BaseProteinSearchForm from "@/components/userInput/BaseProteinSearchForm.vue";


@Component({
    components: {BaseProteinSearchForm, RecursiveRender, ProteinSearchFormFieldSummary, CollapseToggleIcon}
})
export default class MainProteinSearchForm extends Vue implements Subscriber<void>{

    @Prop({required: true}) form!: Form
    @Prop({default: null}) closeFormEventBus?: SingularEventBus
    unfoldState: boolean = false

    created(){
      this.closeFormEventBus?.subscribe(this)
    }

    handleEvent() {
        this.unfoldState = false
    }

    toggleSearchForm(){
      this.unfoldState = !this.unfoldState
    }
}
