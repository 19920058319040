










































import {Component, Inject, Vue} from "vue-property-decorator";
import {BIconPlusCircle, BIconXCircle, BIconArrowRightCircleFill, BIconX} from "bootstrap-vue";
import ProteinIdentifierInput from "@/components/proteins/ProteinIdentifierInput.vue";
import TooltipButton from "@/components/util/TooltipButton.vue";
import {ProteinView} from "@/model/protein/proteinView";
import {ServiceType} from "@/services/types";
import {ProteinQueries} from "@/services/proteinQueries";
import ProteinViewVue from "@/components/userInput/ProteinView.vue";
import {Logging} from "@/services/logging";


@Component({
  components: {
    TooltipButton, BIconX, BIconPlusCircle, ProteinIdentifierInput, BIconXCircle, ProteinViewVue, BIconArrowRightCircleFill}
})
export default class ProteinIdentifierInputs extends Vue{

  @Inject(ServiceType.ProteinQueries) pq!: ProteinQueries
  @Inject(ServiceType.Logging) logging!: Logging
  referenceProteins: ProteinView[] = []

  inputVisible: boolean = false
  hideInput(){ this.inputVisible = false }
  showInput(){ this.inputVisible = true}

  getReferenceProteinIdentifiers(): string[] {
    const proteinIds: string[] = []
    this.referenceProteins.forEach(p => proteinIds.push(p.proteinName))
    return proteinIds
  }

  async handleValidInput(input: string) {
    const view = await this.pq.getNotUpdatableProteinView(input)
    if(view === null) {
      this.logging.addError(`Protein ${input}`+ this.$t('GeneralErrorMessages.NotLoaded'))
    }
    else {
      this.referenceProteins.push(view)
      this.emitIds()
      this.hideInput()
    }
  }

  emitIds(){
    this.$emit("idsChanged", this.getReferenceProteinIdentifiers())
  }

  removeProtein(protein: ProteinView){
    const index = this.referenceProteins.indexOf(protein);
    this.referenceProteins.splice(index, 1)
  }
}
