







import {Component, Prop, Vue} from "vue-property-decorator";
import {ButtonField} from "@/model/userInput/fields/common/button";

@Component
export default class ButtonPreview extends Vue {
    @Prop({required: true}) field!: ButtonField
}
