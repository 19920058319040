














import {Component, Inject, Prop, Vue} from "vue-property-decorator";
import {BIconArrowLeftShort} from "bootstrap-vue";
import {Role} from "@/utilities/roles";
import {ServiceType} from "@/services/types";
import {UserService} from "@/services/user";
import {HTTPErrResponse, HTTPRequest, HTTPResponse, Method, Url, urlFor} from "@/utilities/http";
import config from "@/config/config.json";
import {DownloadSOPFileDetails} from "@/services/sop";
import {Logging, LoggingLevel} from "@/services/logging";
import {AxiosCalls} from "@/services/axiosCalls";
import ProteinViewVue from "@/components/userInput/ProteinView.vue"
import {SOPView} from "@/model/sop/sopView";
import {BIconCloudDownload} from "bootstrap-vue";

@Component({
    components: {ProteinViewVue, BIconArrowLeftShort, BIconCloudDownload}
})
export default class SOPViewMarkdown extends Vue {

  @Prop({default: false}) showSopContent!:boolean
  @Prop({required: true}) view!: SOPView

  @Inject(ServiceType.User) userS!: UserService
  @Inject(ServiceType.AxiosCalls) axios!: AxiosCalls
  @Inject(ServiceType.Logging) logging!: Logging

   backToList(){
        this.$emit("backToList")
    }
    get sufficientPermissions(){
        return this.userS.satisfiesRole(Role.FullAccess)
    }
    downloadSOP(){
      const url: Url = urlFor(config.REST_API.ROUTES.SOP.DOWNLOAD_SOP)
      const httpRequest: HTTPRequest = new HTTPRequest(Method.POST, url, {"fileID": DownloadSOPFileDetails.prototype.fileid}, [], "blob")
      this.axios.request(httpRequest).then((res: HTTPResponse) => {
          const fileURL = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', DownloadSOPFileDetails.prototype.filename+".pdf");
          document.body.appendChild(fileLink);
          fileLink.click();
      })
          .catch((err: HTTPErrResponse) => {
              this.logging.addError("PDF-Datei konnte nicht heruntergeladen werden", 5000, LoggingLevel.USER_INFO)
              this.logging.addError(err.statusText, undefined, LoggingLevel.TECHNICAL)
          })
    }

}
