







import {Vue, Component, Prop} from "vue-property-decorator";
import {BIconTriangleFill} from "bootstrap-vue";

@Component({
  components:{
    BIconTriangleFill
  }
})
export default class CollapseToggleIcon extends Vue {
    @Prop({required: true}) down!: boolean
}
