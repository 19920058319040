































import {Component, Inject, Vue} from 'vue-property-decorator'
import {BIconTrash} from "bootstrap-vue/src/icons/icons";
import {ServiceType} from "@/services/types";
import {ProteinQueries} from "@/services/proteinQueries";

@Component({
  components: {BIconTrash}
})
export default class DeleteAllProteins extends Vue {

  @Inject(ServiceType.ProteinQueries) proteinQueries!: ProteinQueries
  deleteAllRecordsCheckboxChecked: boolean = false

  async deleteAllRecords(){
    await this.proteinQueries.deleteAllProteins()
  }
}
