import {Field} from "@/model/userInput/fields/util/fieldBase";
import {rawField} from "@/model/userInput/fields/util/raw";
import {isNU} from "@/utilities/isNU";
import {Language} from "@/services/language";
import {deserializeField} from "@/model/userInput/fields/util/fieldIds";
import {Deserializable} from "@/model/serializable";


export type RawFields = { [key: string]: rawField }
export type Fields = {[key: string]: Field<any>}


export type RawFieldContainer = RawFields & {label?: string}


export class FieldContainer<T extends RawFieldContainer> extends Deserializable<T>{
    label: string = ""
    private rawLabel: string = ""
    fields: Fields = {}
    protected readonly language: Language

    constructor(raw: T, language: Language) {
        super(raw)
        this.language = language
    }

    protected async _deserialize(raw: RawFieldContainer): Promise<void> {
        if(!isNU(raw.label)) {
            this.rawLabel = raw.label!
            this.label = await this.language.parse(raw.label!)
        }
        await this.deserializeFields(raw, this.language)
    }

    getField(key: string): Field<any> {
        return this.fields[key]
    }

    commitChanges() {
        for (const key of this.keysOfChangedFields) {
            const field = this.fields[key]
            field.commitChange()
        }
    }

    serializeChanged(): RawFieldContainer {
        const serialized: RawFieldContainer = <RawFieldContainer>{}
        for (const key of this.keysOfChangedFields) {
            const field = this.fields[key]
            serialized[key] = field.serialize()
        }
        return serialized
    }

    serializeNonEmpty(): RawFieldContainer {
        const serialized: RawFieldContainer = <RawFieldContainer>{}
        for (const key of this.keysOfNonEmptyFields) {
            const field = this.fields[key]
            serialized[key] = field.serialize()
        }
        return serialized
    }

    get keysOfChangedFields(): string[] {
        return Object.keys(this.fields).filter(key => this.fields[key].changed)
    }

    get keysOfNonEmptyFields(): string[] {
        return Object.keys(this.fields).filter(key => !this.fields[key].isEmpty())
    }

    get nonEmptyFields(): Field<any>[] {
        return Object.values(this.fields).filter(field => !field.isEmpty())
    }

    get changedFields(): Field<any>[] {
        return Object.values(this.fields).filter(field => field.changed)
    }

    private async deserializeFields(fieldsRaw: RawFieldContainer, language: Language): Promise<void> {
        const fields: Fields = {}
        for (const labelId of Object.keys(fieldsRaw)) {
            const rawData = fieldsRaw[labelId]
            if(!isNU(rawData) && !isNU(rawData.fieldTypeId)){
                try{
                    fields[labelId] = await deserializeField(rawData, language)
                } catch (e) {
                    console.log(`cannot deserialize field ${rawData.label}, reason: \n ${e}`)
                }
            }
        }
        this.fields = fields
    }

    nonEmptyFieldsValid(): boolean {
        for(const field of this.nonEmptyFields) if(!field.valid()) return false
        return true
    }

    changedFieldsValid(): boolean {
        for(const field of this.changedFields) if(!field.valid()) return false
        return true
    }
}