import {Language} from "@/services/language";
import {RawView, View} from "@/model/userInput/view/view";
import {isNU} from "@/utilities/isNU";


export type RawProteinView = RawView & {proteinName?: string}


export class ProteinView extends View {

    proteinName: string

    constructor(raw: RawProteinView, language: Language) {
        super(raw, language);
        if(isNU(raw.proteinName)) throw Error(`attribute proteinName is ${raw.proteinName} but should be a string`)
        this.proteinName = raw.proteinName!
    }
}
