






import {Component, Prop, Vue} from "vue-property-decorator";
import {StringField} from "@/model/userInput/fields/common/string";
import {marked} from "marked";

@Component
export default class MarkdownFieldPreview extends Vue {
    @Prop({required: true}) field!: StringField

    get convertToMarkdown(){
        return marked(this.field.value!)
      }
}
