









import {Component, Prop, Vue} from "vue-property-decorator";
import {NormalDistributionField} from "@/model/userInput/fields/common/normalDistribution";

@Component
export default class NormalDistributionPreview extends Vue {
    @Prop({required: true}) field!: NormalDistributionField
}
