import {Language} from "@/services/language";


export function removeFirstHierarchy(langId: string, language: Language){
    const hierarchies = language.getHierarchies(langId)
    hierarchies.shift()
    return language.idsToHierarchicalId(hierarchies)
}


export async function removeFirstHierarchyAndParse(langId: string, language: Language){
    return await language.parse(removeFirstHierarchy(langId, language))
}
